import { useState, useEffect } from "react";
import Lottie from "react-lottie";
import { Container, Title, Animation, Footer } from "./styles";

// Components
import Button from "../../../../components/button/button";

// Assets
import {
	LoadingAnimation,
	SuccessAnimation,
	FailedAnimation,
} from "../../../../assets/animations";
import { useModal } from "../../..";

export function ApiResponse({ content }) {
	const { apiResponse } = content;
	const { changeModalParams } = useModal();

	const [animationOptions, setAnimationOptions] = useState({
		isStopped: false,
		isPaused: false,
	});
	const [defaultOptions, setDefaultOptions] = useState({
		loop: true,
		autoplay: true,
		animationData: LoadingAnimation,
	});
	const [placeholder, setPlaceholder] = useState({
		title: "Por favor, aguarde",
		subtitle: "sua conta está sendo criada",
	});
	const [buttonState, setButtonState] = useState({
		placeholder: "",
		onClick: () => () => {},
	});

	useEffect(() => {
		switch (apiResponse) {
			case 200:
				setDefaultOptions((prevState) => ({
					...prevState,
					loop: false,
					animationData: SuccessAnimation,
				}));
				setPlaceholder({
					title: "Sua conta foi criada com sucesso!",
					subtitle: "agora você pode fazer login!",
				});
				setStyle("success");
				setButtonState((prevState) => ({
					...prevState,
					placeholder: "Fazer login",
					onClick: () => {
						changeModalParams({
							type: "login",
							canClose: true,
							content: {},
							customCloseButton: false,
						});
					},
				}));
				break;
			case 204:
				setDefaultOptions((prevState) => ({
					...prevState,
					loop: false,
					animationData: FailedAnimation,
				}));
				setPlaceholder({
					title: "Houve uma falha!",
					subtitle:
						"Não foi possível criar a sua conta, revise seus dados e tenta novamente",
				});
				setButtonState((prevState) => ({
					...prevState,
					placeholder: "Conferir meus dados",
					onClick: () => {
						changeModalParams({
							type: "createAccount",
							canClose: true,
							content: {},
							customCloseButton: false,
						});
					},
				}));
				setStyle("failed");
				break;
			case 400:
				setDefaultOptions((prevState) => ({
					...prevState,
					loop: false,
					animationData: FailedAnimation,
				}));

				setStyle("failed");
				setPlaceholder({
					title: "Houve uma falha!",
					subtitle: "CPF/E-Mail informado já está sendo usado",
				});
				setButtonState((prevState) => ({
					...prevState,
					placeholder: "Corrigir meus dados",
					onClick: () => {
						changeModalParams({
							type: "createAccount",
							canClose: true,
							content: {},
							customCloseButton: false,
						});
					},
				}));
				break;
			default:
				break;
		}
	}, [apiResponse]);
	const [style, setStyle] = useState("");

	return (
		<Container>
			<Title>
				<h1>{placeholder.title}</h1> <h2>{placeholder.subtitle}</h2>
			</Title>

			<Animation>
				<Lottie
					options={defaultOptions}
					height={"100%"}
					width={"100%"}
					speed={0.9}
					direction={animationOptions.direction}
					isStopped={animationOptions.isStopped}
					isPaused={animationOptions.isPaused}
					isClickToPauseDisabled={true}
				/>
			</Animation>

			{apiResponse ? (
				<Footer>
					<Button
						type="normal"
						width="100%"
						css="normal"
						placeholder={buttonState.placeholder}
						on_click={buttonState.onClick}
					/>
				</Footer>
			) : null}
		</Container>
	);
}
