import React from "react";
import Button from "../../../../components/button/button.js";
import {
	Container,
	IconContainer,
	Description,
	Title,
	Subtitle,
	ButtonContainer,
} from "./styles.js";

import { ReactComponent as HouseIcon } from "../../../../assets/icons/system/home.svg";

export function Address({ address, exclude }) {
	const { usad_address, usad_number, dist_name, city_name, city_uf } = address;

	return (
		<Container>
			<IconContainer>
				<HouseIcon />
			</IconContainer>
			<Description>
				<Title>
					{usad_address}, Nº {usad_number}
				</Title>
				<Subtitle>{dist_name + ", " + city_name + "-" + city_uf}</Subtitle>
			</Description>
			<ButtonContainer>
				<Button
					type="normal"
					css="delete"
					placeholder="Excluir"
					on_click={exclude}
					width="80px"
				/>
			</ButtonContainer>
		</Container>
	);
}

export default Address;
