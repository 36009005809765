import styled from "styled-components";

export const Container_Article = styled.article`
	width: 70vw;
	height: 40vh;
	max-height: 400px;
	max-width: 900px;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const Content_Section = styled.section`
	width: 100%;
	height: 80%;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const ImageContainer_Div = styled.div`
	width: 399px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		width: 300px;
		height: auto;
	}
`;

export const ProductInfoContainer_Div = styled.div`
	flex: 1;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const ProductInfoContent_Div = styled.div`
	height: 50%;
	display: flex;
	flex-direction: column;

	flex-direction: column;
	justify-content: space-between;

	h2 {
		font-size: 24px;
		color: #222;
	}

	.product-price {
		font-size: 18px;
		color: #2662f0;
	}

	button {
	}
`;
