import styled from "styled-components";

export const Content = styled.div`
	width: 95%;
	max-width: 1198px;
	height: 100%;
	margin: 0 auto;

	h1 {
		text-align: center;
	}
`;

export const OrdersList = styled.ul`
	margin-top: 20px;
`;
