
export const configs = {
    slides(){
          const settings = {
          dots: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight:true,
          autoplay: true,
          // tempo que ele leva para fazer a transição entre slides
          speed: 500,
          // tempo que ele fica no mesmo slide
          autoplaySpeed: 5000,
        };

        return settings;
    },
    category(){
        const  settings = {
            infinite: false,

            arrows:false,
            swipeToSlide: true,
            variableWidth:true,
        }
        
        return settings



    },
    product(){
      const  settings = {
        infinite: false,
        arrows:false,
        variableWidth:true,
        swipeToSlide: true,
        
      };
    
      return settings
    },
    recipe(){
       const  settings = {
        infinite: false,
        variableWidth:true,
        arrows:false,
        swipeToSlide: true,         
       };

       return settings
    },
    banner(){
      const  settings = {
            arrows:false,
            variableWidth:true,
            swipeToSlide: true,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 5000,
            pauseOnHover: true,
            responsive:[
               {
                  breakpoint:600,
                  settings:{
                     centerMode:true,
                     row: 1,
                     centerPadding: "0px",
                  }  
               }
            ]
          };
        
        return settings
   }
}