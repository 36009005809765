import styled from "styled-components";

export const Content = styled.div`
	width: 95%;
	max-width: 1198px;
	height: 100%;
	margin: 0 auto;

	h1 {
		text-align: center;
	}
`;

export const AddressList = styled.ul`
	margin-top: 20px;
`;
export const SectionContainer = styled.article`
	display: flex;
	align-items: center;
	margin: 0 auto;
	width: 60%;
	> button {
		margin-left: auto;
	}
`;
