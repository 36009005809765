import React, { useContext, useState, useEffect } from "react";
import { Container, Header, RegisterContainer, InputContainer } from "./styles";
import * as yup from "yup";

// Components
import Input from "../../../../components/input";
import Button from "../../../../components/button/button";

import { useAuth } from "../../../useAuth";
import { useModal } from "../../../useModal";
import { validateCPF, unmaskCPF } from "../../../../utils";

//Assets
import { ReactComponent as CloseModalIcon } from "../../../../assets/icons/header/mainMenu/closeModalIcon.svg";

export function CreateAccount() {
	const newAccountDataInitialState = {
		name: "",
		email: "",
		password: "",
		cpfcnpj: "",
		rgie: "",
		type: "F",
		gender: "",
		birthdate: "",
		phone: "",
		cellphone: "",
	};

	const { createAccount } = useAuth();
	const { changeModalParams } = useModal();

	const [styles, setStyles] = useState({});
	const [inputState, setInputState] = useState({
		style: "",
		disabled: false,
	});
	const [passwordConfirmation, setPasswordConfirmation] = useState("");
	const [newAccountData, setNewAccountData] = useState(
		newAccountDataInitialState
	);
	const [inputs, setInputs] = useState({
		cpf: { style: "", isValid: false, errorMessage: "" },
		passwordConfirmation: { style: "", isValid: false, errorMessage: "" },
	});

	const handleCloseModal = () => {
		setNewAccountData(newAccountDataInitialState);
	};

	function handleCreateNewAccount(element) {
		element.preventDefault();

		const modifiedNewAccountData = {
			...newAccountData,
			cpfcnpj: unmaskCPF(newAccountData.cpfcnpj),
		};

		changeModalParams({
			type: "loadingApiResponse",
			canClose: false,
			content: {
				apiResponse: null,
			},
			customCloseButton: true,
		});

		createAccount(modifiedNewAccountData).then((response) => {
			if (response instanceof Error) {
				changeModalParams((prevState) => ({
					...prevState,
					content: { apiResponse: 400 },
				}));
			} else {
				changeModalParams((prevState) => ({
					...prevState,
					content: { apiResponse: response.status },
				}));
			}
		});
	}

	function cpfValidation(element) {
		const cpf = element;
		if (cpf.length >= 11) {
			const cpfValidation = validateCPF(cpf);
			if (cpfValidation) {
				setInputs((prevState) => ({
					...prevState,
					cpf: {
						...prevState.cpf,
						style: "",
						isValid: true,
						errorMessage: "",
					},
				}));
			} else {
				setInputs((prevState) => ({
					...prevState,
					cpf: {
						...prevState.cpf,
						style: "input-error",
						isValid: false,
						errorMessage: "CPF inválido",
					},
				}));
			}
		} else {
			setInputs((prevState) => ({
				...prevState,
				cpf: {
					style: "",
					isValid: false,
					errorMessage: "",
				},
			}));
		}
	}

	function passwordConfirmationValidation() {
		if (passwordConfirmation.length > 0) {
			if (passwordConfirmation != newAccountData.password) {
				setInputs((prevState) => ({
					...prevState,
					passwordConfirmation: {
						style: "input-error",
						isValid: false,
						errorMessage: "As senhas não coincidem",
					},
				}));
			} else {
				setInputs((prevState) => ({
					...prevState,
					passwordConfirmation: {
						style: "",
						isValid: true,
						errorMessage: "",
					},
				}));
			}
		} else {
			setInputs((prevState) => ({
				...prevState,
				passwordConfirmation: {
					style: "",
					isValid: false,
					errorMessage: "",
				},
			}));
		}
	}

	useEffect(() => {
		passwordConfirmationValidation();
	}, [passwordConfirmation, newAccountData.password]);

	useEffect(() => {
		console.log(newAccountData);
	}, [newAccountData]);

	return (
		<Container>
			<Header>
				<h2>Crie sua conta e aproveite nossas ofertas</h2>
			</Header>
			<RegisterContainer
				onSubmit={(element) => handleCreateNewAccount(element)}
			>
				<InputContainer>
					<Input
						type="Text"
						id="Name"
						placeholder="Nome"
						value={newAccountData.name}
						onChange={(element) =>
							setNewAccountData((prevState) => ({
								...prevState,
								name: element.target.value,
							}))
						}
						required={true}
					/>
					<Input
						type="Email"
						id="Email"
						placeholder="E-mail"
						value={newAccountData.email}
						onChange={(element) =>
							setNewAccountData((prevState) => ({
								...prevState,
								email: element.target.value,
							}))
						}
						required={true}
					/>
					<div class="cpfrgContainer">
						<Input
							type="Text"
							id="rgie"
							placeholder="RG"
							style={{ width: "49%" }}
							value={newAccountData.rgie}
							onChange={(element) =>
								setNewAccountData((prevState) => ({
									...prevState,
									rgie: element.target.value,
								}))
							}
						/>
						<Input
							type="Text"
							id="cpf"
							placeholder="CPF"
							style={{ width: "49%" }}
							css={inputs.cpf.style}
							value={newAccountData.cpfcnpj}
							errorMessage={inputs.cpf.errorMessage}
							onChange={(value) => {
								setNewAccountData((prevState) => ({
									...prevState,
									cpfcnpj: value,
								}));
								cpfValidation(value);
							}}
						/>
					</div>
					<Input
						type="Date"
						id="birthdate"
						placeholder="Data de nascimento"
						value={newAccountData.birthdate}
						onChange={(element) => {
							setNewAccountData((prevState) => ({
								...prevState,
								birthdate: element.target.value,
							}));
						}}
						required={true}
					/>
					<Input
						type="Password"
						id="password"
						placeholder="Senha"
						value={newAccountData.password}
						onChange={(element) => {
							setNewAccountData((prevState) => ({
								...prevState,
								password: element.target.value,
							}));
						}}
						required={true}
					/>
					<Input
						type="Password"
						id="confirmPassword"
						placeholder="Confirme sua senha"
						css={inputs.passwordConfirmation.style}
						value={passwordConfirmation}
						errorMessage={inputs.passwordConfirmation.errorMessage}
						onChange={(element) => {
							setPasswordConfirmation(element.target.value);
						}}
						required={true}
					/>
					<Input
						type="Options"
						options={[
							{ placeholder: "Masculino", value: "M" },
							{ placeholder: "Feminino", value: "F" },
						]}
						placeholder={"Gênero"}
						onChange={(element) =>
							setNewAccountData((prevState) => ({
								...prevState,
								gender: element.target.value,
							}))
						}
						required={true}
					/>
					<div className="passwordContainer">
						<Input
							type="Phone"
							id="cellphone"
							placeholder="Celular"
							style={{ width: "49%" }}
							value={newAccountData.cellphone}
							onChange={(element) => {
								setNewAccountData((prevState) => ({
									...prevState,
									cellphone: element.target.value,
								}));
							}}
						/>
						<Input
							type="Phone"
							id="phone"
							style={{ width: "49%" }}
							placeholder="Telefone"
							value={newAccountData.phone}
							onChange={(element) => {
								setNewAccountData((prevState) => ({
									...prevState,
									phone: element.target.value,
								}));
							}}
						/>
					</div>
				</InputContainer>

				<div className="create-account">
					Já é cliente?{" "}
					<span
						onClick={() =>
							changeModalParams({
								type: "login",
								canClose: true,
								content: {},
							})
						}
					>
						Fazer login
					</span>{" "}
				</div>
				<Input
					type="Submit"
					width="100%"
					css={
						inputs.cpf.isValid && inputs.passwordConfirmation.isValid
							? "normal"
							: "disabled"
					}
					//isLoading={buttonState.isLoading}
					placeholder={"Criar conta"}
				/>
			</RegisterContainer>
		</Container>
	);
}
