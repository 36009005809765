import styled from "styled-components";

const Container = styled.section`
	max-width: 409px;
	min-width: 314px;
	max-height: 338px;
	width: 41vw;
	height: 36vh;

	@media screen and (max-width: 500px) {
		display: flex;

		align-items: center;
		justify-content: center;
		flex-direction: column;

		min-width: calc(100vw - 40px);
		min-height: calc(100vh - 40px);
	}
`;

const Title = styled.header`
	text-align: center;
	padding-top: 20px;
	h1 {
		font-style: normal;
		font-weight: bold;
		font-size: 34px;
		color: var(--black);
	}
	h2 {
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		color: var(--grey);
	}
`;

const Animation = styled.section`
	width: 100%;
	height: 160px;
`;

const Footer = styled.footer`
	width: 100%;
`;

export { Container, Title, Animation, Footer };
