import styled from "styled-components";
import {
	Title as TitleModel,
	Subtitle as SubtitleModel,
} from "../../../../styles/global";

export const Container = styled.li`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;

	list-style: none;
	height: 120px;
	border-radius: 8px;
	border: solid 1px var(--light-grey);

	&:not(:first-of-type) {
		margin-top: 20px;
	}
`;

export const IconContainer = styled.div`
	width: 120px;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 50px;
	}
`;

export const Description = styled.div`
	width: 60%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	flex: 1;
`;

export const Title = styled(TitleModel)``;

export const Subtitle = styled(SubtitleModel)``;

export const ButtonContainer = styled.div`
	width: 120px;

	display: flex;
	justify-content: center;
	align-items: center;
`;
