import { useEffect, useState } from "react";
import { ReactComponent as LongArrow } from "../../../../../../assets/icons/system/long_arrow.svg";
import api from "../../../../../../services/api";

import { CategoryContainer, Category, Content } from "./styles";

import { Link } from "react-router-dom";

export function WrapperCard(props) {
	const [category, setCategory] = useState({ groups: [] });

	async function getGroupsFromCategory(categoryCode) {
		let groupsFromCategory = [];
		await api(`/front/groups?cat=${categoryCode}`).then((response) => {
			//console.log(response);
			if (response.data.groups) {
				return response.data.groups.forEach((group) => {
					if (
						group.grup_status === "N" &&
						group.countproduct > 0 &&
						groupsFromCategory.length < 3
					) {
						return (groupsFromCategory = [...groupsFromCategory, group]);
					}
				});
			}
		});
		return groupsFromCategory;
	}

	useEffect(() => {
		getGroupsFromCategory(props.category.cat_code).then((response) => {
			setCategory({ groups: response });
		});
	}, []);

	return (
		<CategoryContainer>
			<Category>
				<Content>
					<h2>{props.category.cat_description}</h2>
					<div>
						{category.groups?.map((group) => {
							return (
								<span key={group.grup_code}>
									<Link to={`/listByGroups?groupCode=${group.grup_code}`}>
										{group.grup_description}
									</Link>
								</span>
							);
						})}{" "}
					</div>

					<Link
						to={`/listByCategories?categoryCode=${props.category.cat_code}`}
					>
						Ver mais <LongArrow />
					</Link>
				</Content>
			</Category>
		</CategoryContainer>
	);
}
