import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import "./styles.scss";

// Components
import CheckoutPage from "../../../components/page/checkoutPage";
import Button from "../../../components/button/button";

import { ModalContext } from "../../../components/modal/context";

// Assets
import { ReactComponent as WithdrawOnMaketIcon } from "../../../assets/icons/checkout/withdrawOnMarket.svg";
import { ReactComponent as ReceiveAtHomeIcon } from "../../../assets/icons/checkout/receiveAtHome.svg";

import { useAuth, useOrder, useModal } from "../../../hooks";

export default function Cart(props) {
	const history = useHistory();

	const { loggedUser } = useAuth();
	const { handleChangeDeliveryMode, orderData } = useOrder();
	const { openModal, changeModalParams, changeCustomStyles } = useModal();

	const [modalState, setModalState] = useState({
		modalOpened: false,
		openedModal: null,
	});

	const value = { modalState, setModalState };

	// eslint-disable-next-line
	const [sectionSubtitle, setSectionSubtitle] = useState(
		`Revise suas seus itens e escolha a forma de entrega`
	);

	const [deliveryOption, setDeliveryOption] = useState("");

	const [buttonStyle, setButtonStyle] = useState({
		style: "normal ",
		placeholder: "Selecione uma opção acima!",
		action: () => () => {},
	});

	useEffect(() => {
		if (deliveryOption) {
			switch (deliveryOption) {
				case "withdrawOnTheMarket":
					setButtonStyle((prevState) => ({
						...prevState,
						placeholder: loggedUser ? "Escolher pagamento" : "Fazer login",
						action: loggedUser
							? () => {
									history.push("/checkout/pagamento");
									handleChangeDeliveryMode(false);
							  }
							: () => {
									changeModalParams({
										type: "login",
										canClose: true,
										content: {},
									});
									changeCustomStyles();
									openModal();
							  },
					}));

					break;
				case "receiveAtHome":
					setButtonStyle((prevState) => ({
						...prevState,
						placeholder: loggedUser ? "Escolher endereço" : "Fazer login",
						action: loggedUser
							? () => {
									history.push("/checkout/endereco");
									handleChangeDeliveryMode(true);
							  }
							: () => {
									setModalState({
										modalOpened: true,
										openedModal: "ModalFormLogin",
									});
							  },
					}));
					break;
				default:
			}
		}
		// eslint-disable-next-line
	}, [deliveryOption]);

	useEffect(() => {
		if (loggedUser) {
			setButtonStyle((prevState) => ({
				...prevState,
				placeholder: orderData.request?.type_delivery
					? "Escolher endereço"
					: "Escolher pagamento",
				action: () => history.push("/checkout/endereco"),
			}));
		}
		// eslint-disable-next-line
	}, [loggedUser]);

	return (
		<>
			<CheckoutPage sectionSubtitle={sectionSubtitle}>
				<Button
					type="CheckoutOption"
					id="withdrawOnTheMarket"
					className="card"
					on_click={(element) => setDeliveryOption(element.target.id)}
					Icon={WithdrawOnMaketIcon}
					caption={{
						mainCaption: "Retirar no mercado",
						complementCaption: `Retirar em: Av. Pres. Getúlio Vargas,427`,
					}}
				/>

				{/* Implementação futura: animar o icone do motoboy */}
				<Button
					type="CheckoutOption"
					id="receiveAtHome"
					className="card"
					on_click={(element) => setDeliveryOption(element.target.id)}
					Icon={ReceiveAtHomeIcon}
					caption={{
						mainCaption: "Receber em casa",
						complementCaption: `Quero que me entreguem`,
					}}
				/>

				<ModalContext.Provider value={value}>
					<Button
						width={"100%"}
						placeholder={
							orderData.request?.items.length === 0
								? "Seu carrinho está vazio"
								: buttonStyle.placeholder
						}
						css={
							!deliveryOption || orderData.request?.items.length === 0
								? "normal disabled"
								: buttonStyle.style
						}
						on_click={buttonStyle.action}
					/>
				</ModalContext.Provider>
			</CheckoutPage>
		</>
	);
}
