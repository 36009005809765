import React, { useState, useEffect } from "react";
import {
	Container_Article,
	Content_Section,
	ImageContainer_Div,
	ProductInfoContainer_Div,
	ProductInfoContent_Div,
} from "./styles.js";

import { getPriceFormact } from "../../../../components/card/cards_function";
import Button from "../../../../components/button/button";

import api from "../../../../services/api";

export function ProductMoreInfo({ item }) {
	const [clientPrefix, setClientPrefix] = useState("");
	// if (item) {
	// 	item = {
	// 		...item,
	// 		code: item.prod_code,
	// 		name: item.prod_description,
	// 		complement: item.prod_complement,
	// 		promotionalPrice: item.prun_price,
	// 		price: item.prun_price_old,
	// 	};
	// }

	useEffect(() => {
		api.get("/front/configs").then((response) => {
			setClientPrefix(response.data.config.clie_prefix);
		});
	}, []);
	return (
		<Container_Article>
			<Content_Section>
				<ImageContainer_Div>
					{item.imageName == null ? (
						<img
							src={require(`../../../../assets/products/naoDispo.png`).default}
						/>
					) : (
						<img
							src={`https://ecommerceuploads.s3.amazonaws.com/uploads/${clientPrefix}/products/${item.imageName}`}
						/>
					)}
				</ImageContainer_Div>

				<ProductInfoContainer_Div>
					<ProductInfoContent_Div>
						<h2>{item.name}</h2>
						<span className="productDescription"></span>
						<span className="product-price">
							{item.promotionalPrice && item.price > item.promotionalPrice
								? getPriceFormact(item.promotionalPrice)
								: getPriceFormact(item.price)}
						</span>

						<Button
							type="AddWithPlaceholder"
							placeholder="Adicionar"
							item={item}
							width={"80%"}
							css="AddWithPlaceholder"
						/>
					</ProductInfoContent_Div>
				</ProductInfoContainer_Div>
			</Content_Section>
		</Container_Article>
	);
}
