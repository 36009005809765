import React, { useState } from "react";
import "./home.scss";

// Components
import Page from "../../components/page";
import Slicker from "../../components/reactSlick";
import Banner from "../../components/banner/banner";

import LoadedProductsContext from "../../components/reactSlick/context";

// import BannerTop from "../../assets/banners/banner_top.png";

// import Banner10 from "../../assets/banners/banner 10.png";
// import Banner11 from "../../assets/banners/banner 11.png";
// import Banner12 from "../../assets/banners/banner 12.png";

// import Banner2 from "../../assets/banners/banner 2.png";

// import Banner3 from "../../assets/banners/banner 3.png";
// import Banner4 from "../../assets/banners/banner 4.png";
// import Banner5 from "../../assets/banners/banner 5.png";
// import Banner6 from "../../assets/banners/banner 6.png";
// import Banner7 from "../../assets/banners/banner 7.png";

// import Banner8 from "../../assets/banners/banner 8.png";
// import Banner9 from "../../assets/banners/banner 9.png";

import { recipes } from "../../components/support_objects/support_objects";

export default function Home() {
	const [loadedProducts, setLoadedProducts] = useState([]);

	const value = { loadedProducts, setLoadedProducts };

	return (
		<Page page_id="homePage">
			<div id="home">
				<LoadedProductsContext.Provider value={value}>
					<Banner id="bannerTopo" width="100%" images={["banner_top.png"]} />

					<Slicker
						type="slides"
						images={["banner 10.png", "banner 11.png", "banner 12.png"]}
					/>

					<Slicker type="Category" />

					<section id="discount_container">
						<Banner
							width="100%"
							images={["banner 2.png", "banner 2.png", "banner 2.png"]}
						/>
					</section>

					<Slicker type="Product" context="home" showTitle={false} />

					<Slicker type="recipe" title="Receitas">
						{recipes}
					</Slicker>

					<Banner
						id={"slider-promo"}
						width="100%"
						images={[
							"banner 3.png",
							"banner 4.png",
							"banner 5.png",
							"banner 6.png",
							"banner 7.png",
						]}
					/>

					<Slicker type="Product" showTitle={false} />

					<Slicker type="recipe" title="Receitas">
						{recipes}
					</Slicker>

					<Banner width="100%" images={["banner 8.png", "banner 9.png"]} />
				</LoadedProductsContext.Provider>
			</div>
		</Page>
	);
}
