import React, { useContext, useRef, useState } from "react";
import "./styles.scss";

// Components
import Input from "../../../../components/input";
import Button from "../../../../components/button/button";

//Hooks
import { useAuth } from "../../../useAuth";
import { useModal } from "../../../useModal";

//Assets
import { ReactComponent as CloseModalIcon } from "../../../../assets/icons/header/mainMenu/closeModalIcon.svg";
import { ReactComponent as LoginIcon } from "../../../../assets/icons/login/login.svg";

import { useHistory } from "react-router";

//Functions
import { validateCPF, unmaskCPF } from "../../../../utils";

export function Login({ redirectTo }) {
	const { signIn } = useAuth();
	const history = useHistory();
	const { changeModalParams, openModal, closeModal } = useModal();

	const [loginData, setLoginData] = useState({
		login: "",
		password: "",
	});
	const { login, password } = loginData;

	const [buttonState, setButtonState] = useState({
		style: "normal",
		placeholder: "Entrar",
		isLoading: false,
	});
	const [formState, setFormState] = useState({
		CPFinput: {},
		passwordInput: {},
		cpfValition: false,
	});

	const [CPFvalidation, setCPFValidation] = useState("");

	const handleUserLogin = async (login, password) => {
		const modifiedLogin = unmaskCPF(login);

		setButtonState((prevState) => ({
			...prevState,
			isLoading: true,
			style: "disabled",
		}));

		setFormState((prevState) => ({
			...prevState,
			CPFinput: {
				...prevState.CPFinput,
				disabled: true,
			},
			passwordInput: { ...prevState.passwordInput, disabled: true },
		}));

		signIn(modifiedLogin, password).then((response) => {
			switch (response.status) {
				case 200:
					setFormState((prevState) => ({
						...prevState,
						CPFinput: {
							disabled: true,
							style: "input-success",
						},
						passwordInput: { disabled: true, style: "input-success" },
					}));

					setButtonState((prevState) => ({
						...prevState,
						style: "success",
						isLoading: false,
						placeholder: "Login realizado com sucesso!",
					}));
					setTimeout(() => {
						closeModal();

						if (redirectTo) {
							history.push(redirectTo);
						}
					}, 1200);
					break;
				case 204:
					setFormState((prevState) => ({
						...prevState,
						CPFinput: {
							disabled: false,
							style: "normal input-error",
							errorMessage: "Usuário ou senha incorretos",
						},
						passwordInput: { disabled: false, style: "input-error" },
					}));

					setButtonState((prevState) => ({
						...prevState,
						style: "normal",
						isLoading: false,
						placeholder: "Entrar",
					}));
			}
		});
	};

	const validateCPFOnInputChange = (CPF) => {
		const validationOptions = {
			true() {
				return setCPFValidation(true);
			},
			false() {
				return setCPFValidation(false);
			},
		};
		return validationOptions[validateCPF(CPF)]();
	};

	const formLoginContainerRef = useRef();

	const { CPFinput, passwordInput, cpfValition } = formState;

	const handleCloseModal = () => {
		setLoginData({ login: "", password: "" });
	};

	//Alterar tudo para form e Styled Components

	return (
		<div className="formLoginContainer">
			<div className="formLoginContent">
				<div className="illustrationContainer">
					<LoginIcon />
				</div>

				<div className="titleSearchContainer">
					<span className="title">
						Faça seu login e aproveite as melhores ofertas
					</span>
				</div>
				<div className="inputSearchContainer">
					<Input
						type="Text"
						id="CPF"
						placeholder="CPF"
						css={CPFinput.style}
						value={login}
						disabled={CPFinput.disabled}
						onChange={(value) => {
							setLoginData((prevState) => ({
								...prevState,
								login: value,
							}));
							validateCPFOnInputChange(value);
						}}
						validation={CPFvalidation}
						errorMessage={CPFinput?.errorMessage}
					/>
					<Input
						type="Password"
						id="userPASSWORD"
						placeholder="Senha"
						css={passwordInput.style}
						value={password}
						disabled={passwordInput.disabled}
						onChange={(element) =>
							setLoginData((prevState) => ({
								...prevState,
								password: element.target.value,
							}))
						}
					/>
				</div>

				<div className="create-account">
					Não tem conta?{" "}
					<span
						onClick={() => {
							changeModalParams({
								type: "createAccount",
								canClose: true,
								content: {},
							});
							openModal();
						}}
					>
						Criar conta
					</span>
				</div>
				<Button
					type="normal"
					width="100%"
					isLoading={buttonState.isLoading}
					css={CPFvalidation && password ? buttonState.style : "disabled"}
					placeholder={buttonState.placeholder}
					on_click={() => handleUserLogin(login, password)}
				/>
			</div>
		</div>
	);
}
