import styled from "styled-components";

export const Container = styled.nav`
	position: absolute;

	max-width: var(--max-width);

	background: #ffffff;

	box-sizing: border-box;
	border-radius: 0px 0px 8px 8px;

	margin-top: 18px;

	border: 1px solid #e8e9eb;

	display: none;
`;

export const CategoryList = styled.ul`
	display: flex;
	flex-wrap: wrap;
	padding: 10px;
`;

export const CategoryContainer = styled.li`
	width: 182px;

	background-color: var(--white);
	margin: 5px;

	border: 1px solid #e8e9eb;
	border-radius: 4px;

	position: relative;

	height: 220px;

	img {
		position: absolute;
		z-index: 1;
		right: 1px;
	}
`;

export const Category = styled.nav`
	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;
	z-index: 2;

	height: 100%;
`;

export const Content = styled.li`
	width: 80%;
	display: flex;
	flex-direction: column;

	h2::first-line {
		color: var(--black);
		font-size: 16px;
	}
	h2 {
		height: 41px;
		font-size: 14px;
		margin-bottom: 10px;
		max-width: 50%;
		&:not(::first-line) {
			font-size: 12px;
		}
	}

	span {
		color: var(--grey);

		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 25px;
	}

	div {
		height: 80px;

		display: flex;
		flex-direction: column;
	}

	a {
		color: inherit;
		height: 50px;

		&:hover {
			color: var(--primary);
		}
	}
	> a {
		line-height: 50px;
	}
`;
