// Dependencies
import React, {
	useContext,
	useEffect,
	useRef,
	useState,
	createContext,
} from "react";
import api from "../../services/api";

import "./css/styles.scss";

// Components
import Header from "./header";
import Footer from "./footer";
import { ModalContext } from "../modal/context";

// Functions
import { HideMenuMobile } from "./page_function";

// Icons
import { ReactComponent as HomeIcon } from "../../assets/icons/system/home.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/system/search.svg";
import { ReactComponent as ListIcon } from "../../assets/icons/system/list.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/system/profile.svg";

import { useAuth } from "../../hooks/useAuth";

export const PageProvider = createContext({});

export default function Page({ hasFooter = true, page_id, children }) {
	const menu_mobile = useRef();

	// Bug de objetc null, adicionar funcao para verificar o
	// width da tela que deve resolver
	const { setLoggedUser, signOut } = useAuth();

	const { modalState } = useContext(ModalContext);

	const [clientPrefix, setClientPrefix] = useState("");

	useEffect(() => {
		const favicon = document.getElementById("favicon");
		const title = document.getElementById("title");

		const clientName = window.location.hostname.substr(4);

		switch (clientName) {
			case "andreazza.superandreazza.com.br":
				favicon.href =
					require("./../../assets/icons/favicon_andreazza.png").default;
				favicon.type = "image/png";
				title.innerHTML = "Andreazza | O melhor da região";

				break;
			case "loja.veronasupermercados.com.br":
				favicon.href =
					require("./../../assets/icons/favicon_verona.png").default;
				favicon.type = "image/png";
				title.innerHTML = "Verona | Bem vindo ao nosso e-commerce";

				break;
			default:
				favicon.href = "/favicon.ico";
				favicon.type = "image/ico";
				title.innerHTML = "E-commerce";
		}

		api.get("/front/configs").then((response) => {
			setClientPrefix(response.data.config.clie_prefix);
		});
	}, []);

	return (
		<PageProvider.Provider value={{ clientPrefix }}>
			<div className="page_container" id={page_id}>
				<Header />

				<div className="main">
					<div className="itemsContainer">{children}</div>
				</div>

				<div
					style={{
						position: "absolute",
						right: "10px",
						top: "10px",
						zIndex: 999,
					}}
				></div>

				{hasFooter ? <Footer /> : ""}

				<div ref={menu_mobile} className="menu_mobile_row">
					<div className="menu_mobile_container">
						<div className="home_icon_container">
							<HomeIcon />
							Home
						</div>
						<div className="search_icon_container">
							<SearchIcon />
							Busca
						</div>
						<div className="list_icon_container">
							{/* <Link to="/lista" className='logo_container'> */}
							<ListIcon />
							Lista
							{/* </Link> */}
						</div>
						<div className="profile_icon_container">
							<ProfileIcon />
							Perfil
						</div>
					</div>
				</div>
			</div>
		</PageProvider.Provider>
	);
}
