import React from "react";
import Routes from "./routes";
import "./App.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Context
import { GlobalStyle } from "./styles/global";

toast.configure();
function App() {
	return (
		<>
			<Routes />
			<GlobalStyle />
			<ToastContainer />
		</>
	);
}

export default App;
