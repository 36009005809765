import React, { useState } from "react";
import "./styles.scss";

import Page from "../../components/page";
import Input from "../../components/input";
import api from "../../services/api";
import { validateCPF } from "../../utils";

export default function Register() {
	const [inputDisabled, setInputDisabled] = useState(false);

	const [name, setName] = useState();
	const [phone, setPhone] = useState();
	const [CPFCNPJ, setCPFCNPJ] = useState();
	const [email, setEmail] = useState();
	const [gender, setGender] = useState();

	//Função para criar o usuário no servidor
	const createNewUser = () => {
		api.post("front/user").then((response) => {
			console.log(response);
		});
	};

	//Ação disparada pelo usuário ao clicar em "criar conta"
	const HandleCreateUser = (element) => {
		element.preventDefault();
		setInputDisabled(true);

		if (validateCPF(CPFCNPJ)) {
			const userData = {
				name: name,
				phone: phone,
				cpfcnpj: CPFCNPJ,
				email: email,
				gender: gender,
			};
			createNewUser(userData).then((response) => {
				console.log(response);
			});
		} else {
			alert("CPF inválido, favor verificar e tentar novamente");
		}
	};

	return (
		<Page page_id="registerPage" hasFooter={false}>
			<div className="formRegisterContainer">
				<div className="formRegisterContent">
					<div className="header">
						<h1>Criar conta</h1>
						<p className="subtitle">
							Insira suas informações e clique em "criar conta"
						</p>
					</div>

					<form action="" onSubmit={(element) => HandleCreateUser(element)}>
						<Input
							type="Text"
							id="Name"
							placeholder="Nome Completo"
							value={name}
							required="true"
							onChange={(element) => setName(element.target.value)}
							disabled={inputDisabled}
						/>
						<Input
							type="Text"
							placeholder="Telefone"
							required="true"
							value={phone}
							onChange={(element) => setPhone(element.target.value)}
							disabled={inputDisabled}
						/>
						<Input
							type="Text"
							required="true"
							placeholder="CPF"
							value={CPFCNPJ}
							onChange={(element) => setCPFCNPJ(element.target.value)}
							disabled={inputDisabled}
						/>
						<Input
							type="Radio"
							required="true"
							name="Gênero"
							value={gender}
							radioOptions={["Masculino", "Feminino", "Outro"]}
							onChange={(element) => setGender(element.target.value)}
							disabled={inputDisabled}
						/>
						<Input
							type="Text"
							placeholder="E-mail"
							required="true"
							value={email}
							onChange={(element) => setEmail(element.target.value)}
							disabled={inputDisabled}
						/>

						<Input
							type="Submit"
							placeholder="Criar conta"
							disabled={inputDisabled}
						/>
					</form>
				</div>
			</div>
		</Page>
	);
}
