import React, {
	useState,
	useRef,
	useEffect,
	useContext,
	useCallback,
} from "react";
import { Link } from "react-router-dom";

// Components
import { ModalContext } from "../../../modal/context";
import Button from "../../../button/button";

import Input from "../../../input";
import api from "../../../../services/api";
import { search } from "../../../../utils";
import { CategoryWrapper } from "./categoryWrapper";

// Assets
import { ReactComponent as Logo } from "../../../../assets/icons/client_logo.svg";
import { useAuth, useUser, useModal } from "../../../../hooks";

import { PageProvider } from "../../index";

export default function DesktopHeader() {
	const { clientPrefix } = useContext(PageProvider);

	const { loggedUser, setLoggedUser } = useAuth();
	const { getUserAddresses } = useUser();
	const {
		openModal,
		changeModalParams,
		changeCustomStyles,
		changeCustomCloseButton,
	} = useModal();

	const [userAdressess, setUserAddresses] = useState(null);

	const header = useRef();
	const SearchContentRef = useRef();

	const [addressMenuIsOpened, setAddressMenuIsOpened] = useState(false);
	const [clicked, setClicked] = useState(null);
	const { modalState, setModalState } = useContext(ModalContext);

	const value = { modalState, setModalState };

	const [categories, setCategories] = useState([]);
	const [searchState, setSearchState] = useState({
		products: null,
		loading: false,
	});
	const [searchbarValue, setSearchbarValue] = useState("");

	const changeDeliveryAddress = (addressCode) => {
		let newAdressesList = loggedUser.adresses;
		loggedUser.adresses.map((address, index) => {
			if (address.code == addressCode) {
				// setLoggedUser({
				// 	...loggedUser,
				// 	address.usad_default = true});
			}
		});
	};

	const HandleSearchbarActions = (action) => {
		const searchbarActions = {
			select() {
				SearchContentRef.current.classList.add("enabled");
			},
		};

		return searchbarActions[action.type]();
	};

	const HandleOpenModalProductMoreInfo = (item) => {
		const newItem = {
			category: item.cat_code,
			code: item.prod_code,
			complement: item.prod_complement,
			group: item.grup_code,
			imageName: item.prim_image,
			name: item.prod_description,
			price: item.prun_price,
			priceOld: item.prun_price_old,
		};
		changeModalParams({
			type: "productMoreInfo",
			canClose: true,
			content: { item: newItem },
		});
		changeCustomStyles((prevState) => ({
			overlay: {
				...prevState.overlay,
				display: "flex",
				justifyContent: "center",
			},
			content: {
				...prevState.content,
				transform: "unset",
				inset: "unset",
				margin: "0 auto",
				borderRadius: "0px 0px 8px 8px",
			},
		}));
		openModal();
	};

	const searchProducts = useCallback(
		async (props) => {
			const product = searchbarValue.toLowerCase();
			console.log(searchbarValue.toLocaleLowerCase());
			console.log(product);
			const apiResponse = await search(`/front/product/list?search=${product}`);
			return apiResponse.products;
		},
		[searchbarValue]
	);

	const renderProductsSearched = () => {
		let products = (
			<p className="default-caption">Pesquise aqui seus produtos</p>
		);
		const searchedProducts = searchState.products;

		if (!searchbarValue) return products;

		if (searchState.loading) {
			return (
				<div className="loading-animation-container">
					<span>Buscando produtos </span>
				</div>
			);
		}

		if (searchState.notFound) {
			return <div className="default-caption">Produto não encontrado</div>;
		}

		if (searchedProducts && searchedProducts.length > 0) {
			return (
				<>
					<ul className="searchresults-container">
						{searchState.products.map((product) => {
							return (
								<li
									key={product.prod_code}
									style={{}}
									onClick={() => HandleOpenModalProductMoreInfo(product)}
								>
									{product.prim_image == null ? (
										<img
											src={
												require(`../../../../assets/products/naoDispo.png`)
													.default
											}
											alt={`Imagem ilustrativa do Produto ${product.prod_description}`}
											style={{ pointerEvents: "none" }}
										/>
									) : (
										<img
											src={`https://ecommerceuploads.s3.amazonaws.com/uploads/${clientPrefix}/products/${product.prim_image}`}
											alt=""
											srcset=""
											style={{ pointerEvents: "none" }}
										/>
									)}
									<span style={{ pointerEvents: "none" }}>
										{product.prod_description}
										<br />
										<span
											style={{ fontSize: "12px", color: "#ccc", padding: "0" }}
										>
											{product.prod_complement}
										</span>
									</span>
								</li>
							);
						})}
					</ul>
					<Link
						to={`/busca?search=${searchbarValue}`}
						style={{ width: "100%", display: "inline-block" }}
					>
						<span
							style={{
								width: "100%",
								display: "inline-block",
								margin: "10px 0 10px 20px",
								color: "#3a70f0",
								fontSize: "16px",
								display: "flex",
								alignItems: "center",
							}}
						>
							<div
								style={{
									width: "30px",
									height: "30px",
									borderRadius: "15px",
									background: "#3a70f0",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									marginRight: "20px",
								}}
							>
								<i
									className="fa fa-search"
									aria-hidden="true"
									style={{ color: "#fff" }}
								></i>
							</div>
							Pesquisar
							<strong style={{ marginLeft: "5px" }}>{searchbarValue}</strong>
						</span>
					</Link>
				</>
			);
		}
	};

	const handleOpenCart = () => {
		changeCustomStyles((prevState) => ({
			...prevState,
			content: {
				height: "100%",
				width: "460px",
				borderRadius: "8px 0 0 8px",
				padding: 0,
				inset: "auto 0px auto auto",
			},
		}));
		changeModalParams({
			type: "cart",
			canClose: true,
			content: {},
			customCloseButton: true,
		});
		openModal();
	};

	const renderDefaultAddress = () => {
		let defaultAddress = [];
		if (userAdressess) {
			for (var i = 0; i < userAdressess.length; i++) {
				if (userAdressess[i].usad_default == true) {
					defaultAddress =
						userAdressess[i].usad_address +
						" Nº" +
						userAdressess[i].usad_number;
					break;
				}
			}
		}

		return defaultAddress;
	};

	useEffect(() => {
		header.current.addEventListener("animationend", (event) => {
			if (event.animationName == "menu_desktop_out") {
				header.current.classList.add("DisplayNone");
			} else {
				header.current.classList.remove("DisplayNone");
			}
		});

		document.addEventListener("click", (event) => {
			event = event.target.id;
			if (
				event != "menu" &&
				event != "searchItem" &&
				event != "searchbar" &&
				SearchContentRef.current != null
			) {
				SearchContentRef.current.classList.remove("enabled");
			}
		});

		if (loggedUser) {
			getUserAddresses(loggedUser.userToken).then((response) => {
				setUserAddresses(response);
			});
		}

		api.get("front/categories").then((response) => {
			const filteredCategories = response.data.categories.flatMap((category) =>
				category.cat_status == "N" && category.countproduct > 0 ? category : []
			);

			setCategories(filteredCategories);
		});
	}, []);

	useEffect(() => {
		if (searchbarValue) {
			console.log(searchbarValue);
			setSearchState({ products: null, loading: true });
			searchProducts(searchbarValue).then((response) => {
				console.log(response);
				setSearchState(
					response.length > 0
						? { products: response, loading: false }
						: { loading: false, notFound: true }
				);
			});
		}
	}, [searchbarValue]);

	return (
		<>
			<header id="header_desktop" ref={header}>
				<div className="menu" id="menu">
					<div className="itemsContainer">
						<div className="menu_hamburguer_column">
							<Button
								type="OpenModal"
								icon="fa fa-bars"
								modalParams={{ type: "mainMenu", canClose: true, content: {} }}
								modalCustomStyles={(prevState) => ({
									...prevState,
									content: {
										...prevState.content,
										transform: "unset",
										inset: "2.5% auto auto 2%",
										padding: "0",
									},
								})}
							/>
						</div>

						<Link to="/" className="logo_container">
							<Logo id="logo" />
							{/* <img
								src={
									require("../../../../assets/icons/client_logo.png")
										.default
								}
								alt=""
								style={{ width: "120px" }}
								srcset=""
							/> */}
						</Link>

						<div className="searchbar_column">
							<Input
								id="searchbar"
								type="Searchbar"
								placeholder="O que você precisa hoje?"
								onSelect={HandleSearchbarActions}
								onChange={(element) => {
									setSearchbarValue(element);
								}}
							/>
							<div
								ref={SearchContentRef}
								className="search-container buttonBoxShadow"
								onClick={() =>
									SearchContentRef.current.classList.add("enabled")
								}
							>
								{renderProductsSearched()}
							</div>
						</div>

						<div className="address_column">
							{loggedUser ? (
								<div className="address_container">
									<div className="caption">Endereço de entrega</div>
									<div className="address">
										<p>{renderDefaultAddress()}</p>
									</div>
									<i
										className="fa fa-angle-down"
										id="arrow"
										aria-hidden="true"
										onClick={() => setAddressMenuIsOpened(!addressMenuIsOpened)}
									/>
									{addressMenuIsOpened ? (
										<ul className="addressMenuContainer">
											{userAdressess &&
												userAdressess.map((address, index) => {
													return (
														<li className="addressContainer">
															{address.usad_default == true ? (
																<input
																	type="radio"
																	id={index}
																	name="address"
																	value={address.usad_address}
																	checked={true}
																	onClick={() =>
																		changeDeliveryAddress(address.usad_code)
																	}
																/>
															) : (
																<input
																	type="radio"
																	id={index}
																	name="address"
																	value={address.usad_address}
																/>
															)}

															<label for={index}>
																<span className="title">
																	{address.usad_address +
																		" Nº" +
																		address.usad_number}
																</span>
																<span className="complement">
																	{address.dist_name} - {address.city_name}
																</span>
															</label>
														</li>
													);
												})}
										</ul>
									) : null}
								</div>
							) : (
								<div className="address_container">
									<div className="caption">Você não está logado</div>
									<div className="address">
										Clique
										<a
											onClick={() => {
												changeModalParams({
													type: "login",
													canClose: true,
													content: {},
												});
												changeCustomStyles();
												openModal();
											}}
										>
											{" "}
											aqui{" "}
										</a>
										para fazer login
									</div>
								</div>
							)}
						</div>
						{/* <ModalContext.Provider value={value}>
							<button
								onClick={() =>
									setModalState({
										modalOpened: true,
										openedModal: "AddNewAddress",
									})
								}
								className="addNewAdress buttonBoxShadow"
							>
								+ Adicionar novo endereço
					</button>
						</ModalContext.Provider> */}
						<Link to="#" className="buylist_button_container">
							<Button
								width="180px"
								height="50px"
								placeholder="Lista de compras"
								type="list"
								css="list"
								on_click={() => alert("Funcionalidade disponível em breve")}
							/>
						</Link>

						<div className="cart_button_container">
							<Button
								width="180px"
								height="50px"
								placeholder="Carrinho"
								type="Cart"
								css="cart"
								on_click={handleOpenCart}
							/>
						</div>
					</div>
					{/*Fim ITENS_CONTAINER */}
					<div className="sub_menu">
						<div className="itemsContainer">
							<nav>
								<li>
									<i className="fa fa-shopping-basket" aria-hidden="true"></i>
									Departamentos
									<CategoryWrapper categories={categories} />
								</li>
							</nav>
							<nav className="mainly-sectors">
								<ul>
									<li>
										<Link to="/listByCategories?categoryCode=8">Padaria</Link>
									</li>
									<li>
										<Link to="/listByCategories?categoryCode=6">Carnes</Link>
									</li>
									<li>
										<Link to="/listByCategories?categoryCode=7">
											Hortifruti
										</Link>
									</li>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</header>
		</>
	);
}
