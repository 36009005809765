import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Content, AddressList, SectionContainer } from "./styles.js";
import { toast } from "react-toastify";

// Components
import Page from "../../../components/page";
import Button from "../../../components/button/button";
import { Address } from "./address/index";

import { useAuth, useModal, useUser } from "../../../hooks";

export function Addresses() {
	const [addresses, setAddresses] = useState([]);
	const { loggedUser } = useAuth();
	const { deleteUserAddress, getUserAddresses } = useUser();
	const { changeModalParams, openModal, changeCustomStyles, closeModal } =
		useModal();

	const history = useHistory();

	function handleDeleteUserAddress(addressCode) {
		changeModalParams((prevState) => ({
			...prevState,
			type: "confirmation",
			canClose: true,
			customCloseButton: false,
			content: {
				confirmationOptions: {
					accept: async () => {
						await deleteUserAddress({
							token: loggedUser.userToken,
							addressId: addressCode,
						});
						closeModal();
						toast.success("Endereço removido com sucesso", {
							position: "top-right",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
					},
					reject: () => {
						toast.warn("Operação cancelada", {
							position: "top-right",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
						closeModal();
					},
				},
			},
		}));
		changeCustomStyles((prevState) => ({
			...prevState,
			overlay: {
				...prevState.overlay,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
			content: {
				borderRadius: "8px",
				inset: "unset",
			},
		}));
		openModal();
	}

	const HandleAddNewAddressButtonClick = () => {
		changeModalParams({
			type: "addNewAddress",
			content: {},
			canClose: true,
		});
		changeCustomStyles((prevState) => ({
			...prevState,
			overlay: {
				...prevState.overlay,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
			content: {
				borderRadius: "8px",
				inset: "unset",
			},
		}));
		openModal();
	};

	useEffect(() => {
		if (!loggedUser) {
			return history.push("/");
		} else {
			getUserAddresses(loggedUser.userToken).then((response) => {
				setAddresses(response);
			});
		}
	}, []);

	return (
		<Page page_id="ordersPage">
			<Content>
				<SectionContainer>
					<h1>Endereços de entrega</h1>
					<Button
						type="normal"
						css="normal"
						placeholder="Adicionar novo"
						width="342px"
						on_click={() => HandleAddNewAddressButtonClick()}
					/>
				</SectionContainer>

				<AddressList>
					{addresses.map((address) => {
						return (
							<Address
								address={address}
								exclude={() => handleDeleteUserAddress(address.usad_code)}
							/>
						);
					})}
				</AddressList>
			</Content>
		</Page>
	);
}
