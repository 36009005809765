import React from "react";

const loadedProducts = React.createContext({
	loadedProducts: [
		// possivel estrutura futura, para otimizar as buscas
		// {
		// 	category: null,
		// 	groups: [
		// 		{
		// 			code: null,
		// 			items: [],
		// 		},
		// 	],
		// },
	],
	setLoadedProducts: () => {},
});

export default loadedProducts;
