import React, { useState } from "react";
import {
	Container,
	DotContainer,
	Dot,
	Description,
	Title,
	Subtitle,
	ButtonContainer,
} from "./styles";

import Button from "../../../../components/button/button.js";

export function Order({ info }) {
	const order = info;
	const [isActive, setIsActive] = useState(false);
	return (
		<Container>
			<DotContainer>
				<Dot active={isActive} />
			</DotContainer>
			<Description>
				<Title>{order.date_delivery}</Title>
				<Subtitle>
					{order.items.length +
						(order.items.length > 1 ? " items" : "item")}
				</Subtitle>
			</Description>
			<ButtonContainer>
				<Button
					type="normal"
					css="neutral"
					width="80px"
					placeholder="Ver mais"
					on_click={() => setIsActive(!isActive)}
				/>
			</ButtonContainer>
		</Container>
	);
}

export default Order;
