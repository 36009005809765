import React, { useEffect, useState, createContext } from "react";
import api from "../../../services/api";
import Slicker from "../../../components/reactSlick";
import "../styles.scss";
import { useHistory } from "react-router-dom";

import Page from "../../../components/page";

export const ListByCategoriesContext = createContext({});

export function ByCategories(props) {
	const params = new URLSearchParams(props.location.search);
	const history = useHistory();
	const categoryCode = params.get("categoryCode");
	const [groups, setGroups] = useState([]);
	const [isLoading, setIsLoading] = useState();

	async function getGroupsFromCategory(categoryCode) {
		console.log(categoryCode);
		let groupsFromCategory = [];
		await api(`/front/groups?cat=${categoryCode.toString()}`).then(
			(response) => {
				if (response.data.groups) {
					return response.data.groups.forEach((group) => {
						if (group.grup_status === "N" && group.countproduct > 0) {
							return (groupsFromCategory = [...groupsFromCategory, group]);
						}
					});
				}
			}
		);
		return groupsFromCategory;
	}

	useEffect(() => {
		setIsLoading(true);
		getGroupsFromCategory(categoryCode).then((response) => {
			setGroups(response);
			setIsLoading(false);
		});

		// eslint-disable-next-line
	}, [categoryCode]);

	return (
		<Page page_id="pageByCategories">
			<ListByCategoriesContext.Provider value={{ categoryCode }}>
				<div id="products">
					<div className="content">
						<div className="categoriesListContainer">
							<Slicker
								style={{ style: "categoriesSlicker" }}
								type="Category"
								selected={categoryCode}
							/>
						</div>
						<div className="selectedCategorieContainer">
							{console.log(groups)}
							{isLoading ? (
								<>
									<Slicker type="Product" group={{}} context="home" />
									<Slicker type="Product" group={{}} context="home" />
									<Slicker type="Product" group={{}} context="home" />
									<Slicker type="Product" group={{}} context="home" />
								</>
							) : groups.length == 0 ? (
								<>
									<h1>Ops! Algo deu errado</h1>
									<p class="subtitle">
										Parece que a categoria não tem produtos
									</p>
								</>
							) : (
								groups.map((group, index) => (
									<section
										id={group.grup_description}
										className="productsByGroupListContainer"
										key={index}
									>
										<Slicker
											type="Product"
											group={group.grup_code}
											context="home"
										/>
									</section>
								))
							)}
						</div>
					</div>
				</div>{" "}
			</ListByCategoriesContext.Provider>
		</Page>
	);
}
