import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { configs } from "./react_slick_config";

import api from "../../services/api";
import Slider from "react-slick";
import Card from "../card/card";
import LoadingCards from "./loadingCards";

import "./css/react_slick.scss";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

export default function SimpleSlider(props) {
	const history = useHistory();

	const SelectSlickType = {
		slides(slides) {
			return (
				<div id="slides_container" id={props.id}>
					<Slider {...configs["slides"]()}>
						{slides.images.map((image) => (
							<div>
								<img
									style={{ width: "100%", height: "100%" }}
									src={require(`../../assets/banners/${image}`).default}
								/>
							</div>
						))}
					</Slider>
				</div>
			);
		},
		Category(props) {
			const { selected, style } = props;
			const [categories, setCategories] = useState(null);
			const [isLoading, setIsLoading] = useState(true);
			const numberOfCardsThatFitTheScreen = parseInt(window.innerWidth / 120);

			const getCategories = () => {
				let filteredCategories = [];
				return api.get("front/categories").then((response) => {
					response.data.categories.map((categorie) => {
						if (categorie.cat_status == "N") {
							// && categorie.countproduct > 0)
							filteredCategories = [...filteredCategories, categorie];
						}
					});
					return filteredCategories;
				});
			};

			useEffect(() => {
				getCategories().then((data) => {
					setCategories(data);
					setIsLoading(false);
				});
			}, []);

			if (isLoading && categories == null) {
				return (
					<div id="category_container" className="loading">
						<LoadingCards
							quantityOfCardsToRender={numberOfCardsThatFitTheScreen}
							type={"Category"}
						/>{" "}
					</div>
				);
			} else {
				return (
					<div id={`category_container ${style}`}>
						<Slider {...configs["category"]()}>
							{categories.map((category) => {
								return (
									<Link
										to={`/listByCategories?categoryCode=${category.cat_code}`}
									>
										<Card
											type="Category"
											category={category}
											item={{}}
											selected={category.cat_code == selected ? true : false}
										/>
									</Link>
								);
							})}
						</Slider>
					</div>
				);
			}
		},
		Product({ type, group, showTitle = true }) {
			const productsInitialState = {
				title: "",
				content: [],
				isLoading: true,
			};
			const [products, setProducts] = useState(productsInitialState);
			const numberOfCardsThatFitTheScreen = parseInt(window.innerWidth / 185);
			// Pega o tamanho da tela e divide pelo tamanho os cartões (165px) + 20px de padding left

			useEffect(() => {
				setProducts((prevState) => ({ ...prevState, isLoading: true }));

				if (group == undefined) {
					api.get(`front/product/list`).then((data) => {
						data = data.data.products;
						let content = [];
						let title = "";
						if (data && data != undefined && data.length > 0) {
							content = data.map((product) => ({
								code: product.prod_code,
								imageName: product.prim_image,
								name: product.prod_description,
								complement: product.prod_complement,
								priceOld: product.prun_price_old,
								price: product.prun_price,
								group: product.grup_code,
								category: product.cat_code,
							}));
							title = data[0].grup_description;
						}
						setProducts({
							title: title,
							content,
							isLoading: false,
						});
					});
				} else {
					api.get(`front/product/list?group=${group}`).then((data) => {
						data = data.data.products;
						let content = [];
						let title = "";
						if (data && data != undefined && data.length > 0) {
							content = data.map((product) => ({
								code: product.prod_code,
								imageName: product.prim_image,
								name: product.prod_description,
								complement: product.prod_complement,
								priceOld: product.prun_price_old,
								price: product.prun_price,
								group: product.grup_code,
								category: product.cat_code,
							}));
							title = data[0].grup_description;
						}
						setProducts({
							title: title,
							content,
							isLoading: false,
						});
					});
				}
			}, [group]);

			return (
				<>
					{products.isLoading ? (
						<section
							id={"loadingCards"}
							className="productsByGroupListContainer"
						>
							<div className="products_container loading" key={group}>
								<h1 className="title" />

								<LoadingCards
									quantityOfCardsToRender={numberOfCardsThatFitTheScreen}
									type={"Product"}
								/>
							</div>
						</section>
					) : (
						<div className="products_container" id={group} key={group}>
							{showTitle && (
								<Link to={`/listByGroups?groupCode=${group}`}>
									<h1 className="title">{products.title}</h1>
								</Link>
							)}

							<Slider {...configs["product"]()}>
								{products.content.map((product, index) => {
									return (
										<div className="container_padding" key={index}>
											<Card type={type} item={product} />
										</div>
									);
								})}
							</Slider>
						</div>
					)}
				</>
			);
		},
		recipe(recipes) {
			var title = recipes.title;
			var type = recipes.type;
			var recipes = recipes.children;

			return (
				<div className="products_container">
					<h1>{title}</h1>
					<Slider {...configs["recipe"]()}>
						{recipes.map((recipe) => (
							<div className="container_padding">
								<Card type={type} item={recipe} />
							</div>
						))}
					</Slider>
				</div>
			);
		},
		banner(banners) {
			var banner = banners.children;
			var title = banners.title;

			return (
				<div id="banner_container" className={props.className}>
					<Slider {...configs["banner"]()}>
						{banner.map((banner) => (
							<div className="container_padding">
								<img src={require(`../../assets/banners/${banner}`).default} />
							</div>
						))}
					</Slider>
				</div>
			);
		},
	};
	return (
		<>
			{/* Chama a função para saber qual tipo de Slick vai ser renderizado */}

			{SelectSlickType[props.type](props)}
		</>
	);
}
