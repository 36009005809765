import React, { useRef, useState } from "react";
import "./styles.scss";

import Input from "../../../input";

// Assets
import { ReactComponent as UserIcon } from "../../../../assets/icons/header/mainMenu/userIcon.svg";

export default function MobileHeader() {
	const teste = React.createRef();

	React.useEffect(() => {}, []);

	return (
		<>
			<header id="headerMobile">
				<div className="headerMobileContent">
					<div className="clientInfo">
						<div className="avatarContainer">
							<UserIcon />
						</div>

						<div className="adressContainer">
							<span className="caption">Endereço de entraga</span>
							<span className="adress">Luiza Verone Piccoli 32</span>
							<span className="arrow">
								<i className="fa fa-angle-down" id="arrow" aria-hidden="true" />
							</span>
						</div>
					</div>
					<Input
						type="Searchbar"
						ref={teste}
						placeholder="O que você quer hoje?"
					/>
				</div>
			</header>
		</>
	);
}
