import React, { useState, useRef, useEffect, useContext } from "react";
import "./styles.scss";
import api from "../../../../services/api";
import { useHistory } from "react-router-dom";

import Button from "../../../../components/button/button";
import Input from "../../../../components/input";

// assets
import { ReactComponent as NewAddressIcon } from "../../../../assets/icons/addNewAddressModal/newAddress.svg";
import { ReactComponent as CloseModalIcon } from "../../../../assets/icons/header/mainMenu/closeModalIcon.svg";
import { ReactComponent as CurrentLocalIcon } from "../../../../assets/icons/addNewAddressModal/locationIcon.svg";

import { useAuth, useUser, useModal } from "../../../../hooks";
import { getCoordinatesByAddress } from "../../../../services/googleMaps";

export function AddNewAddress({ wasCalled }) {
	const { loggedUser, setLoggedUser } = useAuth();
	const { closeModal } = useModal();
	const { addNewAddress } = useUser();
	const [cities, setCities] = useState([]);
	const [districts, setDistrics] = useState([]);

	const history = useHistory();

	// lembrar de add o token do usuário
	const addressInitialState = {
		address: "",
		number: null,
		zipcode: "",
		token: loggedUser ? loggedUser.userToken : "",
		default: loggedUser?.adresses ? false : true,
		district_code: "",
		district: "",
		city: "",
		complement: "",
		type: "",
		billing: false,
	};

	const [address, setAddress] = useState(addressInitialState);

	const [buttonState, setButtonState] = useState({
		style: "normal",
		placeholder: "Adicionar novo endereço",
		disabled: false,
		isLoading: false,
	});

	useState(() => {
		if (!loggedUser) {
			history.push("/checkout/carrinho");
		}
		api.get("front/cities").then((response) => {
			const cities = response.data.map((city) => {
				return {
					placeholder: city.city_name,
					value: city.city_code,
				};
			});

			setCities(cities);
		});
	}, []);

	useEffect(() => {
		if (address.city_code) {
			api.get(`front/districts?city=${address.city_code}`).then((response) => {
				console.log(response);
				const districts = response.data.map((district) => {
					return {
						placeholder: district.dist_name,
						value: district.dist_code,
					};
				});

				setDistrics(districts);
			});
		}
	}, [address.city_code]);

	const [addressSearched, setAddressSearched] = useState(null);

	async function handleAddNewAddress(e) {
		e.preventDefault();

		setButtonState((prevState) => ({
			...prevState,
			isLoading: true,
			disabled: true,
		}));

		const coordinates = await getCoordinatesByAddress({
			...address,
			publicPlace: address,
		});

		const { lat, lng } = coordinates;

		addNewAddress({ ...address, coordinates: [lat, lng] }).then((response) => {
			console.log(response);

			switch (response.status) {
				case 200:
					setButtonState((prevState) => ({
						...prevState,
						style: "input-success",
						isLoading: false,
						disabled: false,
						placeholder: "Endereço adicionado com sucesso!",
					}));
					setTimeout(() => {
						closeModal();
					}, 1800);
					break;
				case 204:
					setButtonState((prevState) => ({
						...prevState,
						style: "input-error",
						isLoading: false,
						placeholder: "Endereço adicionado com sucesso!",
					}));
			}
			let newAdresses = [];

			if (loggedUser.adresses != undefined) {
				console.log("oi");
				newAdresses.push(...loggedUser.adresses);
			}

			console.log(response.data);
			newAdresses.push(response.data);
			setLoggedUser((prevState) => ({
				...prevState,
				adresses: newAdresses,
			}));
		});
	}

	const handleCloseModal = () => {
		setAddress(addressInitialState);
	};

	return (
		<div className="modalFormAddNewAddressContainer">
			<div className="formAddNewAddressContainer">
				<div className="formNewAddressContent">
					<Button className="closeModalIcon" type="CloseModal">
						<CloseModalIcon onClick={() => handleCloseModal()} />
					</Button>

					<div className="formDataContainer">
						{/* {addressSearched == null ||
								(latitude == null && longitude == null) ? ( */}

						<div className="titleBeforeSearchContainer">
							<span className="title">
								Onde você quer <br /> receber seu pedido?
							</span>
							<span className="subtitle">
								Selecione o endereço de entrega abaixo
							</span>
						</div>
						<div className="inputBeforeSearchContainer">
							<Input
								type="Options"
								options={cities}
								id={"cities"}
								placeholder={"Sua cidade"}
								required={true}
								onChange={(element) => {
									setAddress((prevState) => ({
										...prevState,
										city_code: element.target.value,
										city: cities.find(
											(city) => city.value == element.target.value
										).placeholder,
									}));
								}}
							/>
							{address.city_code ? (
								<Input
									type="Options"
									id={"districts"}
									options={districts}
									placeholder={"Seu bairro"}
									required={true}
									onChange={(element) => {
										setAddress((prevState) => ({
											...prevState,
											district_code: element.target.value,
											district: districts.find(
												(district) => district.value == element.target.value
											).placeholder,
										}));
									}}
								/>
							) : null}

							{address.district_code ? (
								<form
									class="inputAfterSearchContainer"
									onSubmit={(element) => handleAddNewAddress(element)}
								>
									<Input
										type="Text"
										value={address.zipcode}
										id={"siteZipcode"}
										placeholder={"CEP"}
										required={true}
										onChange={(element) =>
											setAddress((prevState) => ({
												...prevState,
												zipcode: element.target.value,
											}))
										}
									/>
									<div className="numberAndComplementContainer">
										{" "}
										<Input
											type="Text"
											value={address.address}
											id={"siteAddress"}
											placeholder={"Logradouro"}
											width={"70%"}
											required={true}
											onChange={(element) =>
												setAddress((prevState) => ({
													...prevState,
													address: element.target.value,
												}))
											}
										/>
										<Input
											type="Text"
											value={address.number}
											id={"siteNumber"}
											placeholder={"Número"}
											required={true}
											onChange={(element) =>
												setAddress((prevState) => ({
													...prevState,
													number: element.target.value,
												}))
											}
										/>
									</div>
									<Input
										type="Text"
										value={address.complement}
										id={"siteComplement"}
										placeholder={"Complemento"}
										required={true}
										onChange={(element) =>
											setAddress((prevState) => ({
												...prevState,
												complement: element.target.value,
											}))
										}
									/>

									<Input
										type="Options"
										id={"typeOfAddress"}
										options={[
											{
												placeholder: "Casa",
												value: "H",
											},
											{
												placeholder: "Trabalho",
												value: "W",
											},
											{
												placeholder: "Outro",
												value: "O",
											},
										]}
										placeholder={"Tipo"}
										required={true}
										onChange={(element) =>
											setAddress((prevState) => ({
												...prevState,
												type: element.target.value,
											}))
										}
									/>
									<Input
										type="Checkbox"
										id={"billing address"}
										value={address.billing}
										onChange={() =>
											setAddress((prevState) => ({
												...prevState,
												billing: !prevState.billing,
											}))
										}
										placeholder={`Usar esse endereço como endereço de cobrança (necessário para pagamentos online)`}
									/>
									<Input
										type="Submit"
										style={buttonState.style}
										isLoading={buttonState.isLoading}
										disabled={buttonState.disabled}
										placeholder={buttonState.placeholder}
									/>
								</form>
							) : null}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
