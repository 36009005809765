import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import "./styles.scss";

import Button from "../../../../components/button/button";
// Assets
import { ReactComponent as CartIcon } from "../../../../assets/icons/cart/cart.svg";
import { ReactComponent as CloseModalIcon } from "../../../../assets/icons/header/mainMenu/closeModalIcon.svg";

import { useModal } from "../../index";

// Functions
import { getPriceFormact } from "../../../../components/card/cards_function";
import { useOrder } from "../../../useOrder";

import { PageProvider } from "../../../../components/page";
import api from "../../../../services/api";

export function Cart({ cartType = "Desktop" }) {
	const [clientPrefix, setClientPrefix] = useState("");

	const { orderData, itemsQuantity, cartValue } = useOrder();
	const [path, setPath] = useState();
	const { closeModal } = useModal();

	const { items } = orderData.request;

	useEffect(() => {
		api.get("/front/configs").then((response) => {
			setClientPrefix(response.data.config.clie_prefix);
		});
	}, []);

	const cartTypes = {
		Mobile() {
			const mobileCartContainer = useRef();

			function mobileCartOptions(option) {
				const options = {
					Open() {
						mobileCartContainer.current.classList.add("opened");
						setPath("/checkout/carrinho");
						setButtonPlaceholder("Fechar pedido");
					},
					Close() {
						mobileCartContainer.current.classList.remove("opened");
						setPath("#");
						setButtonPlaceholder("Ver Carrinho");
					},
				};

				return options[option]();
			}
			const [buttonPlaceholder, setButtonPlaceholder] =
				useState("Ver Carrinho");

			return (
				<div ref={mobileCartContainer} className="mobileCartContainer">
					<div className="mobileCartContent">
						<div className="mobileCartHeader">
							<span>Carrinho</span>
							<CloseModalIcon onClick={() => mobileCartOptions("Close")} />
						</div>

						<div className="mobileCartItems">
							{items.length == 0 ? (
								<div id="cartMessage">
									<p>Seu carrinho está vazio :(</p>
									<p>Adicione um produto ao carrinho para ele aparecer aqui!</p>
								</div>
							) : (
								items.map((item) => (
									<div className="itemContainer">
										<div className="imageContainer">
											{item.imageName == null ? (
												<img
													src={
														require(`../../../../assets/products/naoDispo.png`)
															.default
													}
												/>
											) : (
												<img
													src={`https://ecommerceuploads.s3.amazonaws.com/uploads/${clientPrefix}/products/${item.imageName}`}
												/>
											)}
										</div>
										<div className="infoContainer">
											<span className="title">{item.name}</span>
											<span className="price">
												{getPriceFormact(item.price)}
											</span>
										</div>
										<div className="quantityContainer">
											<Button type="QuantityButton" product={item} />
										</div>
									</div>
								))
							)}
						</div>
					</div>
					<Link to={path}>
						<Button
							width="90%"
							on_click={() => mobileCartOptions("Open")}
							type="MobileCartButton"
							placeholder={buttonPlaceholder}
						/>
					</Link>
				</div>
			);
		},
		Desktop() {
			return (
				<div className="cartContainer">
					<div className="cartContent">
						<div className="cartHeader">
							<div className="cartHeaderCaption">
								{items.length == 0 ? (
									<>
										Seu carrinho
										<span>Seu carrinho está vazio</span>
									</>
								) : (
									<>
										Seu carrinho
										<span>
											{items.length > 1
												? items.length + " Produtos"
												: items.length + " Produto"}
											,{" "}
											{itemsQuantity > 1
												? itemsQuantity + " itens"
												: itemsQuantity + " item"}
										</span>
									</>
								)}
							</div>

							<Button
								type="CloseModal"
								css="neutral"
								placeholder="Fechar"
								width="100px"
							/>
						</div>

						<div className="cartIconContainer">
							<CartIcon />
							<span>Seu carrinho</span>
						</div>

						<div className="itensContainer">
							{items.length == 0 ? (
								<div id="cartMessage">
									<p>Seu carrinho está vazio :(</p>
									<p>Adicione um produto ao carrinho para ele aparecer aqui!</p>
								</div>
							) : (
								items.map((item, key) => (
									<div key={key} className="itemContainer">
										<div className="imageContainer">
											{item.imageName == null ? (
												<img
													src={
														require(`../../../../assets/products/naoDispo.png`)
															.default
													}
												/>
											) : (
												<img
													src={`https://ecommerceuploads.s3.amazonaws.com/uploads/${clientPrefix}/products/${item.imageName}`}
												/>
											)}
										</div>
										<div className="infoContainer">
											<span className="title">{item.name}</span>
											<span className="price">
												{getPriceFormact(item.price)}
											</span>
										</div>
										<div className="quantityContainer">
											<Button type="QuantityButton" product={item} />
										</div>
									</div>
								))
							)}
						</div>

						<div className="cartPriceContainer">
							<div className="subTotalValueContainer">
								<span className="caption">Subtotal</span>
								<span className="value">{getPriceFormact(cartValue)}</span>
							</div>
							<div className="deliveryValueContainer">
								<span className="caption">Entrega</span>
								<span className="value">
									{/* {getPriceFormact(cartValue)} */}
									R$ --
								</span>
							</div>
							<div className="totalValueContainer">
								<span className="caption">Total</span>
								<span className="value">{getPriceFormact(cartValue)}</span>
							</div>
						</div>
						{items.length == 0 ? null : (
							<Link to="/checkout/carrinho">
								<Button
									type="normal"
									css="normal"
									width="100%"
									placeholder="Finalizar compra"
									on_click={() => closeModal()}
								/>
							</Link>
						)}
					</div>
				</div>
			);
		},
	};

	return cartTypes[cartType]();
}
