import React from "react";
import Button from "../../../../components/button/button.js";
import {
	Container,
	IconContainer,
	Description,
	Title,
	Subtitle,
	ButtonContainer,
} from "./styles.js";

export function PaymentMethod({ card, exclude }) {
	function renderCardFlag(cardFlag) {
		cardFlag = cardFlag.toLowerCase();
		try {
			return (
				<img
					src={
						require(`../../../../assets/icons/cardFlag/${cardFlag}.svg`).default
					}
					alt="Loading skeleton"
				/>
			);
		} catch (e) {
			return (
				<img
					src={require(`../../../../assets/icons/checkout/card.svg`).default}
					alt="Payment icon"
				/>
			);
		}
	}
	return (
		<Container>
			<IconContainer>{renderCardFlag(card.ucar_brand)}</IconContainer>
			<Description>
				<Title>Final {card.ucar_last_digits}</Title>
				<Subtitle>
					Cartão de {card.ucar_type === "C" ? "crédito" : "débito"}
				</Subtitle>
			</Description>
			<ButtonContainer>
				<Button
					type="normal"
					css="delete"
					placeholder="Excluir"
					on_click={() => exclude(card.ucar_code)}
					width="80px"
				/>
			</ButtonContainer>
		</Container>
	);
}

export default PaymentMethod;
