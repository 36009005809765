// A forma como os produtos são fornecidos para o component "AddToCart" não me
// parecem ser a melhor possível, verificar o component "Cart" junto do
// "Quantity Button" para ver se aquela forma é a melhor

import React, { useRef, useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import "./css/button.scss";

// Functions
import { HandleHoverAddToCart } from "./button_functions";
import { getPriceFormact } from "../card/cards_function";

// Components
import LoadedProductsContext from "../reactSlick/context";
import { ModalContext } from "../modal/context";
import { useOrder } from "../../hooks/useOrder";
import { useModal } from "../../hooks/useModal";

export default function Button(props) {
	const { type = "normal" } = props;
	const { orderData, handleChangeCartItemQuantity, cartValue, cartItems } =
		useOrder();
	const { modalState, setModalState } = useContext(ModalContext);
	const {
		closeModal,
		openModal,
		changeCustomStyles,
		changeModalParams,
		changeCustomCloseButton,
	} = useModal();

	const TypeOfButton = {
		Cart(props) {
			const { placeholder } = props;

			const { modalParams, modalCustomStyles } = props;

			function handleModalButtonClick(modalParams, modalCustomStyles) {
				if (modalCustomStyles) {
					changeCustomStyles(modalCustomStyles);
				}
				changeModalParams(modalParams);
				changeCustomCloseButton();
				openModal();
			}

			const cartItems = orderData.request.items;
			// const modifiedProperties = {
			// 	...props,
			// 	on_click: () => {
			// 		on
			// 	},
			// };

			return (
				<Structure props={props}>
					<>
						<i className="fa fa-shopping-cart" aria-hidden="true"></i>
						<span>{placeholder}</span> <span>{cartItems.length}</span>
					</>
				</Structure>
			);
		},
		list(props) {
			const { placeholder } = props;
			return (
				<Structure props={props}>
					<>
						<i class="fa fa-list-ul" aria-hidden="true"></i>
						<span>{placeholder}</span>
					</>
				</Structure>
			);
		},
		normal(props) {
			const { isLoading, placeholder } = props;

			return (
				<Structure props={props}>
					<>
						{isLoading ? (
							<div className="button-loading-animation"></div>
						) : (
							<span>{placeholder}</span>
						)}
					</>
				</Structure>
			);
		},
		AddToCart(props) {
			const { productId, HoverState, item } = props;
			const addToCartContainer = useRef();
			const plus_button = useRef();
			const quantity_container = useRef();
			const cartItems = orderData.request.items;

			// Isso não me parece a melhor maneira de resolver isso, revisar
			// quanto puder
			const quantityInitialState = cartItems.map((item) => {
				if (item.code == productId) {
					return item.quantity;
				}

				return 0;
			});

			const [quantity, setQuantity] = useState(
				quantityInitialState.length > 0 ? quantityInitialState[0] : 0
			);
			// Mesma animação pode ser feita com CSS puro, verificar a viabilidade
			useEffect(() => {
				plus_button.current.addEventListener("animationend", (event) => {
					HandleHoverAddToCart(quantity_container.current, event.animationName);
				});
				quantity_container.current.addEventListener("mouseleave", (event) => {
					HandleHoverAddToCart(quantity_container.current, event.type);
				});
			}, []);

			return (
				<div
					ref={addToCartContainer}
					className={"addToCartContainer" + (HoverState ? " HoverState" : "")}
				>
					<i
						ref={plus_button}
						id="plus_button"
						class="fa fa-plus"
						aria-hidden="true"
					></i>
					<div ref={quantity_container} className={"quantity_container"} yy>
						<button>
							{quantity >= 2 ? (
								<i
									onClick={() => {
										if (quantity > 0) {
											handleChangeCartItemQuantity(
												{
													...item,
													quantity: quantity - 1,
												},
												"RemoveItemFromCart"
											);
											setQuantity(quantity - 1);
										}
									}}
									class="fa fa-minus"
									m
									aria-hidden="true"
								></i>
							) : (
								<i
									style={{
										color: quantity == 0 ? "#9B9B9B" : null,
									}}
									onClick={() => {
										if (quantity > 0) {
											handleChangeCartItemQuantity(
												{
													...item,
													quantity: quantity - 1,
												},
												"RemoveItemFromCart"
											);
											setQuantity(quantity - 1);
										}
									}}
									class="fa fa-trash"
									aria-hidden="true"
								></i>
							)}
						</button>
						<input
							disabled
							className="productQuantity"
							type="number"
							value={quantity}
						/>
						<button>
							<i
								onClick={() => {
									handleChangeCartItemQuantity(
										{
											...item,
											quantity: quantity + 1,
										},
										"AddItemToCart"
									);

									setQuantity(quantity + 1);
								}}
								class="fa fa-plus"
								aria-hidden="true"
							></i>
						</button>
					</div>
				</div>
			);
		},
		OpenModal(props) {
			const { openModal, changeModalParams, changeCustomStyles } = useModal();
			const { modalParams, icon, modalCustomStyles } = props;

			function handleModalButtonClick(modalParams, modalCustomStyles) {
				if (modalCustomStyles) {
					changeCustomStyles(modalCustomStyles);
				}
				changeModalParams(modalParams);
				openModal();
			}

			return (
				<i
					class={icon}
					aria-hidden="true"
					onClick={() => handleModalButtonClick(modalParams, modalCustomStyles)}
				></i>
			);
		},
		CloseModal(props) {
			const { modalInfo, setModalState } = useContext(ModalContext);
			const { className, children, style, placeholder } = props;

			return (
				<button
					className={className}
					onClick={() => closeModal()}
					style={style}
				>
					<div className="close-desktop-cart">
						{children ? children : placeholder}
					</div>
				</button>
			);
		},
		// Trocar Redux pela Context
		QuantityButton(props) {
			const { product } = props;
			const [quantity, setQuantity] = useState(product.quantity);

			return (
				<div className={"addToCartContainer HoverState"}>
					<div className={"quantity_container"}>
						<button>
							{quantity >= 2 ? (
								<i
									onClick={() => {
										if (quantity > 0) {
											handleChangeCartItemQuantity(
												{
													...product,
													quantity: quantity - 1,
												},
												"RemoveItemFromCart"
											);
											setQuantity(quantity - 1);
										}
									}}
									class="fa fa-minus"
									m
									aria-hidden="true"
								></i>
							) : (
								<i
									style={{
										color: quantity == 0 ? "#9B9B9B" : null,
									}}
									onClick={() => {
										if (quantity > 0) {
											handleChangeCartItemQuantity(
												{
													...product,
													quantity: quantity - 1,
												},
												"RemoveItemFromCart"
											);
											setQuantity(quantity - 1);
										}
									}}
									class="fa fa-trash"
									aria-hidden="true"
								></i>
							)}
						</button>
						<input
							disabled
							className="productQuantity"
							type="number"
							value={quantity}
						/>
						<button>
							<i
								onClick={() => {
									handleChangeCartItemQuantity(
										{
											...product,
											quantity: quantity + 1,
										},
										"AddItemToCart"
									);

									setQuantity(quantity + 1);
								}}
								class="fa fa-plus"
								aria-hidden="true"
							></i>
						</button>
					</div>
				</div>
			);
		},
		// Trocar Redux pela Context
		MobileCartButton(props) {
			const { placeholder } = props;
			const { modalState, setModalState } = useContext(ModalContext);
			const cart = useSelector((state) => state);

			const modifiedProperties = {
				...props,
				css: `mobileCartButton${
					cart.items.length == 0 ? " mobileCartButtonOut" : ""
				}`,
				// on_click: () =>
				// 	setModalState({
				// 		modalOpened: true,
				// 		openedModal: "cart",
				// 	}),
			};

			return (
				<Structure props={modifiedProperties}>
					<>
						<span className="cartQuantityContainer">{cart.items.length}</span>
						<span className="placeholderContainer">{placeholder}</span>{" "}
						<span className="cartValueContainer">{cartValue}</span>
					</>
				</Structure>
			);
		},
		// Trocar Redux pela Context
		AddWithPlaceholder(props) {
			const { item, placeholder } = props;
			const { modalState, setModalState } = useContext(ModalContext);
			const alert = useAlert();

			const [quantity, setQuantity] = useState(0);

			const handleAddItemToCart = () => {
				handleChangeCartItemQuantity(
					{
						...item,
						quantity: quantity + 1,
					},
					"AddItemToCart"
				);
				setQuantity(quantity + 1);
			};

			const modifiedProperties = {
				...props,
				on_click: () => handleAddItemToCart(),
			};

			return (
				<Structure props={modifiedProperties}>
					<>
						<span>{placeholder}</span>{" "}
						<span>{getPriceFormact(item.price)}</span>
					</>
				</Structure>
			);
		},
		CheckoutOption(props) {
			const { id, className, on_click, Icon, caption } = props;
			return (
				<div id={id} className={className} onClick={on_click}>
					<div className="content">
						{Icon != undefined ? (
							typeof Icon == "object" ? (
								<Icon />
							) : (
								<img src={Icon} />
							)
						) : null}
						<div className="caption">
							<span className="mainCaption">{caption.mainCaption}</span>
							<span className="complementCaption">
								{caption.complementCaption}
							</span>
						</div>
					</div>
				</div>
			);
		},
		AddAddress() {},
	};

	return TypeOfButton[type](props);
}

function Structure({ props, children }) {
	const { width, height, id, on_click, css, data_value, style } = props;
	const newStyles = { ...style, width: width, height: height };
	return (
		<button
			data-value={data_value}
			onClick={on_click}
			id={id}
			className={`${css} Button`}
			style={newStyles}
		>
			{children}
		</button>
	);
}
