import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyDt0RKG4v6YIcWn4zlaQ73fMJkPO6WjJAY");
Geocode.setRegion("pt-BR");
Geocode.setLocationType("ROOFTOP");

export async function getCoordinatesByAddress({
	address,
	number,
	district,
	city,
}) {
	const formattedAddress = `
	${address}, 
	${number}, 
	${district} - 
	${city}`;

	return await Geocode.fromAddress(formattedAddress).then(
		(response) => {
			return response.results[0].geometry.location;
		},
		(error) => {
			throw new Error(error);
		}
	);
}
