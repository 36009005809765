import axios from "axios";

import api from "./services/api";

const makeRequestCreator = () => {
	let token;

	return async (query) => {
		// Check if we made a request
		if (token) {
			// Cancel the previous request before making a new request
			token.cancel();
		}
		// Create a new CancelToken
		const CancelToken = axios.CancelToken;
		const source = CancelToken.source();
		try {
			const res = await api(query, { cancelToken: source.token });
			const result = res.data;
			return result;
		} catch (error) {
			if (axios.isCancel(error)) {
				// Handle if request was cancelled
				console.log("Request canceled", error.message);
			} else {
				// Handle usual errors
				console.log("Something went wrong: ", error.message);
			}
		}
	};
};

export const search = makeRequestCreator();

export function validateCPF(CPF) {
	CPF = unmaskCPF(CPF);
	var Sum;
	var Remain;
	Sum = 0;

	if (CPF === "00000000000") return false;

	for (var i = 1; i <= 9; i++)
		Sum = Sum + parseInt(CPF.substring(i - 1, i)) * (11 - i);
	Remain = (Sum * 10) % 11;

	if (Remain === 10 || Remain === 11) Remain = 0;
	if (Remain !== parseInt(CPF.substring(9, 10))) return false;

	Sum = 0;
	for (i = 1; i <= 10; i++)
		Sum = Sum + parseInt(CPF.substring(i - 1, i)) * (12 - i);
	Remain = (Sum * 10) % 11;

	if (Remain === 10 || Remain === 11) Remain = 0;
	if (Remain !== parseInt(CPF.substring(10, 11))) return false;
	return true;
}

export function unmaskCPF(CPF) {
	CPF = CPF.replaceAll(".", "");
	CPF = CPF.replaceAll("-", "");

	return CPF;
}

export const getCepFromViaCep = async (cep) => {
	return await axios
		.get(`https://viacep.com.br/ws/${cep}/json/`)
		.then((response) => {
			return response.data;
		});
};

export const cpfMask = (value) => {
	const maskedCPF = value
		.replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
		.replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
		.replace(/(\d{3})(\d)/, "$1.$2")
		.replace(/(\d{3})(\d{1,2})/, "$1-$2")
		.replace(/(-\d{2})\d+?$/, "$1"); // captura 2
	return maskedCPF;
};
