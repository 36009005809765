import axios from "axios";
import React, { createContext, useEffect, useState, useContext } from "react";
import { useAlert, positions, transitions } from "react-alert";
import api from "../services/api";

const OrderContext = createContext({});

const OrderProvider = ({ children }) => {
	let today = new Date();
	let dateToCartExpire = new Date();

	const localorderData = JSON.parse(localStorage.getItem("orderData"));
	const alert = useAlert();

	// adiciona 1 dia após a data do dia de hoje na data em que o carrinho irá expirar
	dateToCartExpire.setDate(dateToCartExpire.getDate() + 1);

	// Transformar as variaveis "today" e "dateToCartExpire" em timestamp
	today = Date.parse(today);
	dateToCartExpire = Date.parse(dateToCartExpire);

	// Verificando se existe informações do carrinho no localStorate
	// se existir, armazena os itens e a data para expirar o carrinho no
	// initial state da redux store

	const [orderData, setOrderData] = useState({
		request:
			localStorage.getItem("orderData") &&
			localorderData.request.dateToCartExpire > today
				? localorderData.request
				: { dateToCartExpire: dateToCartExpire, items: [] },
	});

	const [message, setMessage] = useState({
		message: {
			content: null,
			displayTime: null,
		},
	});

	const [marketInfo, setMarketInfo] = useState(false);

	const [lastAlter, setLastAlter] = useState(null);

	const itemsQuantity = orderData.request.items.reduce((accumulator, item) => {
		return accumulator + item.quantity;
	}, 0);
	const cartValue = orderData.request.items.reduce((accumulator, item) => {
		return (accumulator = accumulator + parseFloat(item.price * item.quantity));
	}, 0);

	function handleChangeCartItemQuantity(item, changeAction) {
		const ChangeQuantityActions = {
			// Tentar melhorar essa função usando array.prototype.some() para
			// validar se o item ja existe no carrinho
			AddItemToCart(itemToAdd) {
				for (let i = 0; i <= orderData.request.items.length; i++) {
					// Se o carrinho estiver vazio
					if (orderData.request.items.length == 0) {
						setOrderData({
							...orderData,
							request: {
								...orderData.request,
								items: [...orderData.request.items, itemToAdd],
							},
						});
						setLastAlter({
							item: itemToAdd,
							message: `${itemToAdd.name} adicionado ao seu carrinho`,
						});
						break;
					}

					// Se o produto a ser adicionado for um
					// produto que não esteja no carrinho
					if (i + 1 > orderData.request.items.length) {
						setOrderData({
							...orderData,
							request: {
								...orderData.request,
								items: [...orderData.request.items, itemToAdd],
							},
						});
						setLastAlter({
							item: itemToAdd,
							message: `${itemToAdd.name} adicionado ao seu carrinho`,
						});

						break;
					}

					// Se o produto já está no carrinho
					if (itemToAdd.code == orderData.request.items[i].code) {
						let newArrayItems = [...orderData.request.items];

						let newItem = { ...newArrayItems[i] };

						newItem = itemToAdd;

						newArrayItems[i] = newItem;

						setOrderData({
							...orderData,
							request: {
								...orderData.request,
								items: newArrayItems,
							},
						});

						setLastAlter({
							item: itemToAdd,
							message: `${itemToAdd.name} adicionado ao seu carrinho | 
							No carrinho: ${itemToAdd.quantity} unidades`,
							displayTime: 10000,
						});
						break;
					}
				}
			},
			RemoveItemFromCart(itemToRemove) {
				let itemIndex;
				let neworderData = orderData;

				orderData.request.items.forEach((item, index) => {
					if (item.code == itemToRemove.code) {
						itemIndex = index;
					}
				});

				// Verifica se a quantidade do item a ser decrementado é igual a 1, se sim
				// significa que ele será removido do carrinho, caso não,
				// ele decrementa a quantidade do item no carinho
				if (orderData.request.items[itemIndex].quantity == 1) {
					let first_part = orderData.request.items.slice(0, itemIndex);
					let second_part = orderData.request.items.slice(itemIndex + 1);

					if (
						orderData.request.items.length == 1 &&
						orderData.request.items[0].quantity == 1
					) {
						setOrderData({
							...orderData,
							request: {
								dateToExpire: dateToCartExpire,
								items: [],
							},
						});
						setLastAlter({
							item: itemToRemove,
							message: `${itemToRemove.name} foi removido do seu carrinho`,
							displayTime: 10000,
							alertType: alert.info,
						});
					} else {
						setOrderData({
							...orderData,
							request: {
								dateToExpire: dateToCartExpire,
								items: first_part.concat(second_part),
							},
						});
					}
				} else {
					orderData.request.items.forEach((item, index) => {
						if (index == itemIndex) {
							neworderData.request.items[index] = itemToRemove;
						}
					});

					setOrderData(neworderData);
					setLastAlter({
						item: itemToRemove,
						message: `${itemToRemove.name} adicionado ao seu carrinho | 
							No carrinho: ${itemToRemove.quantity} ${
							itemToRemove.quantity >= 2 ? "unidades " : "unidade"
						} restante`,
						displayTime: 10000,
						alertType: alert.info,
					});
				}
			},
		};

		return ChangeQuantityActions[changeAction](item);
	}

	function handleChangeDeliveryMode(forDelivery) {
		setOrderData((prevState) => ({
			...prevState,
			request: {
				...prevState.request,
				type_delivery: forDelivery ? "E" : "R",
			},
		}));
	}

	async function handleSetDeliveryAddress(addressCode) {
		setOrderData((prevState) => ({
			request: { ...prevState.request, address_code: addressCode },
		}));
	}

	async function setOrderPaymentMethod({ payment_method, card }) {
		setOrderData((prevState) => ({
			...prevState,
			request: {
				...prevState.request,
				payment_method: payment_method,
				card: card,
			},
		}));
	}

	async function handleGenerateOrder() {
		const sessionStorageUserData = JSON.parse(
			sessionStorage.getItem("loggedUser")
		);

		let request = {
			...orderData.request,
			date_delivery: "2020-05-20 08:00:00",
			token: sessionStorageUserData.userToken,
		};

		const items = request.items.map((item) => {
			const { code, quantity } = item;
			return {
				product: code,
				quantity: quantity,
				price_unit:
					item.promotionalPrice && item.promotionalPrice < item.price
						? item.promotionalPrice
						: item.price,

				option_code: 0,
				comments: "",
			};
		});

		request.items = items;

		if (request.card) {
			request.payment_method = request.card;
		}
		delete request.card;
		delete request.dateToCartExpire;

		return await api.post("front/checkout", request).then((response) => {
			if (response.status === 200) {
				const order = {
					order: response.data.order_code,
					method: orderData.request.payment_method,
					card: orderData.request.card,
					token: sessionStorageUserData.userToken,
				};
				payOrder(order).then((response) => {
					console.log(response);
					return response;
				});
			}
		});
	}

	async function payOrder(order) {
		const sessionStorageUserData = JSON.parse(
			sessionStorage.getItem("loggedUser")
		);

		console.log(order);
		return api.post("front/pay", order).then((response) => {
			console.log(response);
		});
	}

	useEffect(() => {
		api.get("/front/configs").then((response) => {
			response = {
				...response.data.config,
				socialMedias: [
					{ twitter: response.data.config.clie_twitter },
					{ youtube: response.data.config.clie_youtube },
					{ instagram: response.data.config.clie_instagram },
					{ facebook: response.data.config.clie_facebook },
				],
			};
			setMarketInfo(response);
		});
	}, []);

	useEffect(() => {
		localStorage.setItem("orderData", JSON.stringify(orderData));
	}, [orderData]);

	useEffect(() => {
		if (lastAlter != null)
			lastAlter.alertType
				? lastAlter.alertType(`${lastAlter.message}`, {
						position: positions.TOP_RIGHT,
						timeout: lastAlter.displayTime ? lastAlter.displayTime : 7000,
						transition: transitions.SCALE,
				  })
				: alert.success(`${lastAlter.message}`, {
						position: positions.TOP_RIGHT,
						timeout: lastAlter.displayTime ? lastAlter.displayTime : 7000,
						transition: transitions.SCALE,
				  });
	}, [lastAlter]);

	return (
		<OrderContext.Provider
			value={{
				orderData,
				handleChangeCartItemQuantity,
				handleChangeDeliveryMode,
				handleSetDeliveryAddress,
				setOrderPaymentMethod,
				handleGenerateOrder,
				marketInfo,
				payOrder,
				itemsQuantity,
				cartValue,
			}}
		>
			{children}
		</OrderContext.Provider>
	);
};

function useOrder() {
	const context = useContext(OrderContext);

	if (!context) {
		throw new Error("useOrder deve ser usado junto do OrderContext");
	}

	return context;
}

export { OrderProvider, useOrder };
