import { useState, useEffect } from "react";

import api from "../../../services/api";

import Page from "../../../components/page";
import Card from "../../../components/card/card";
import Slicker from "../../../components/reactSlick";

export function ByGroups(props) {
	const params = new URLSearchParams(props.location.search);
	const groupCode = params.get("groupCode");
	const [group, setGroups] = useState({
		title: "",
		products: [],
	});
	const [isLoading, setIsLoading] = useState();

	async function getProductsFromGroup(groupCode) {
		let productsFromGroup = [];
		return await api(`/front/product/list?group=${groupCode}`).then(
			(response) => {
				const products = response.data.products;
				if (products) {
					products.map((product) => {
						return (productsFromGroup = [...productsFromGroup, product]);
					});
				}
				return productsFromGroup;
			}
		);
	}

	useEffect(() => {
		setIsLoading(true);
		getProductsFromGroup(groupCode)
			.then((response) => {
				let content = [];
				if (response && response != undefined && response.length > 0) {
					content = response.map((product) => ({
						code: product.prod_code,
						imageName: product.prim_image,
						name: product.prod_description,
						complement: product.prod_complement,
						priceOld: product.prun_price_old,
						price: product.prun_price,
						group: product.grup_code,
						category: product.cat_code,
					}));
				}
				setGroups({
					title: response[0].grup_description,
					products: content,
				});
			})
			.then(() => setIsLoading(false));
	}, [groupCode]);

	return (
		<Page page_id="pageByGroups">
			<div className="content">
				<div className="categoriesListContainer">
					{group.products.length > 0 ? (
						<Slicker style={{ style: "categoriesSlicker" }} type="Category" />
					) : null}
				</div>{" "}
				<h1 className="title">{group.title}</h1>
				<div className="productsFromGroup">
					{isLoading ? (
						<>
							<Slicker type="Product" group={{}} context="home" />
						</>
					) : (
						<>
							{group.products.map((product, key) => (
								<Card type="Product" item={product} key={key} />
							))}
						</>
					)}
				</div>
			</div>{" "}
		</Page>
	);
}
