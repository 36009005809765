import React, { useContext } from "react";
//Components
import { ReactComponent as LongArrow } from "../../../assets/icons/system/long_arrow.svg";
import { ReactComponent as Logo } from "../../../assets/icons/client_logo.svg";

import { market_info } from "../../../components/support_objects/support_objects";
import { useOrder } from "../../../hooks/useOrder";

export default function Footer() {
	const { marketInfo } = useOrder();

	return (
		<footer>
			<div className="marketInfoContainer">
				<div className="marketInfoContent">
					<div style={{ width: "140px" }} className="logoContainer">
						<Logo />
					</div>
					<div className="marketData">
						<div className="market_name">{marketInfo.clie_name}</div>
						<div className="adress">{marketInfo.clie_address}</div>
						<div className="to_map">
							<a
								target="_blank"
								href={`https://www.google.com.br/maps/place/${
									marketInfo.clie_latitude + marketInfo.clie_longitude
								}`}
							>
								Como Chegar <LongArrow />
							</a>
						</div>
					</div>
					<div className="socialMedia">
						<div className="socialMediaContent">
							<div className="title">Nos siga nas redes sociais!</div>
							<div className="icons">
								{marketInfo &&
									marketInfo.socialMedias.map((icon, key) => (
										<a
											href={icon[Object.keys(icon)[0]]}
											target="_blank"
											key={key}
										>
											<i
												className={`fa fa-${Object.keys(icon)[0]}${
													Object.keys(icon)[0] == "youtube" ? "-play" : " "
												}`}
												aria-hidden="true"
											/>
										</a>
									))}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="complement">
				<p>
					{marketInfo.clie_companyname} / CNPJ: {marketInfo.clie_cnpj} /
					Endereço {marketInfo.clie_address} - 20081-902{" "}
				</p>
			</div>
			<div className="poweredBy">
				Powered By
				<a
					style={{ marginLeft: "5px" }}
					href="https://rpinfo.com.br/"
					target="_blank"
				>
					RP Info Sistemas
				</a>
				- v1.0.03
			</div>
		</footer>
	);
}
