import React, { setState, useState, useEffect, useContext } from "react";
import { Container, Form } from "./styles";
import Cards from "react-credit-cards";
import Button from "../../../../components/button/button";
import Input from "../../../../components/input";

import { ReactComponent as CloseModalIcon } from "../../../../assets/icons/header/mainMenu/closeModalIcon.svg";

import "react-credit-cards/es/styles-compiled.css";
import api from "../../../../services/api";
import { useAuth } from "../../../../hooks";
import { validateCPF } from "../../../../utils";

export function AddNewCard() {
	const [CPFvalidation, setCPFValidation] = useState("");
	const [formState, setFormState] = useState({
		CPFinput: {},
		passwordInput: {},
	});

	const { CPFinput, passwordInput, cpfValition } = formState;

	const { loggedUser } = useAuth();

	const [card, setCard] = useState({
		cvc: "",
		expMonth: "",
		expYear: "",
		focus: "",
		holderName: "",
		holderDocument: "",
		number: "",
	});

	function handleInputFocus(e) {
		setCard((prevState) => ({ ...prevState, focus: e.target.name }));
	}

	function handleInputChange(e, markedCPF) {
		const { name, value } = e.target;
		switch (name) {
			case "number":
				if (value.length <= 16) {
					setCard((prevState) => ({ ...prevState, [name]: e }));
				}
				break;
			case "cvc":
				if (value.length <= 3) {
					setCard((prevState) => ({ ...prevState, [name]: value }));
				}
				break;
			case "holderDocument":
				if (value.length <= 11) {
					setCard((prevState) => ({ ...prevState, [name]: value }));
				}
				break;
			case "expiry":
				const year = value.split("-")[0].slice(-2);
				const month = value.split("-")[1];
				setCard((prevState) => ({
					...prevState,
					expYear: year,
					expMonth: month,
				}));
				break;
			default:
				setCard((prevState) => ({ ...prevState, [name]: value }));
				break;
		}
	}

	async function addNewCard(element) {
		element.preventDefault();

		let cardInfoToAdd = { ...card, token: loggedUser.userToken };

		const userAdressess = await api
			.get(`/front/adresses/${loggedUser.userToken}`)
			.then((response) => response.data);

		const billingAddress = userAdressess.find(
			(address) => address.usad_billing
		);

		delete cardInfoToAdd.focus;
		api
			.post("front/card", cardInfoToAdd)
			.then((response) => console.log(response));
	}

	const validateCPFOnInputChange = (CPF) => {
		const validationOptions = {
			true() {
				setFormState((prevState) => ({
					...prevState,
					CPFinput: { errorMessage: "" },
				}));
				return setCPFValidation(true);
			},
			false() {
				if (card.holderDocument.length >= 14) {
					setFormState((prevState) => ({
						...prevState,
						CPFinput: { errorMessage: "CPF Inválido" },
					}));
				}
				return setCPFValidation(false);
			},
		};

		return validationOptions[validateCPF(CPF)]();
	};

	return (
		<Container>
			<Button type="CloseModal">
				<CloseModalIcon />
			</Button>
			<Form onSubmit={(element) => addNewCard(element)}>
				<Input
					type="Number"
					name="number"
					id="card"
					placeholder="Número do Cartão"
					value={card.number}
					onChange={(element) => handleInputChange(element)}
					onFocus={(element) => handleInputFocus(element)}
				/>
				<Input
					id="holderName"
					type="Text"
					name="holderName"
					placeholder="Nome do titular"
					value={card.holderName}
					onChange={(element) => handleInputChange(element)}
					onFocus={(element) => handleInputFocus(element)}
				/>
				<div className="cvcExpiryContainer">
					<Input
						type="Number"
						name="cvc"
						id="cvc"
						placeholder="Código de segurança"
						value={card.cvc}
						onChange={(element) => handleInputChange(element)}
						onFocus={(element) => handleInputFocus(element)}
					/>
					<Input
						type="Date"
						id="date"
						name="expiry"
						placeholder="Vencimento"
						value={"20" + card.expYear + "-" + card.expMonth}
						onChange={(element) => handleInputChange(element)}
						onFocus={(element) => handleInputFocus(element)}
					/>
				</div>

				<Input
					type="Text"
					id="holderDocument"
					placeholder="CPF do titular"
					value={card.holderDocument}
					onChange={(element) => {
						setCard((prevState) => ({
							...prevState,
							holderDocument: element,
						}));

						validateCPFOnInputChange(element);
					}}
					validation={CPFvalidation}
					errorMessage={CPFinput?.errorMessage}
					disabled={CPFinput.disabled}
					css={CPFinput.style}
				/>
				<Button
					width="90%"
					height="48px"
					placeholder="Adicionar"
					type="normal"
					css="normal"
					width="100%"
					id="addNewCard"
				/>
			</Form>
			<Cards
				cvc={card.cvc}
				expiry={card.expYear + card.expMonth}
				focused={card.focus}
				name={card.holderName}
				number={card.number}
			/>
		</Container>
	);
}
