import styled from "styled-components";

export const Container = styled.div`
	width: 800px;

	display: flex;
	flex-direction: row;

	background-color: var(--white);

	padding: 50px;

	border-radius: 8px;

	position: relative;

	& > button {
		position: absolute;
		z-index: 2;
		top: 20px;
		right: 20px;
	}
`;

export const Form = styled.form`
	margin-right: 15px;
	width: 100%;

	.inputContainer {
		width: 400px;
	}

	.cvcExpiryContainer {
		margin-top: 10px;

		display: flex;
		width: 100%;
		flex-direction: row;

		.inputContainer {
			margin: unset;

			&:first-of-type {
				margin-right: 10px;
			}

			width: 50%;
			flex: 1;
		}
	}

	button {
		margin-top: 10px;
	}

	div:first-of-type {
		margin-bottom: 10px;
	}

	div:last-of-type {
		margin-top: 10px;
	}
`;
