import React, { useState, useEffect } from "react";

// Components
import Page from "../../../components/page";
import Slicker from "../../../components/reactSlick";
import Card from "../../../components/card/card";

import api from "../../../services/api";

export const Search = ({ location }) => {
	const [loadedProducts, setLoadedProducts] = useState([]);
	const [searchList, setSearchList] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const params = new URLSearchParams(location.search);
	const value = { loadedProducts, setLoadedProducts };
	const search = params.get("search").toString();

	const searchProducts = async (search) => {
		const apiResponse = await api.get(`/front/product/list?search=${search}`);
		return apiResponse.data.products;
	};

	useEffect(() => {
		searchProducts(search)
			.then((response) => {
				let content = [];
				if (response && response != undefined && response.length > 0) {
					content = response.map((product) => ({
						code: product.prod_code,
						imageName: product.prim_image,
						name: product.prod_description,
						complement: product.prod_complement,
						priceOld: product.prun_price_old,
						price: product.prun_price,
						group: product.grup_code,
						category: product.cat_code,
					}));
				}
				setSearchList(content);
			})
			.then(() => setIsLoading(false));
	}, []);

	return (
		<Page>
			<div id="search-page">
				<h1 className="title">Resultados para {search}</h1>

				<div className="content">
					<div
						className="productsFromGroup"
						style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}
					>
						{isLoading ? (
							<>
								<Slicker type="Product" group={{}} context="home" />
							</>
						) : (
							<>
								{searchList.map((product, key) => (
									<Card
										type="Product"
										item={product}
										key={key}
										style={{ marginRight: "20px" }}
									/>
								))}
							</>
						)}
					</div>
				</div>
			</div>
		</Page>
	);
};
