const FailedAnimation = {
	v: "5.6.3",
	fr: 29.9700012207031,
	ip: 0,
	op: 60.0000024438501,
	w: 1920,
	h: 1080,
	nm: "Failed",
	ddd: 0,
	assets: [],
	fonts: {
		list: [
			{
				fName: "SFUIDisplay-Bold",
				fFamily: "SF UI Display",
				fStyle: "Bold",
				ascent: 73.828125,
			},
		],
	},
	layers: [
		{
			ddd: 0,
			ind: 1,
			ty: 4,
			nm: "cros1",
			sr: 1,
			ks: {
				o: { a: 0, k: 100, ix: 11 },
				r: { a: 0, k: 0, ix: 10 },
				p: { a: 0, k: [960.21, 535.925, 0], ix: 2 },
				a: { a: 0, k: [319.771, 234.922, 0], ix: 1 },
				s: { a: 0, k: [119.548, 119.548, 100], ix: 6 },
			},
			ao: 0,
			shapes: [
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, 0],
										[0, 0],
										[-144.75, 96.16],
									],
									o: [
										[0, 0],
										[0, 0],
										[0, 0],
									],
									v: [
										[114.401, -118.25],
										[-53.706, 49.857],
										[30.348, 22.09],
									],
									c: false,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "st",
							c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
							o: { a: 0, k: 100, ix: 4 },
							w: { a: 0, k: 28.346, ix: 5 },
							lc: 2,
							lj: 2,
							bm: 0,
							nm: "Stroke 1",
							mn: "ADBE Vector Graphic - Stroke",
							hd: false,
						},
						{
							ty: "tr",
							p: { a: 0, k: [277.79, 280.751], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 1",
					np: 2,
					cix: 2,
					bm: 0,
					ix: 1,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "tm",
					s: {
						a: 1,
						k: [
							{
								i: { x: [0.667], y: [1] },
								o: { x: [0.333], y: [0] },
								t: 15,
								s: [100],
							},
							{ t: 35.0000014255792, s: [0] },
						],
						ix: 1,
					},
					e: {
						a: 1,
						k: [
							{
								i: { x: [0.667], y: [1] },
								o: { x: [0.333], y: [0] },
								t: 15,
								s: [100],
							},
							{ t: 35.0000014255792, s: [54] },
						],
						ix: 2,
					},
					o: { a: 0, k: 0, ix: 3 },
					m: 1,
					ix: 2,
					nm: "Trim Paths 1",
					mn: "ADBE Vector Filter - Trim",
					hd: false,
				},
			],
			ip: 0,
			op: 60.0000024438501,
			st: 0,
			bm: 0,
		},
		{
			ddd: 0,
			ind: 2,
			ty: 4,
			nm: "cros2",
			sr: 1,
			ks: {
				o: { a: 0, k: 100, ix: 11 },
				r: { a: 0, k: 0, ix: 10 },
				p: { a: 0, k: [957, 534.463, 0], ix: 2 },
				a: { a: 0, k: [313.219, 323.786, 0], ix: 1 },
				s: { a: 0, k: [119.548, 119.548, 100], ix: 6 },
			},
			ao: 0,
			shapes: [
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, 0],
										[0, 0],
										[-144.75, -96.16],
									],
									o: [
										[0, 0],
										[0, 0],
										[0, 0],
									],
									v: [
										[114.401, 118.249],
										[-53.706, -49.857],
										[30.348, -22.09],
									],
									c: false,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "st",
							c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
							o: { a: 0, k: 100, ix: 4 },
							w: { a: 0, k: 28.346, ix: 5 },
							lc: 2,
							lj: 2,
							bm: 0,
							nm: "Stroke 1",
							mn: "ADBE Vector Graphic - Stroke",
							hd: false,
						},
						{
							ty: "tr",
							p: { a: 0, k: [277.79, 280.941], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 1",
					np: 2,
					cix: 2,
					bm: 0,
					ix: 1,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "tm",
					s: {
						a: 1,
						k: [
							{
								i: { x: [0.667], y: [1] },
								o: { x: [0.333], y: [0] },
								t: 15,
								s: [100],
							},
							{ t: 35.0000014255792, s: [0] },
						],
						ix: 1,
					},
					e: {
						a: 1,
						k: [
							{
								i: { x: [0.667], y: [1] },
								o: { x: [0.333], y: [0] },
								t: 15,
								s: [100],
							},
							{ t: 35.0000014255792, s: [54] },
						],
						ix: 2,
					},
					o: { a: 0, k: 0, ix: 3 },
					m: 1,
					ix: 2,
					nm: "Trim Paths 1",
					mn: "ADBE Vector Filter - Trim",
					hd: false,
				},
			],
			ip: 0,
			op: 60.0000024438501,
			st: 0,
			bm: 0,
		},
		{
			ddd: 0,
			ind: 3,
			ty: 4,
			nm: "elipse bold",
			sr: 1,
			ks: {
				o: {
					a: 1,
					k: [
						{
							i: { x: [0.667], y: [1] },
							o: { x: [0.333], y: [0] },
							t: 0,
							s: [0],
						},
						{
							i: { x: [0.667], y: [1] },
							o: { x: [0.333], y: [0] },
							t: 14,
							s: [81],
						},
						{ t: 27.0000010997325, s: [100] },
					],
					ix: 11,
				},
				r: { a: 0, k: 0, ix: 10 },
				p: { a: 0, k: [960, 535.5, 0], ix: 2 },
				a: { a: 0, k: [-62, -4.5, 0], ix: 1 },
				s: {
					a: 1,
					k: [
						{
							i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
							o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
							t: 0,
							s: [91.25, 91.25, 100],
						},
						{
							i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
							o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
							t: 14,
							s: [582.5, 582.5, 100],
						},
						{
							i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
							o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
							t: 21,
							s: [536.25, 536.25, 100],
						},
						{
							i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
							o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
							t: 27,
							s: [558.75, 558.75, 100],
						},
						{ t: 269.000010956595, s: [158.75, 158.75, 100] },
					],
					ix: 6,
				},
			},
			ao: 0,
			shapes: [
				{
					ty: "gr",
					it: [
						{
							d: 1,
							ty: "el",
							s: { a: 0, k: [80, 80], ix: 2 },
							p: { a: 0, k: [0, 0], ix: 3 },
							nm: "Ellipse Path 1",
							mn: "ADBE Vector Shape - Ellipse",
							hd: false,
						},
						{
							ty: "fl",
							c: { a: 0, k: [1, 0.305882323022, 0.305882323022, 1], ix: 4 },
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: { a: 0, k: [-62, -4.5], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Ellipse 1",
					np: 3,
					cix: 2,
					bm: 0,
					ix: 1,
					mn: "ADBE Vector Group",
					hd: false,
				},
			],
			ip: 0,
			op: 60.0000024438501,
			st: 0,
			bm: 0,
		},
		{
			ddd: 0,
			ind: 4,
			ty: 4,
			nm: "elipse light",
			sr: 1,
			ks: {
				o: {
					a: 1,
					k: [
						{
							i: { x: [0.667], y: [1] },
							o: { x: [0.333], y: [0] },
							t: 0,
							s: [0],
						},
						{
							i: { x: [0.667], y: [1] },
							o: { x: [0.333], y: [0] },
							t: 14,
							s: [80],
						},
						{ t: 27.0000010997325, s: [0] },
					],
					ix: 11,
				},
				r: { a: 0, k: 0, ix: 10 },
				p: { a: 0, k: [960, 535.5, 0], ix: 2 },
				a: { a: 0, k: [-60.75, -4.5, 0], ix: 1 },
				s: {
					a: 1,
					k: [
						{
							i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
							o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
							t: 0,
							s: [150, 150, 100],
						},
						{
							i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
							o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
							t: 14,
							s: [580, 580, 100],
						},
						{
							i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
							o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
							t: 21,
							s: [528, 528, 100],
						},
						{ t: 27.0000010997325, s: [585, 585, 100] },
					],
					ix: 6,
				},
			},
			ao: 0,
			shapes: [
				{
					ty: "gr",
					it: [
						{
							d: 1,
							ty: "el",
							s: { a: 0, k: [122.5, 122.5], ix: 2 },
							p: { a: 0, k: [0, 0], ix: 3 },
							nm: "Ellipse Path 1",
							mn: "ADBE Vector Shape - Ellipse",
							hd: false,
						},
						{
							ty: "fl",
							c: { a: 0, k: [1, 0.643137195064, 0.643137195064, 1], ix: 4 },
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: { a: 0, k: [-60.75, -4.25], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Ellipse 1",
					np: 3,
					cix: 2,
					bm: 0,
					ix: 1,
					mn: "ADBE Vector Group",
					hd: false,
				},
			],
			ip: 0,
			op: 60.0000024438501,
			st: 0,
			bm: 0,
		},
		{
			ddd: 0,
			ind: 5,
			ty: 4,
			nm: "background",
			sr: 1,
			ks: {
				o: {
					a: 1,
					k: [
						{
							i: { x: [0.667], y: [1] },
							o: { x: [0.333], y: [0] },
							t: 13,
							s: [0],
						},
						{
							i: { x: [0.667], y: [1] },
							o: { x: [0.333], y: [0] },
							t: 26,
							s: [50],
						},
						{ t: 36.0000014663101, s: [100] },
					],
					ix: 11,
				},
				r: { a: 0, k: 0, ix: 10 },
				p: { a: 0, k: [960, 540, 0], ix: 2 },
				a: { a: 0, k: [0, 0, 0], ix: 1 },
				s: {
					a: 1,
					k: [
						{
							i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
							o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
							t: 13,
							s: [415.646, 415.646, 100],
						},
						{
							i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
							o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
							t: 26,
							s: [577.128, 577.128, 100],
						},
						{ t: 36.0000014663101, s: [370.371, 370.371, 100] },
					],
					ix: 6,
				},
			},
			ao: 0,
			shapes: [
				{
					ty: "gr",
					it: [
						{
							d: 1,
							ty: "el",
							s: { a: 0, k: [6, 6], ix: 2 },
							p: { a: 0, k: [0, 0], ix: 3 },
							nm: "Ellipse Path 1",
							mn: "ADBE Vector Shape - Ellipse",
							hd: false,
						},
						{
							ty: "st",
							c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
							o: { a: 0, k: 100, ix: 4 },
							w: { a: 0, k: 5, ix: 5 },
							lc: 1,
							lj: 1,
							ml: 4,
							bm: 0,
							nm: "Stroke 1",
							mn: "ADBE Vector Graphic - Stroke",
							hd: false,
						},
						{
							ty: "fl",
							c: { a: 0, k: [1, 0.64313695571, 0.64313695571, 1], ix: 4 },
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: { a: 0, k: [-73, -4], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Ellipse 8",
					np: 3,
					cix: 2,
					bm: 0,
					ix: 1,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							d: 1,
							ty: "el",
							s: { a: 0, k: [9, 9], ix: 2 },
							p: { a: 0, k: [0, 0], ix: 3 },
							nm: "Ellipse Path 1",
							mn: "ADBE Vector Shape - Ellipse",
							hd: false,
						},
						{
							ty: "st",
							c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
							o: { a: 0, k: 100, ix: 4 },
							w: { a: 0, k: 5, ix: 5 },
							lc: 1,
							lj: 1,
							ml: 4,
							bm: 0,
							nm: "Stroke 1",
							mn: "ADBE Vector Graphic - Stroke",
							hd: false,
						},
						{
							ty: "fl",
							c: { a: 0, k: [1, 0.64313695571, 0.64313695571, 1], ix: 4 },
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: { a: 0, k: [42, -63], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Ellipse 7",
					np: 3,
					cix: 2,
					bm: 0,
					ix: 2,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							d: 1,
							ty: "el",
							s: { a: 0, k: [7.5, 7.5], ix: 2 },
							p: { a: 0, k: [0, 0], ix: 3 },
							nm: "Ellipse Path 1",
							mn: "ADBE Vector Shape - Ellipse",
							hd: false,
						},
						{
							ty: "st",
							c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
							o: { a: 0, k: 100, ix: 4 },
							w: { a: 0, k: 5, ix: 5 },
							lc: 1,
							lj: 1,
							ml: 4,
							bm: 0,
							nm: "Stroke 1",
							mn: "ADBE Vector Graphic - Stroke",
							hd: false,
						},
						{
							ty: "fl",
							c: { a: 0, k: [1, 0.64313695571, 0.64313695571, 1], ix: 4 },
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: { a: 0, k: [-65.25, -37.75], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Ellipse 6",
					np: 3,
					cix: 2,
					bm: 0,
					ix: 3,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							d: 1,
							ty: "el",
							s: { a: 0, k: [9.5, 9.5], ix: 2 },
							p: { a: 0, k: [0, 0], ix: 3 },
							nm: "Ellipse Path 1",
							mn: "ADBE Vector Shape - Ellipse",
							hd: false,
						},
						{
							ty: "st",
							c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
							o: { a: 0, k: 100, ix: 4 },
							w: { a: 0, k: 5, ix: 5 },
							lc: 1,
							lj: 1,
							ml: 4,
							bm: 0,
							nm: "Stroke 1",
							mn: "ADBE Vector Graphic - Stroke",
							hd: false,
						},
						{
							ty: "fl",
							c: { a: 0, k: [1, 0.64313695571, 0.64313695571, 1], ix: 4 },
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: { a: 0, k: [-6.75, 68.75], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Ellipse 5",
					np: 3,
					cix: 2,
					bm: 0,
					ix: 4,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							d: 1,
							ty: "el",
							s: { a: 0, k: [12.5, 12.5], ix: 2 },
							p: { a: 0, k: [0, 0], ix: 3 },
							nm: "Ellipse Path 1",
							mn: "ADBE Vector Shape - Ellipse",
							hd: false,
						},
						{
							ty: "st",
							c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
							o: { a: 0, k: 100, ix: 4 },
							w: { a: 0, k: 5, ix: 5 },
							lc: 1,
							lj: 1,
							ml: 4,
							bm: 0,
							nm: "Stroke 1",
							mn: "ADBE Vector Graphic - Stroke",
							hd: false,
						},
						{
							ty: "fl",
							c: { a: 0, k: [1, 0.64313695571, 0.64313695571, 1], ix: 4 },
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: { a: 0, k: [59.75, 43.25], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Ellipse 4",
					np: 3,
					cix: 2,
					bm: 0,
					ix: 5,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							d: 1,
							ty: "el",
							s: { a: 0, k: [12, 12], ix: 2 },
							p: { a: 0, k: [0, 0], ix: 3 },
							nm: "Ellipse Path 1",
							mn: "ADBE Vector Shape - Ellipse",
							hd: false,
						},
						{
							ty: "st",
							c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
							o: { a: 0, k: 100, ix: 4 },
							w: { a: 0, k: 5, ix: 5 },
							lc: 1,
							lj: 1,
							ml: 4,
							bm: 0,
							nm: "Stroke 1",
							mn: "ADBE Vector Graphic - Stroke",
							hd: false,
						},
						{
							ty: "fl",
							c: { a: 0, k: [1, 0.64313695571, 0.64313695571, 1], ix: 4 },
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: { a: 0, k: [-49.5, -57.5], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Ellipse 3",
					np: 3,
					cix: 2,
					bm: 0,
					ix: 6,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							d: 1,
							ty: "el",
							s: { a: 0, k: [17, 17], ix: 2 },
							p: { a: 0, k: [0, 0], ix: 3 },
							nm: "Ellipse Path 1",
							mn: "ADBE Vector Shape - Ellipse",
							hd: false,
						},
						{
							ty: "st",
							c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
							o: { a: 0, k: 100, ix: 4 },
							w: { a: 0, k: 5, ix: 5 },
							lc: 1,
							lj: 1,
							ml: 4,
							bm: 0,
							nm: "Stroke 1",
							mn: "ADBE Vector Graphic - Stroke",
							hd: false,
						},
						{
							ty: "fl",
							c: { a: 0, k: [1, 0.64313695571, 0.64313695571, 1], ix: 4 },
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: { a: 0, k: [-51, 48.5], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Ellipse 2",
					np: 3,
					cix: 2,
					bm: 0,
					ix: 7,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							d: 1,
							ty: "el",
							s: { a: 0, k: [15, 15], ix: 2 },
							p: { a: 0, k: [0, 0], ix: 3 },
							nm: "Ellipse Path 1",
							mn: "ADBE Vector Shape - Ellipse",
							hd: false,
						},
						{
							ty: "st",
							c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
							o: { a: 0, k: 100, ix: 4 },
							w: { a: 0, k: 5, ix: 5 },
							lc: 1,
							lj: 1,
							ml: 4,
							bm: 0,
							nm: "Stroke 1",
							mn: "ADBE Vector Graphic - Stroke",
							hd: false,
						},
						{
							ty: "fl",
							c: { a: 0, k: [1, 0.64313695571, 0.64313695571, 1], ix: 4 },
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: { a: 0, k: [66.5, -49], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Ellipse 1",
					np: 3,
					cix: 2,
					bm: 0,
					ix: 8,
					mn: "ADBE Vector Group",
					hd: false,
				},
			],
			ip: 0,
			op: 60.0000024438501,
			st: 0,
			bm: 0,
		},
		{
			ddd: 0,
			ind: 6,
			ty: 5,
			nm: "Payment Failed",
			sr: 1,
			ks: {
				o: {
					a: 1,
					k: [
						{
							i: { x: [0.833], y: [0.833] },
							o: { x: [0.167], y: [0.167] },
							t: 29,
							s: [0],
						},
						{ t: 40.0000016292334, s: [100] },
					],
					ix: 11,
				},
				r: { a: 0, k: 0, ix: 10 },
				p: { a: 0, k: [733.125, 893, 0], ix: 2 },
				a: { a: 0, k: [0, 0, 0], ix: 1 },
				s: { a: 0, k: [100, 100, 100], ix: 6 },
			},
			ao: 0,
			t: {
				d: {
					k: [
						{
							s: {
								s: 64,
								f: "SFUIDisplay-Bold",
								t: "",
								j: 0,
								tr: 0,
								lh: 76.8,
								ls: 0,
								fc: [1, 0.306, 0.306],
							},
							t: 0,
						},
					],
				},
				p: {},
				m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
				a: [],
			},
			ip: 0,
			op: 60.0000024438501,
			st: 0,
			bm: 0,
		},
	],
	markers: [],
	chars: [
		{
			ch: "P",
			size: 64,
			style: "Bold",
			w: 62.5,
			data: {
				shapes: [
					{
						ty: "gr",
						it: [
							{
								ind: 0,
								ty: "sh",
								ix: 1,
								ks: {
									a: 0,
									k: {
										i: [
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 14.453],
											[14.453, 0],
										],
										o: [
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[14.844, 0],
											[0, -14.502],
											[0, 0],
										],
										v: [
											[5.859, -70.459],
											[5.859, 0],
											[20.605, 0],
											[20.605, -22.217],
											[34.521, -22.217],
											[59.668, -46.338],
											[35.254, -70.459],
										],
										c: true,
									},
									ix: 2,
								},
								nm: "P",
								mn: "ADBE Vector Shape - Group",
								hd: false,
							},
							{
								ind: 1,
								ty: "sh",
								ix: 2,
								ks: {
									a: 0,
									k: {
										i: [
											[0, 0],
											[0, 0],
											[0, -8.008],
											[8.447, 0],
											[0, 0],
										],
										o: [
											[0, 0],
											[8.447, 0],
											[0, 8.057],
											[0, 0],
											[0, 0],
										],
										v: [
											[20.605, -58.789],
											[31.348, -58.789],
											[44.678, -46.289],
											[31.299, -33.74],
											[20.605, -33.74],
										],
										c: true,
									},
									ix: 2,
								},
								nm: "P",
								mn: "ADBE Vector Shape - Group",
								hd: false,
							},
						],
						nm: "P",
						np: 5,
						cix: 2,
						bm: 0,
						ix: 1,
						mn: "ADBE Vector Group",
						hd: false,
					},
				],
			},
			fFamily: "SF UI Display",
		},
		{
			ch: "a",
			size: 64,
			style: "Bold",
			w: 56.01,
			data: {
				shapes: [
					{
						ty: "gr",
						it: [
							{
								ind: 0,
								ty: "sh",
								ix: 1,
								ks: {
									a: 0,
									k: {
										i: [
											[6.592, 0],
											[0, 3.955],
											[-5.957, 0.391],
											[0, 0],
											[0, 0],
										],
										o: [
											[-5.176, 0],
											[0, -3.955],
											[0, 0],
											[0, 0],
											[0, 5.859],
										],
										v: [
											[25.391, -9.766],
											[16.846, -16.211],
											[25.977, -22.9],
											[37.256, -23.682],
											[37.256, -19.727],
										],
										c: true,
									},
									ix: 2,
								},
								nm: "a",
								mn: "ADBE Vector Shape - Group",
								hd: false,
							},
							{
								ind: 1,
								ty: "sh",
								ix: 2,
								ks: {
									a: 0,
									k: {
										i: [
											[-10.352, 0],
											[-2.93, 5.615],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[13.818, 0],
											[0.391, -10.254],
											[0, 0],
											[-5.176, 0],
											[0, -5.176],
											[0, 0],
											[0, 0],
											[0, -9.766],
										],
										o: [
											[6.885, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, -10.742],
											[-13.428, 0],
											[0, 0],
											[0.684, -3.906],
											[5.859, 0],
											[0, 0],
											[0, 0],
											[-13.721, 0.83],
											[0, 9.619],
										],
										v: [
											[20.752, 0.635],
											[37.207, -8.301],
											[37.5, -8.301],
											[37.5, 0],
											[51.27, 0],
											[51.27, -36.865],
											[28.418, -54.688],
											[5.322, -37.354],
											[18.359, -37.354],
											[27.832, -43.994],
											[37.256, -35.986],
											[37.256, -32.422],
											[23.828, -31.592],
											[2.637, -15.479],
										],
										c: true,
									},
									ix: 2,
								},
								nm: "a",
								mn: "ADBE Vector Shape - Group",
								hd: false,
							},
						],
						nm: "a",
						np: 5,
						cix: 2,
						bm: 0,
						ix: 1,
						mn: "ADBE Vector Group",
						hd: false,
					},
				],
			},
			fFamily: "SF UI Display",
		},
		{
			ch: "y",
			size: 64,
			style: "Bold",
			w: 56.2,
			data: {
				shapes: [
					{
						ty: "gr",
						it: [
							{
								ind: 0,
								ty: "sh",
								ix: 1,
								ks: {
									a: 0,
									k: {
										i: [
											[-0.537, 0],
											[-4.59, 13.721],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[5.322, 0],
											[0.488, 0.098],
											[0, 0],
										],
										o: [
											[13.428, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[-1.123, 4.297],
											[-0.586, 0],
											[0, 0],
											[0.537, 0.098],
										],
										v: [
											[11.67, 19.629],
											[36.279, 1.074],
											[54.883, -53.76],
											[39.844, -53.76],
											[28.174, -11.572],
											[27.881, -11.572],
											[16.26, -53.76],
											[0.635, -53.76],
											[19.482, 0.342],
											[18.994, 2.393],
											[9.473, 8.545],
											[6.25, 8.398],
											[6.25, 19.434],
										],
										c: true,
									},
									ix: 2,
								},
								nm: "y",
								mn: "ADBE Vector Shape - Group",
								hd: false,
							},
						],
						nm: "y",
						np: 3,
						cix: 2,
						bm: 0,
						ix: 1,
						mn: "ADBE Vector Group",
						hd: false,
					},
				],
			},
			fFamily: "SF UI Display",
		},
		{
			ch: "m",
			size: 64,
			style: "Bold",
			w: 88.43,
			data: {
				shapes: [
					{
						ty: "gr",
						it: [
							{
								ind: 0,
								ty: "sh",
								ix: 1,
								ks: {
									a: 0,
									k: {
										i: [
											[0, 0],
											[0, 0],
											[0, 0],
											[-5.615, 0],
											[0, -5.762],
											[0, 0],
											[0, 0],
											[0, 0],
											[-5.615, 0],
											[0, -6.299],
											[0, 0],
											[0, 0],
											[0, 0],
											[10.547, 0],
											[2.441, -6.738],
											[0, 0],
											[7.715, 0],
											[2.295, -6.494],
											[0, 0],
											[0, 0],
											[0, 0],
										],
										o: [
											[0, 0],
											[0, 0],
											[0, -6.104],
											[5.518, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, -6.104],
											[5.811, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, -10.84],
											[-7.813, 0],
											[0, 0],
											[-1.807, -7.031],
											[-7.178, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
										],
										v: [
											[4.834, 0],
											[19.092, 0],
											[19.092, -32.324],
											[28.613, -42.725],
											[37.451, -33.496],
											[37.451, 0],
											[51.221, 0],
											[51.221, -32.52],
											[60.596, -42.725],
											[69.58, -32.959],
											[69.58, 0],
											[83.838, 0],
											[83.838, -36.523],
											[66.211, -54.541],
											[49.512, -43.555],
											[49.219, -43.555],
											[34.229, -54.541],
											[18.896, -43.994],
											[18.604, -43.994],
											[18.604, -53.76],
											[4.834, -53.76],
										],
										c: true,
									},
									ix: 2,
								},
								nm: "m",
								mn: "ADBE Vector Shape - Group",
								hd: false,
							},
						],
						nm: "m",
						np: 3,
						cix: 2,
						bm: 0,
						ix: 1,
						mn: "ADBE Vector Group",
						hd: false,
					},
				],
			},
			fFamily: "SF UI Display",
		},
		{
			ch: "e",
			size: 64,
			style: "Bold",
			w: 56.84,
			data: {
				shapes: [
					{
						ty: "gr",
						it: [
							{
								ind: 0,
								ty: "sh",
								ix: 1,
								ks: {
									a: 0,
									k: {
										i: [
											[-6.494, 0],
											[-0.244, -7.031],
											[0, 0],
										],
										o: [
											[6.592, 0],
											[0, 0],
											[0.488, -6.885],
										],
										v: [
											[28.809, -44.043],
											[40.234, -32.422],
											[17.041, -32.422],
										],
										c: true,
									},
									ix: 2,
								},
								nm: "e",
								mn: "ADBE Vector Shape - Group",
								hd: false,
							},
							{
								ind: 1,
								ty: "sh",
								ix: 2,
								ks: {
									a: 0,
									k: {
										i: [
											[0, 0],
											[5.371, 0],
											[0, 7.764],
											[0, 0],
											[0, 0],
											[0, 0],
											[15.479, 0],
											[0, -17.09],
											[-16.357, 0],
											[-1.563, 10.4],
										],
										o: [
											[-1.367, 4.15],
											[-7.52, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, -16.357],
											[-15.771, 0],
											[0, 17.09],
											[13.086, 0],
											[0, 0],
										],
										v: [
											[40.43, -16.406],
											[29.443, -9.717],
											[16.895, -22.705],
											[16.895, -23.584],
											[54.004, -23.584],
											[54.004, -27.979],
											[28.662, -54.688],
											[2.832, -26.66],
											[29.199, 0.928],
											[53.564, -16.406],
										],
										c: true,
									},
									ix: 2,
								},
								nm: "e",
								mn: "ADBE Vector Shape - Group",
								hd: false,
							},
						],
						nm: "e",
						np: 5,
						cix: 2,
						bm: 0,
						ix: 1,
						mn: "ADBE Vector Group",
						hd: false,
					},
				],
			},
			fFamily: "SF UI Display",
		},
		{
			ch: "n",
			size: 64,
			style: "Bold",
			w: 58.94,
			data: {
				shapes: [
					{
						ty: "gr",
						it: [
							{
								ind: 0,
								ty: "sh",
								ix: 1,
								ks: {
									a: 0,
									k: {
										i: [
											[0, 0],
											[0, 0],
											[0, 0],
											[-6.836, 0],
											[0, -7.129],
											[0, 0],
											[0, 0],
											[0, 0],
											[11.914, 0],
											[3.076, -6.787],
											[0, 0],
											[0, 0],
											[0, 0],
										],
										o: [
											[0, 0],
											[0, 0],
											[0, -7.129],
											[6.738, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, -12.549],
											[-8.252, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
										],
										v: [
											[4.834, 0],
											[19.092, 0],
											[19.092, -30.664],
											[30.029, -42.676],
											[40.088, -31.494],
											[40.088, 0],
											[54.346, 0],
											[54.346, -34.473],
											[35.693, -54.541],
											[18.896, -44.141],
											[18.604, -44.141],
											[18.604, -53.76],
											[4.834, -53.76],
										],
										c: true,
									},
									ix: 2,
								},
								nm: "n",
								mn: "ADBE Vector Shape - Group",
								hd: false,
							},
						],
						nm: "n",
						np: 3,
						cix: 2,
						bm: 0,
						ix: 1,
						mn: "ADBE Vector Group",
						hd: false,
					},
				],
			},
			fFamily: "SF UI Display",
		},
		{
			ch: "t",
			size: 64,
			style: "Bold",
			w: 36.18,
			data: {
				shapes: [
					{
						ty: "gr",
						it: [
							{
								ind: 0,
								ty: "sh",
								ix: 1,
								ks: {
									a: 0,
									k: {
										i: [
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[-12.451, 0],
											[-1.465, 0.293],
											[0, 0],
											[1.27, 0],
											[0, 4.15],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
										],
										o: [
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 10.498],
											[2.881, 0],
											[0, 0],
											[-0.879, 0.146],
											[-4.346, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
										],
										v: [
											[8.643, -65.723],
											[8.643, -53.76],
											[1.367, -53.76],
											[1.367, -42.969],
											[8.643, -42.969],
											[8.643, -14.502],
											[25.977, 0.195],
											[32.861, -0.391],
											[32.861, -10.938],
											[29.297, -10.693],
											[22.9, -16.846],
											[22.9, -42.969],
											[32.861, -42.969],
											[32.861, -53.76],
											[22.9, -53.76],
											[22.9, -65.723],
										],
										c: true,
									},
									ix: 2,
								},
								nm: "t",
								mn: "ADBE Vector Shape - Group",
								hd: false,
							},
						],
						nm: "t",
						np: 3,
						cix: 2,
						bm: 0,
						ix: 1,
						mn: "ADBE Vector Group",
						hd: false,
					},
				],
			},
			fFamily: "SF UI Display",
		},
		{
			ch: " ",
			size: 64,
			style: "Bold",
			w: 21.48,
			data: {},
			fFamily: "SF UI Display",
		},
		{
			ch: "F",
			size: 64,
			style: "Bold",
			w: 54.83,
			data: {
				shapes: [
					{
						ty: "gr",
						it: [
							{
								ind: 0,
								ty: "sh",
								ix: 1,
								ks: {
									a: 0,
									k: {
										i: [
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
										],
										o: [
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
										],
										v: [
											[20.605, 0],
											[20.605, -27.734],
											[49.268, -27.734],
											[49.268, -39.404],
											[20.605, -39.404],
											[20.605, -58.301],
											[52.002, -58.301],
											[52.002, -70.459],
											[5.859, -70.459],
											[5.859, 0],
										],
										c: true,
									},
									ix: 2,
								},
								nm: "F",
								mn: "ADBE Vector Shape - Group",
								hd: false,
							},
						],
						nm: "F",
						np: 3,
						cix: 2,
						bm: 0,
						ix: 1,
						mn: "ADBE Vector Group",
						hd: false,
					},
				],
			},
			fFamily: "SF UI Display",
		},
		{
			ch: "i",
			size: 64,
			style: "Bold",
			w: 24.41,
			data: {
				shapes: [
					{
						ty: "gr",
						it: [
							{
								ind: 0,
								ty: "sh",
								ix: 1,
								ks: {
									a: 0,
									k: {
										i: [
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
										],
										o: [
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
										],
										v: [
											[5.078, 0],
											[19.336, 0],
											[19.336, -53.76],
											[5.078, -53.76],
										],
										c: true,
									},
									ix: 2,
								},
								nm: "i",
								mn: "ADBE Vector Shape - Group",
								hd: false,
							},
							{
								ind: 1,
								ty: "sh",
								ix: 2,
								ks: {
									a: 0,
									k: {
										i: [
											[-4.199, 0],
											[0, 4.248],
											[4.248, 0],
											[0, -4.248],
										],
										o: [
											[4.248, 0],
											[0, -4.248],
											[-4.199, 0],
											[0, 4.248],
										],
										v: [
											[12.207, -59.619],
											[19.922, -67.09],
											[12.207, -74.512],
											[4.541, -67.09],
										],
										c: true,
									},
									ix: 2,
								},
								nm: "i",
								mn: "ADBE Vector Shape - Group",
								hd: false,
							},
						],
						nm: "i",
						np: 5,
						cix: 2,
						bm: 0,
						ix: 1,
						mn: "ADBE Vector Group",
						hd: false,
					},
				],
			},
			fFamily: "SF UI Display",
		},
		{
			ch: "l",
			size: 64,
			style: "Bold",
			w: 24.66,
			data: {
				shapes: [
					{
						ty: "gr",
						it: [
							{
								ind: 0,
								ty: "sh",
								ix: 1,
								ks: {
									a: 0,
									k: {
										i: [
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
										],
										o: [
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
										],
										v: [
											[5.225, 0],
											[19.482, 0],
											[19.482, -73.828],
											[5.225, -73.828],
										],
										c: true,
									},
									ix: 2,
								},
								nm: "l",
								mn: "ADBE Vector Shape - Group",
								hd: false,
							},
						],
						nm: "l",
						np: 3,
						cix: 2,
						bm: 0,
						ix: 1,
						mn: "ADBE Vector Group",
						hd: false,
					},
				],
			},
			fFamily: "SF UI Display",
		},
		{
			ch: "d",
			size: 64,
			style: "Bold",
			w: 60.99,
			data: {
				shapes: [
					{
						ty: "gr",
						it: [
							{
								ind: 0,
								ty: "sh",
								ix: 1,
								ks: {
									a: 0,
									k: {
										i: [
											[-13.379, 0],
											[-2.637, 5.908],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[7.764, 0],
											[0, -16.846],
										],
										o: [
											[7.91, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[0, 0],
											[-2.686, -5.957],
											[-13.379, 0],
											[0, 16.943],
										],
										v: [
											[24.854, 0.635],
											[41.602, -9.18],
											[41.846, -9.18],
											[41.846, 0],
											[55.908, 0],
											[55.908, -73.828],
											[41.65, -73.828],
											[41.65, -44.58],
											[41.357, -44.58],
											[24.902, -54.395],
											[2.832, -26.953],
										],
										c: true,
									},
									ix: 2,
								},
								nm: "d",
								mn: "ADBE Vector Shape - Group",
								hd: false,
							},
							{
								ind: 1,
								ty: "sh",
								ix: 2,
								ks: {
									a: 0,
									k: {
										i: [
											[-7.422, 0],
											[0, -9.717],
											[7.373, 0],
											[0, 9.863],
										],
										o: [
											[7.373, 0],
											[0, 9.717],
											[-7.422, 0],
											[0, -9.766],
										],
										v: [
											[29.59, -42.871],
											[41.748, -26.855],
											[29.59, -10.889],
											[17.432, -26.904],
										],
										c: true,
									},
									ix: 2,
								},
								nm: "d",
								mn: "ADBE Vector Shape - Group",
								hd: false,
							},
						],
						nm: "d",
						np: 5,
						cix: 2,
						bm: 0,
						ix: 1,
						mn: "ADBE Vector Group",
						hd: false,
					},
				],
			},
			fFamily: "SF UI Display",
		},
	],
};

const SuccessAnimation = {
	v: "5.5.7",
	meta: { g: "LottieFiles AE 0.1.21", a: "", k: "", d: "", tc: "" },
	fr: 29.9700012207031,
	ip: 0,
	op: 50.0000020365418,
	w: 360,
	h: 360,
	nm: "Success",
	ddd: 0,
	assets: [],
	layers: [
		{
			ddd: 0,
			ind: 1,
			ty: 4,
			nm: "Tick Outlines",
			sr: 1,
			ks: {
				o: {
					a: 1,
					k: [
						{ t: 0, s: [0], h: 1 },
						{
							i: { x: [0.833], y: [0.833] },
							o: { x: [1], y: [0.005] },
							t: 20,
							s: [0],
						},
						{ t: 26.0000010590017, s: [100] },
					],
					ix: 11,
				},
				r: { a: 0, k: 0, ix: 10 },
				p: { a: 0, k: [180.056, 180.011, 0], ix: 2 },
				a: { a: 0, k: [28.402, 25.421, 0], ix: 1 },
				s: {
					a: 1,
					k: [
						{
							i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, -24] },
							o: { x: [1, 1, 0.333], y: [0.003, 0.003, 0] },
							t: 20,
							s: [0, 0, 100],
						},
						{
							i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
							o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
							t: 26,
							s: [150, 150, 100],
						},
						{
							i: { x: [0.599, 0.599, 0.833], y: [1.945, 1.945, 1] },
							o: { x: [0.211, 0.211, 0.167], y: [1.647, 1.647, 0] },
							t: 30,
							s: [80, 80, 100],
						},
						{ t: 36.0000014663101, s: [100, 100, 100] },
					],
					ix: 6,
				},
			},
			ao: 0,
			shapes: [
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[-2.283, -2.474],
										[0, 0],
										[0, 0],
										[-2.652, -2.073],
										[2.073, -2.652],
										[0, 0],
										[2.549, 2.761],
										[0, 0],
										[-2.474, 2.283],
									],
									o: [
										[0, 0],
										[0, 0],
										[2.074, -2.651],
										[2.652, 2.074],
										[0, 0],
										[-2.315, 2.96],
										[0, 0],
										[-2.283, -2.474],
										[2.473, -2.283],
									],
									v: [
										[-16.911, -1.097],
										[-7.709, 8.873],
										[16.476, -22.051],
										[25.032, -23.098],
										[26.079, -14.541],
										[-2.523, 22.03],
										[-11.804, 22.41],
										[-25.868, 7.172],
										[-25.524, -1.442],
									],
									c: true,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "fl",
							c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: { a: 0, k: [28.401, 25.421], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 1",
					np: 2,
					cix: 2,
					bm: 0,
					ix: 1,
					mn: "ADBE Vector Group",
					hd: false,
				},
			],
			ip: 0,
			op: 50.0000020365418,
			st: 0,
			bm: 0,
		},
		{
			ddd: 0,
			ind: 2,
			ty: 4,
			nm: "Solid Circle Outlines",
			sr: 1,
			ks: {
				o: {
					a: 1,
					k: [
						{
							i: { x: [0.833], y: [0.833] },
							o: { x: [0.167], y: [0.167] },
							t: 0,
							s: [0],
						},
						{
							i: { x: [0.833], y: [0.833] },
							o: { x: [0.167], y: [0.167] },
							t: 10,
							s: [0],
						},
						{ t: 20.0000008146167, s: [100] },
					],
					ix: 11,
				},
				r: { a: 0, k: 0, ix: 10 },
				p: { a: 0, k: [180, 179.999, 0], ix: 2 },
				a: { a: 0, k: [60.25, 60.25, 0], ix: 1 },
				s: { a: 0, k: [100, 100, 100], ix: 6 },
			},
			ao: 0,
			shapes: [
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[-33.138, 0],
										[0, 33.138],
										[33.137, 0],
										[0, -33.137],
									],
									o: [
										[33.137, 0],
										[0, -33.137],
										[-33.138, 0],
										[0, 33.138],
									],
									v: [
										[0, 60],
										[60, 0],
										[0, -60],
										[-60, 0],
									],
									c: true,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "fl",
							c: {
								a: 0,
								k: [0.305220211253, 0.725116265989, 0.096665498322, 1],
								ix: 4,
							},
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: { a: 0, k: [60.25, 60.25], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 1",
					np: 2,
					cix: 2,
					bm: 0,
					ix: 1,
					mn: "ADBE Vector Group",
					hd: false,
				},
			],
			ip: 0,
			op: 50.0000020365418,
			st: 0,
			bm: 0,
		},
		{
			ddd: 0,
			ind: 3,
			ty: 4,
			nm: "Big Green Border Outlines",
			sr: 1,
			ks: {
				o: {
					a: 1,
					k: [
						{
							i: { x: [0.833], y: [0.833] },
							o: { x: [0.167], y: [0.167] },
							t: 16,
							s: [0],
						},
						{
							i: { x: [0.833], y: [0.833] },
							o: { x: [0.167], y: [0.167] },
							t: 18,
							s: [50],
						},
						{ t: 21.0000008553475, s: [0] },
					],
					ix: 11,
				},
				r: { a: 0, k: 0, ix: 10 },
				p: { a: 0, k: [180, 179.999, 0], ix: 2 },
				a: { a: 0, k: [89.955, 89.955, 0], ix: 1 },
				s: {
					a: 1,
					k: [
						{
							i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
							o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
							t: 0,
							s: [0, 0, 100],
						},
						{
							i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
							o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
							t: 16,
							s: [72, 72, 100],
						},
						{ t: 21.0000008553475, s: [150, 150, 100] },
					],
					ix: 6,
				},
			},
			ao: 0,
			shapes: [
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[-44.183, 0],
										[0, 44.184],
										[44.183, 0],
										[0, -44.183],
									],
									o: [
										[44.183, 0],
										[0, -44.183],
										[-44.183, 0],
										[0, 44.184],
									],
									v: [
										[0, 80],
										[80, 0],
										[0, -80],
										[-80, 0],
									],
									c: true,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "st",
							c: {
								a: 0,
								k: [0.305220211253, 0.725116265989, 0.096665498322, 1],
								ix: 3,
							},
							o: { a: 0, k: 100, ix: 4 },
							w: { a: 0, k: 3.982, ix: 5 },
							lc: 1,
							lj: 1,
							ml: 10,
							bm: 0,
							nm: "Stroke 1",
							mn: "ADBE Vector Graphic - Stroke",
							hd: false,
						},
						{
							ty: "tr",
							p: { a: 0, k: [89.955, 89.955], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 1",
					np: 2,
					cix: 2,
					bm: 0,
					ix: 1,
					mn: "ADBE Vector Group",
					hd: false,
				},
			],
			ip: 0,
			op: 50.0000020365418,
			st: 0,
			bm: 0,
		},
		{
			ddd: 0,
			ind: 4,
			ty: 4,
			nm: "Green Border Outlines",
			sr: 1,
			ks: {
				o: {
					a: 1,
					k: [
						{
							i: { x: [0.833], y: [0.833] },
							o: { x: [0.167], y: [0.167] },
							t: 10,
							s: [100],
						},
						{ t: 20.0000008146167, s: [0] },
					],
					ix: 11,
				},
				r: { a: 0, k: 0, ix: 10 },
				p: { a: 0, k: [180, 179.999, 0], ix: 2 },
				a: { a: 0, k: [67.465, 67.465, 0], ix: 1 },
				s: {
					a: 1,
					k: [
						{
							i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
							o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
							t: 10,
							s: [100, 100, 100],
						},
						{ t: 20.0000008146167, s: [150, 150, 100] },
					],
					ix: 6,
				},
			},
			ao: 0,
			shapes: [
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[-33.138, 0],
										[0, 33.138],
										[33.137, 0],
										[0, -33.137],
									],
									o: [
										[33.137, 0],
										[0, -33.137],
										[-33.138, 0],
										[0, 33.138],
									],
									v: [
										[0, 60],
										[60, 0],
										[0, -60],
										[-60, 0],
									],
									c: true,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "tm",
							s: {
								a: 1,
								k: [
									{
										i: { x: [0.833], y: [0.833] },
										o: { x: [0.167], y: [0.167] },
										t: 0,
										s: [100],
									},
									{ t: 10.0000004073083, s: [0] },
								],
								ix: 1,
							},
							e: { a: 0, k: 100, ix: 2 },
							o: { a: 0, k: 0, ix: 3 },
							m: 1,
							ix: 2,
							nm: "Trim Paths 1",
							mn: "ADBE Vector Filter - Trim",
							hd: false,
						},
						{
							ty: "st",
							c: {
								a: 0,
								k: [0.305220211253, 0.725116265989, 0.096665498322, 1],
								ix: 3,
							},
							o: { a: 0, k: 100, ix: 4 },
							w: { a: 0, k: 2.986, ix: 5 },
							lc: 1,
							lj: 1,
							ml: 10,
							bm: 0,
							nm: "Stroke 1",
							mn: "ADBE Vector Graphic - Stroke",
							hd: false,
						},
						{
							ty: "tr",
							p: { a: 0, k: [67.465, 67.465], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 1",
					np: 3,
					cix: 2,
					bm: 0,
					ix: 1,
					mn: "ADBE Vector Group",
					hd: false,
				},
			],
			ip: 0,
			op: 50.0000020365418,
			st: 0,
			bm: 0,
		},
		{
			ddd: 0,
			ind: 5,
			ty: 4,
			nm: "Burst Outlines",
			sr: 1,
			ks: {
				o: {
					a: 1,
					k: [
						{ t: 0, s: [0], h: 1 },
						{
							i: { x: [0.833], y: [0.833] },
							o: { x: [0.167], y: [0.167] },
							t: 23,
							s: [0],
						},
						{
							i: { x: [0.833], y: [0.833] },
							o: { x: [0.333], y: [0] },
							t: 25,
							s: [100],
						},
						{ t: 30.0000012219251, s: [0] },
					],
					ix: 11,
				},
				r: { a: 0, k: 0, ix: 10 },
				p: { a: 0, k: [180.734, 175.365, 0], ix: 2 },
				a: { a: 0, k: [104.611, 106.395, 0], ix: 1 },
				s: { a: 0, k: [100, 100, 100], ix: 6 },
			},
			ao: 0,
			shapes: [
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, -1.811],
										[1.81, 0],
										[0, 1.811],
										[-1.811, 0],
									],
									o: [
										[0, 1.811],
										[-1.811, 0],
										[0, -1.811],
										[1.81, 0],
									],
									v: [
										[3.279, 0],
										[0, 3.278],
										[-3.279, 0],
										[0, -3.278],
									],
									c: true,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "fl",
							c: {
								a: 0,
								k: [0.305220211253, 0.725116265989, 0.096665498322, 1],
								ix: 4,
							},
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: {
								a: 1,
								k: [
									{
										i: { x: 0.833, y: 0.833 },
										o: { x: 0.167, y: 0.167 },
										t: 19,
										s: [106, 106],
										to: [14.492, 8.317],
										ti: [-14.492, -8.317],
									},
									{ t: 26.0000010590017, s: [192.95, 155.899] },
								],
								ix: 2,
							},
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 1",
					np: 2,
					cix: 2,
					bm: 0,
					ix: 1,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, -1.811],
										[1.81, 0],
										[0, 1.812],
										[-1.811, 0],
									],
									o: [
										[0, 1.812],
										[-1.811, 0],
										[0, -1.811],
										[1.81, 0],
									],
									v: [
										[3.279, -0.001],
										[0, 3.279],
										[-3.279, -0.001],
										[0, -3.279],
									],
									c: true,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "fl",
							c: {
								a: 0,
								k: [0.305220211253, 0.725116265989, 0.096665498322, 1],
								ix: 4,
							},
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: {
								a: 1,
								k: [
									{
										i: { x: 0.833, y: 0.833 },
										o: { x: 0.167, y: 0.167 },
										t: 19,
										s: [105, 105],
										to: [13.019, -6.16],
										ti: [-13.019, 6.16],
									},
									{ t: 26.0000010590017, s: [183.114, 68.041] },
								],
								ix: 2,
							},
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 2",
					np: 2,
					cix: 2,
					bm: 0,
					ix: 2,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, -3.621],
										[3.621, 0],
										[0, 3.621],
										[-3.621, 0],
									],
									o: [
										[0, 3.621],
										[-3.621, 0],
										[0, -3.621],
										[3.621, 0],
									],
									v: [
										[6.557, 0],
										[-0.001, 6.557],
										[-6.557, 0],
										[-0.001, -6.557],
									],
									c: true,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "fl",
							c: {
								a: 0,
								k: [0.305220211253, 0.725116265989, 0.096665498322, 1],
								ix: 4,
							},
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: {
								a: 1,
								k: [
									{
										i: { x: 0.833, y: 0.833 },
										o: { x: 0.167, y: 0.167 },
										t: 19,
										s: [105, 105],
										to: [11.926, -14.076],
										ti: [-11.926, 14.076],
									},
									{ t: 26.0000010590017, s: [176.557, 20.546] },
								],
								ix: 2,
							},
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 3",
					np: 2,
					cix: 2,
					bm: 0,
					ix: 3,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, -1.811],
										[1.811, 0],
										[0, 1.811],
										[-1.811, 0],
									],
									o: [
										[0, 1.811],
										[-1.811, 0],
										[0, -1.811],
										[1.811, 0],
									],
									v: [
										[3.279, 0],
										[0, 3.278],
										[-3.279, 0],
										[0, -3.278],
									],
									c: true,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "fl",
							c: {
								a: 0,
								k: [0.305220211253, 0.725116265989, 0.096665498322, 1],
								ix: 4,
							},
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: {
								a: 1,
								k: [
									{
										i: { x: 0.833, y: 0.833 },
										o: { x: 0.167, y: 0.167 },
										t: 19,
										s: [105, 105],
										to: [-13.176, -8.053],
										ti: [13.176, 8.053],
									},
									{ t: 26.0000010590017, s: [25.942, 56.679] },
								],
								ix: 2,
							},
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 4",
					np: 2,
					cix: 2,
					bm: 0,
					ix: 4,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, -1.811],
										[1.81, 0],
										[0, 1.811],
										[-1.811, 0],
									],
									o: [
										[0, 1.811],
										[-1.811, 0],
										[0, -1.811],
										[1.81, 0],
									],
									v: [
										[3.279, 0],
										[0, 3.278],
										[-3.279, 0],
										[0, -3.278],
									],
									c: true,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "fl",
							c: {
								a: 0,
								k: [0.305220211253, 0.725116265989, 0.096665498322, 1],
								ix: 4,
							},
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: {
								a: 1,
								k: [
									{
										i: { x: 0.833, y: 0.833 },
										o: { x: 0.167, y: 0.167 },
										t: 19,
										s: [105, 105],
										to: [-4.759, 16.085],
										ti: [4.759, -16.085],
									},
									{ t: 26.0000010590017, s: [76.448, 201.512] },
								],
								ix: 2,
							},
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 5",
					np: 2,
					cix: 2,
					bm: 0,
					ix: 5,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, -1.811],
										[1.81, 0],
										[0, 1.811],
										[-1.811, 0],
									],
									o: [
										[0, 1.811],
										[-1.811, 0],
										[0, -1.811],
										[1.81, 0],
									],
									v: [
										[3.279, 0],
										[0, 3.278],
										[-3.279, 0],
										[0, -3.278],
									],
									c: true,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "fl",
							c: {
								a: 0,
								k: [0.305220211253, 0.725116265989, 0.096665498322, 1],
								ix: 4,
							},
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: {
								a: 1,
								k: [
									{
										i: { x: 0.833, y: 0.833 },
										o: { x: 0.167, y: 0.167 },
										t: 19,
										s: [105, 105],
										to: [6.502, 16.085],
										ti: [-6.502, -16.085],
									},
									{ t: 26.0000010590017, s: [144.009, 201.512] },
								],
								ix: 2,
							},
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 6",
					np: 2,
					cix: 2,
					bm: 0,
					ix: 6,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, -1.812],
										[1.81, 0],
										[0, 1.811],
										[-1.811, 0],
									],
									o: [
										[0, 1.811],
										[-1.811, 0],
										[0, -1.812],
										[1.81, 0],
									],
									v: [
										[3.279, 0],
										[0, 3.279],
										[-3.279, 0],
										[0, -3.279],
									],
									c: true,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "fl",
							c: {
								a: 0,
								k: [0.305220211253, 0.725116265989, 0.096665498322, 1],
								ix: 4,
							},
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: {
								a: 1,
								k: [
									{
										i: { x: 0.833, y: 0.833 },
										o: { x: 0.167, y: 0.167 },
										t: 19,
										s: [105, 105],
										to: [16.782, 4.522],
										ti: [-16.782, -4.522],
									},
									{ t: 26.0000010590017, s: [205.693, 132.132] },
								],
								ix: 2,
							},
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 7",
					np: 2,
					cix: 2,
					bm: 0,
					ix: 7,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, -3.621],
										[3.621, 0],
										[0, 3.621],
										[-3.622, 0],
									],
									o: [
										[0, 3.621],
										[-3.622, 0],
										[0, -3.621],
										[3.621, 0],
									],
									v: [
										[6.557, 0],
										[0, 6.557],
										[-6.557, 0],
										[0, -6.557],
									],
									c: true,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "fl",
							c: {
								a: 0,
								k: [0.305220211253, 0.725116265989, 0.096665498322, 1],
								ix: 4,
							},
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: {
								a: 1,
								k: [
									{
										i: { x: 0.833, y: 0.833 },
										o: { x: 0.167, y: 0.167 },
										t: 19,
										s: [105, 105],
										to: [-12.083, 15.368],
										ti: [12.083, -15.368],
									},
									{ t: 26.0000010590017, s: [32.499, 197.209] },
								],
								ix: 2,
							},
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 8",
					np: 2,
					cix: 2,
					bm: 0,
					ix: 8,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, -1.812],
										[1.81, 0],
										[0, 1.811],
										[-1.811, 0],
									],
									o: [
										[0, 1.811],
										[-1.811, 0],
										[0, -1.812],
										[1.81, 0],
									],
									v: [
										[3.279, 0],
										[0.001, 3.279],
										[-3.279, 0],
										[0.001, -3.279],
									],
									c: true,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "fl",
							c: {
								a: 0,
								k: [0.305220211253, 0.725116265989, 0.096665498322, 1],
								ix: 4,
							},
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: {
								a: 1,
								k: [
									{
										i: { x: 0.833, y: 0.833 },
										o: { x: 0.167, y: 0.167 },
										t: 19,
										s: [105, 105],
										to: [-11.537, -14.622],
										ti: [11.537, 14.622],
									},
									{ t: 26.0000010590017, s: [35.778, 17.268] },
								],
								ix: 2,
							},
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 9",
					np: 2,
					cix: 2,
					bm: 0,
					ix: 9,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, -1.811],
										[1.81, 0],
										[0, 1.811],
										[-1.811, 0],
									],
									o: [
										[0, 1.811],
										[-1.811, 0],
										[0, -1.811],
										[1.81, 0],
									],
									v: [
										[3.279, 0],
										[0, 3.278],
										[-3.279, 0],
										[0, -3.278],
									],
									c: true,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "fl",
							c: {
								a: 0,
								k: [0.305220211253, 0.725116265989, 0.096665498322, 1],
								ix: 4,
							},
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: {
								a: 1,
								k: [
									{
										i: { x: 0.833, y: 0.833 },
										o: { x: 0.167, y: 0.167 },
										t: 19,
										s: [105, 105],
										to: [7.594, -12.436],
										ti: [-7.594, 12.436],
									},
									{ t: 26.0000010590017, s: [150.566, 30.382] },
								],
								ix: 2,
							},
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 10",
					np: 2,
					cix: 2,
					bm: 0,
					ix: 10,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, -1.811],
										[1.81, 0],
										[0, 1.811],
										[-1.811, 0],
									],
									o: [
										[0, 1.811],
										[-1.811, 0],
										[0, -1.811],
										[1.81, 0],
									],
									v: [
										[3.279, 0],
										[0.001, 3.278],
										[-3.279, 0],
										[0.001, -3.278],
									],
									c: true,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "fl",
							c: {
								a: 0,
								k: [0.305220211253, 0.725116265989, 0.096665498322, 1],
								ix: 4,
							},
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: {
								a: 1,
								k: [
									{
										i: { x: 0.833, y: 0.833 },
										o: { x: 0.167, y: 0.167 },
										t: 19,
										s: [105, 105],
										to: [-15.819, 10.036],
										ti: [15.819, -10.036],
									},
									{ t: 26.0000010590017, s: [10.084, 165.214] },
								],
								ix: 2,
							},
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 11",
					np: 2,
					cix: 2,
					bm: 0,
					ix: 11,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, -3.621],
										[3.621, 0],
										[0, 3.621],
										[-3.622, 0],
									],
									o: [
										[0, 3.621],
										[-3.622, 0],
										[0, -3.621],
										[3.621, 0],
									],
									v: [
										[6.557, 0],
										[0, 6.557],
										[-6.557, 0],
										[0, -6.557],
									],
									c: true,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "fl",
							c: {
								a: 0,
								k: [0.305220211253, 0.725116265989, 0.096665498322, 1],
								ix: 4,
							},
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: {
								a: 1,
								k: [
									{
										i: { x: 0.833, y: 0.833 },
										o: { x: 0.167, y: 0.167 },
										t: 19,
										s: [105, 105],
										to: [16.236, -4.49],
										ti: [-16.236, 4.49],
									},
									{ t: 26.0000010590017, s: [202.414, 78.057] },
								],
								ix: 2,
							},
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 12",
					np: 2,
					cix: 2,
					bm: 0,
					ix: 12,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, -3.621],
										[3.622, 0],
										[0, 3.621],
										[-3.621, 0],
									],
									o: [
										[0, 3.621],
										[-3.621, 0],
										[0, -3.621],
										[3.622, 0],
									],
									v: [
										[6.557, 0],
										[-0.001, 6.557],
										[-6.557, 0],
										[-0.001, -6.557],
									],
									c: true,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "fl",
							c: {
								a: 0,
								k: [0.305220211253, 0.725116265989, 0.096665498322, 1],
								ix: 4,
							},
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: {
								a: 1,
								k: [
									{
										i: { x: 0.833, y: 0.833 },
										o: { x: 0.167, y: 0.167 },
										t: 19,
										s: [105, 105],
										to: [-6.398, -14.076],
										ti: [6.398, 14.076],
									},
									{ t: 26.0000010590017, s: [66.613, 20.546] },
								],
								ix: 2,
							},
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 13",
					np: 2,
					cix: 2,
					bm: 0,
					ix: 13,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, -1.811],
										[1.81, 0],
										[0, 1.811],
										[-1.811, 0],
									],
									o: [
										[0, 1.811],
										[-1.811, 0],
										[0, -1.811],
										[1.81, 0],
									],
									v: [
										[3.279, 0],
										[0, 3.278],
										[-3.279, 0],
										[0, -3.278],
									],
									c: true,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "fl",
							c: {
								a: 0,
								k: [0.305220211253, 0.725116265989, 0.096665498322, 1],
								ix: 4,
							},
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: {
								a: 1,
								k: [
									{
										i: { x: 0.833, y: 0.833 },
										o: { x: 0.167, y: 0.167 },
										t: 19,
										s: [105, 105],
										to: [1.09, 17.377],
										ti: [-1.09, -17.377],
									},
									{ t: 26.0000010590017, s: [111.542, 209.261] },
								],
								ix: 2,
							},
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 14",
					np: 2,
					cix: 2,
					bm: 0,
					ix: 14,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, -3.621],
										[3.621, 0],
										[0, 3.621],
										[-3.621, 0],
									],
									o: [
										[0, 3.621],
										[-3.621, 0],
										[0, -3.621],
										[3.621, 0],
									],
									v: [
										[6.557, 0],
										[-0.001, 6.557],
										[-6.557, 0],
										[-0.001, -6.557],
									],
									c: true,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "fl",
							c: {
								a: 0,
								k: [0.305220211253, 0.725116265989, 0.096665498322, 1],
								ix: 4,
							},
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: {
								a: 1,
								k: [
									{
										i: { x: 0.833, y: 0.833 },
										o: { x: 0.167, y: 0.167 },
										t: 19,
										s: [105, 105],
										to: [-16.366, -0.088],
										ti: [16.366, 0.088],
									},
									{ t: 26.0000010590017, s: [6.806, 104.472] },
								],
								ix: 2,
							},
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 15",
					np: 2,
					cix: 2,
					bm: 0,
					ix: 15,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, -3.621],
										[3.621, 0],
										[0, 3.621],
										[-3.621, 0],
									],
									o: [
										[0, 3.621],
										[-3.621, 0],
										[0, -3.621],
										[3.621, 0],
									],
									v: [
										[6.557, -0.001],
										[-0.001, 6.557],
										[-6.557, -0.001],
										[-0.001, -6.557],
									],
									c: true,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "fl",
							c: {
								a: 0,
								k: [0.305220211253, 0.725116265989, 0.096665498322, 1],
								ix: 4,
							},
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: {
								a: 1,
								k: [
									{
										i: { x: 0.833, y: 0.833 },
										o: { x: 0.167, y: 0.167 },
										t: 19,
										s: [105, 105],
										to: [13.019, 13.182],
										ti: [-13.019, -13.182],
									},
									{ t: 26.0000010590017, s: [183.115, 184.095] },
								],
								ix: 2,
							},
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 16",
					np: 2,
					cix: 2,
					bm: 0,
					ix: 16,
					mn: "ADBE Vector Group",
					hd: false,
				},
				{
					ty: "gr",
					it: [
						{
							ind: 0,
							ty: "sh",
							ix: 1,
							ks: {
								a: 0,
								k: {
									i: [
										[0, -1.811],
										[1.811, 0],
										[0, 1.811],
										[-1.811, 0],
									],
									o: [
										[0, 1.811],
										[-1.811, 0],
										[0, -1.811],
										[1.811, 0],
									],
									v: [
										[3.279, 0],
										[0, 3.278],
										[-3.279, 0],
										[0, -3.278],
									],
									c: true,
								},
								ix: 2,
							},
							nm: "Path 1",
							mn: "ADBE Vector Shape - Group",
							hd: false,
						},
						{
							ty: "fl",
							c: {
								a: 0,
								k: [0.305220211253, 0.725116265989, 0.096665498322, 1],
								ix: 4,
							},
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							bm: 0,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: {
								a: 1,
								k: [
									{
										i: { x: 0.833, y: 0.833 },
										o: { x: 0.167, y: 0.167 },
										t: 19,
										s: [105, 105],
										to: [1.146, -16.912],
										ti: [-1.146, 16.912],
									},
									{ t: 26.0000010590017, s: [111.875, 3.528] },
								],
								ix: 2,
							},
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Group 17",
					np: 2,
					cix: 2,
					bm: 0,
					ix: 17,
					mn: "ADBE Vector Group",
					hd: false,
				},
			],
			ip: 0,
			op: 50.0000020365418,
			st: 0,
			bm: 0,
		},
	],
	markers: [],
};

const LoadingAnimation = {
	v: "5.4.2",
	fr: 30,
	ip: 0,
	op: 30,
	w: 500,
	h: 500,
	nm: "Loading2",
	ddd: 0,
	assets: [],
	layers: [
		{
			ddd: 0,
			ind: 1,
			ty: 4,
			nm: "Shape Layer 4",
			sr: 1,
			ks: {
				o: { a: 0, k: 100, ix: 11 },
				r: { a: 0, k: 0, ix: 10 },
				p: {
					a: 1,
					k: [
						{
							i: { x: 0.667, y: 1 },
							o: { x: 0.333, y: 0 },
							n: "0p667_1_0p333_0",
							t: 0,
							s: [329.961, 250.96100000000004, 0],
							e: [329.961, 193.961, 0],
							to: [0, 0, 0],
							ti: [0, 0, 0],
						},
						{
							i: { x: 0.667, y: 1 },
							o: { x: 0.333, y: 0 },
							n: "0p667_1_0p333_0",
							t: 24,
							s: [329.961, 193.961, 0],
							e: [329.961, 250.961, 0],
							to: [0, 0, 0],
							ti: [0, 0, 0],
						},
						{ t: 30.0000012219251 },
					],
					ix: 2,
				},
				a: { a: 0, k: [-98.039, 8.961, 0], ix: 1 },
				s: { a: 0, k: [100, 100, 100], ix: 6 },
			},
			ao: 0,
			shapes: [
				{
					ty: "gr",
					it: [
						{
							d: 1,
							ty: "el",
							s: { a: 0, k: [39.922, 39.922], ix: 2 },
							p: { a: 0, k: [0, 0], ix: 3 },
							nm: "Ellipse Path 1",
							mn: "ADBE Vector Shape - Ellipse",
							hd: false,
						},
						{
							ty: "fl",
							c: {
								a: 0,
								k: [
									0.6078431372549019, 0.6078431372549019, 0.6078431372549019, 1,
								],
								ix: 4,
							},
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: { a: 0, k: [-98.039, 8.961], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Ellipse 1",
					np: 3,
					cix: 2,
					ix: 1,
					mn: "ADBE Vector Group",
					hd: false,
				},
			],
			ip: 0,
			op: 32.0000013033867,
			st: 0,
			bm: 0,
		},
		{
			ddd: 0,
			ind: 2,
			ty: 4,
			nm: "Shape Layer 3",
			sr: 1,
			ks: {
				o: { a: 0, k: 100, ix: 11 },
				r: { a: 0, k: 0, ix: 10 },
				p: {
					a: 1,
					k: [
						{
							i: { x: 0.667, y: 1 },
							o: { x: 0.333, y: 0 },
							n: "0p667_1_0p333_0",
							t: 0,
							s: [272.628, 250.96100000000004, 0],
							e: [272.628, 193.961, 0],
							to: [0, 0, 0],
							ti: [0, 0, 0],
						},
						{
							i: { x: 0.667, y: 1 },
							o: { x: 0.333, y: 0 },
							n: "0p667_1_0p333_0",
							t: 18,
							s: [272.628, 193.961, 0],
							e: [272.628, 250.961, 0],
							to: [0, 0, 0],
							ti: [0, 0, 0],
						},
						{ t: 30.0000012219251 },
					],
					ix: 2,
				},
				a: { a: 0, k: [-98.039, 8.961, 0], ix: 1 },
				s: { a: 0, k: [100, 100, 100], ix: 6 },
			},
			ao: 0,
			shapes: [
				{
					ty: "gr",
					it: [
						{
							d: 1,
							ty: "el",
							s: { a: 0, k: [39.922, 39.922], ix: 2 },
							p: { a: 0, k: [0, 0], ix: 3 },
							nm: "Ellipse Path 1",
							mn: "ADBE Vector Shape - Ellipse",
							hd: false,
						},
						{
							ty: "fl",
							c: {
								a: 0,
								k: [
									0.6078431372549019, 0.6078431372549019, 0.6078431372549019, 1,
								],
								ix: 4,
							},
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: { a: 0, k: [-98.039, 8.961], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Ellipse 1",
					np: 3,
					cix: 2,
					ix: 1,
					mn: "ADBE Vector Group",
					hd: false,
				},
			],
			ip: 0,
			op: 32.0000013033867,
			st: 0,
			bm: 0,
		},
		{
			ddd: 0,
			ind: 3,
			ty: 4,
			nm: "Shape Layer 2",
			sr: 1,
			ks: {
				o: { a: 0, k: 100, ix: 11 },
				r: { a: 0, k: 0, ix: 10 },
				p: {
					a: 1,
					k: [
						{
							i: { x: 0.667, y: 1 },
							o: { x: 0.333, y: 0 },
							n: "0p667_1_0p333_0",
							t: 0,
							s: [215.294, 250.96100000000004, 0],
							e: [215.294, 193.961, 0],
							to: [0, 0, 0],
							ti: [0, 0, 0],
						},
						{
							i: { x: 0.667, y: 1 },
							o: { x: 0.333, y: 0 },
							n: "0p667_1_0p333_0",
							t: 12,
							s: [215.294, 193.961, 0],
							e: [215.294, 250.961, 0],
							to: [0, 0, 0],
							ti: [0, 0, 0],
						},
						{ t: 30.0000012219251 },
					],
					ix: 2,
				},
				a: { a: 0, k: [-98.039, 8.961, 0], ix: 1 },
				s: { a: 0, k: [100, 100, 100], ix: 6 },
			},
			ao: 0,
			shapes: [
				{
					ty: "gr",
					it: [
						{
							d: 1,
							ty: "el",
							s: { a: 0, k: [39.922, 39.922], ix: 2 },
							p: { a: 0, k: [0, 0], ix: 3 },
							nm: "Ellipse Path 1",
							mn: "ADBE Vector Shape - Ellipse",
							hd: false,
						},
						{
							ty: "fl",
							c: {
								a: 0,
								k: [
									0.6078431372549019, 0.6078431372549019, 0.6078431372549019, 1,
								],
								ix: 4,
							},
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: { a: 0, k: [-98.039, 8.961], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Ellipse 1",
					np: 3,
					cix: 2,
					ix: 1,
					mn: "ADBE Vector Group",
					hd: false,
				},
			],
			ip: 0,
			op: 32.0000013033867,
			st: 0,
			bm: 0,
		},
		{
			ddd: 0,
			ind: 4,
			ty: 4,
			nm: "Shape Layer 1",
			sr: 1,
			ks: {
				o: { a: 0, k: 100, ix: 11 },
				r: { a: 0, k: 0, ix: 10 },
				p: {
					a: 1,
					k: [
						{
							i: { x: 0.667, y: 1 },
							o: { x: 0.333, y: 0 },
							n: "0p667_1_0p333_0",
							t: 0,
							s: [157.961, 250.96100000000004, 0],
							e: [157.961, 193.961, 0],
							to: [0, 0, 0],
							ti: [0, 0, 0],
						},
						{
							i: { x: 0.667, y: 1 },
							o: { x: 0.333, y: 0 },
							n: "0p667_1_0p333_0",
							t: 7,
							s: [157.961, 193.961, 0],
							e: [157.961, 250.961, 0],
							to: [0, 0, 0],
							ti: [0, 0, 0],
						},
						{ t: 30.0000012219251 },
					],
					ix: 2,
				},
				a: { a: 0, k: [-98.039, 8.961, 0], ix: 1 },
				s: { a: 0, k: [100, 100, 100], ix: 6 },
			},
			ao: 0,
			shapes: [
				{
					ty: "gr",
					it: [
						{
							d: 1,
							ty: "el",
							s: { a: 0, k: [39.922, 39.922], ix: 2 },
							p: { a: 0, k: [0, 0], ix: 3 },
							nm: "Ellipse Path 1",
							mn: "ADBE Vector Shape - Ellipse",
							hd: false,
						},
						{
							ty: "fl",
							c: {
								a: 0,
								k: [
									0.6078431372549019, 0.6078431372549019, 0.6078431372549019, 1,
								],
								ix: 4,
							},
							o: { a: 0, k: 100, ix: 5 },
							r: 1,
							nm: "Fill 1",
							mn: "ADBE Vector Graphic - Fill",
							hd: false,
						},
						{
							ty: "tr",
							p: { a: 0, k: [-98.039, 8.961], ix: 2 },
							a: { a: 0, k: [0, 0], ix: 1 },
							s: { a: 0, k: [100, 100], ix: 3 },
							r: { a: 0, k: 0, ix: 6 },
							o: { a: 0, k: 100, ix: 7 },
							sk: { a: 0, k: 0, ix: 4 },
							sa: { a: 0, k: 0, ix: 5 },
							nm: "Transform",
						},
					],
					nm: "Ellipse 1",
					np: 3,
					cix: 2,
					ix: 1,
					mn: "ADBE Vector Group",
					hd: false,
				},
			],
			ip: 0,
			op: 32.0000013033867,
			st: 0,
			bm: 0,
		},
	],
	markers: [],
};

export { FailedAnimation, SuccessAnimation, LoadingAnimation };
