import React, { memo, useState, useRef, useEffect, forwardRef } from "react";
import Lottie from "react-lottie";
import "./styles.scss";

import animationData from "../../assets/animations/x-to-check-mark.json";

import Button from "../../components/button/button";
import useDebounce from "../../hooks/useDebounce";
import { cpfMask } from "../../utils";

function Input({
	type,
	placeholder = "",
	id,
	reference,
	value,
	onBlur,
	onChange,
	css,
	required,
	disabled = false,
	radioOptions,
	name,
	onSelect,
	validation,
	options,
	maxLength,
	errorMessage,
	onFocus,
	style,
}) {
	const InputType = {
		Password() {
			return (
				<>
					<div
						id={id}
						className={`inputContainer ${css} ${
							disabled ? "inputDisabled" : ""
						}`}
					>
						<div className="inputPlaceholder">{placeholder}</div>
						<input
							id={id}
							value={value}
							onChange={onChange}
							// onFocus={HandleInputClick}
							// onBlur={HandleInputFocusOut}
							type="password"
							onBlur={onBlur}
							disabled={disabled}
						/>
					</div>
					{errorMessage ? (
						<div className="input-error-description">{errorMessage}</div>
					) : null}
				</>
			);
		},
		Submit() {
			const InputSubmit = React.forwardRef((props, ref) => (
				<div className={`inputSubmitContainer ${css}`}>
					<input
						type="submit"
						value=""
						className="inputSubmit"
						value={placeholder}
						disabled={disabled}
					/>
					<div id="loadingEffectContainer">
						<div className="loader"></div>
					</div>
				</div>
			));

			return <InputSubmit ref={reference} />;
		},
		Radio() {
			const InputRadio = React.forwardRef((props, ref) => (
				<div className="inputRadioContainer ">
					<label htmlFor={name}>{name}</label>
					<div className="inputRadioContent">
						{radioOptions.map((option) => {
							return (
								<>
									<input
										type="radio"
										id={option}
										name={name}
										value={option}
										onChange={onChange}
									/>
									<label htmlFor={option}>{option}</label>
								</>
							);
						})}
					</div>
				</div>
			));

			return <InputRadio ref={reference} />;
		},
		Searchbar() {
			const [searchbarDisplayValue, setSearchbarDisplayValue] = useState("");
			const debounce = useDebounce(onChange, 1000);

			const HandleChangeValue = (element) => {
				setSearchbarDisplayValue(element.target.value);
				debounce(element.target.value);
			};
			return (
				<div className="searchbar_container">
					<div className={`searchbar`}>
						<i class="fa fa-search" aria-hidden="true"></i>
						<input
							id={id}
							onSelect={(element) => onSelect(element)}
							onBlur={onBlur ? (element) => onBlur(element) : null}
							type="text"
							placeholder={placeholder}
							name=""
							value={searchbarDisplayValue}
							onChange={HandleChangeValue}
						/>
					</div>
				</div>
			);
		},
		Text() {
			const [animationOptions, setAnimationOptions] = useState({
				isStopped: true,
				isPaused: true,
			});
			const defaultOptions = {
				loop: false,
				autoplay: false,
				animationData: animationData,
			};

			const onChangeCpfInput = (element) => {
				const maskedCpf = cpfMask(element.target.value);
				onChange(maskedCpf);
			};
			useEffect(() => {
				if (validation == true) {
					setAnimationOptions({
						isStopped: false,
						isPaused: false,
					});
				} else {
					if (animationOptions.isPaused == false) {
						setAnimationOptions((prevState) => ({
							...prevState,
							direction: -1,
						}));
					}
				}
			}, [validation]);

			return (
				<div style={style}>
					<div
						id={id}
						className={`inputContainer ${css} ${
							disabled ? "inputDisabled" : ""
						}`}
					>
						<label
							htmlFor={!id ? placeholder : id}
							className="inputPlaceholder"
						>
							{placeholder}
						</label>
						<input
							maxLength={
								id &&
								(id.toLowerCase() == "cpf" ||
									id.toLowerCase() == "holderDocument")
									? 14
									: maxLength
							}
							id={!id ? placeholder : id}
							name={name}
							value={value}
							onChange={
								id.toLowerCase() == "cpf" ||
								id.toLowerCase() == "holderdocument"
									? (element) => onChangeCpfInput(element)
									: onChange
							}
							type="text"
							onBlur={onBlur}
							required={required ? true : false}
							onFocus={onFocus}
							disabled={disabled}
						/>
						{/* {id.toLowerCase() == "cpf" && validation ? (
							<span className="validation-icon">
								<Lottie
									options={defaultOptions}
									height={50}
									width={50}
									speed={1.7}
									direction={animationOptions.direction}
									isStopped={animationOptions.isStopped}
									isPaused={animationOptions.isPaused}
								/>
							</span>
						) : null} */}
					</div>{" "}
					{errorMessage ? (
						<div className="input-error-description">{errorMessage}</div>
					) : null}
				</div>
			);
		},
		Number() {
			return (
				<>
					<div
						id={id}
						className={`inputContainer ${css} ${
							disabled ? "inputDisabled" : ""
						}`}
					>
						<label
							htmlFor={!id ? placeholder : id}
							className="inputPlaceholder"
						>
							{placeholder}
						</label>
						<input
							maxLength={id.toLowerCase() == "cpf" && 14}
							id={!id ? placeholder : id}
							value={value}
							name={name}
							onChange={onChange}
							onFocus={onFocus}
							// onBlur={HandleInputFocusOut}
							type="number"
							onBlur={onBlur}
							required={required ? true : false}
							disabled={disabled}
						/>
					</div>
				</>
			);
		},
		Email() {
			return (
				<>
					<div
						id={id}
						className={`inputContainer ${css} ${
							disabled ? "inputDisabled" : ""
						}`}
					>
						<label
							htmlFor={!id ? placeholder : id}
							className="inputPlaceholder"
						>
							{placeholder}
						</label>
						<input
							id={!id ? placeholder : id}
							value={value}
							onChange={onChange}
							// onFocus={HandleInputClick}

							// onBlur={HandleInputFocusOut}

							type="email"
							onBlur={onBlur}
							required={required ? true : false}
							disabled={disabled}
						/>
					</div>
				</>
			);
		},
		Phone() {
			return (
				<div style={style}>
					<div
						id={id}
						className={`inputContainer ${css} ${
							disabled ? "inputDisabled" : ""
						}`}
					>
						<label
							htmlFor={!id ? placeholder : id}
							className="inputPlaceholder"
						>
							{placeholder}
						</label>
						<input
							maxLength={11}
							id={!id ? placeholder : id}
							value={value}
							onChange={onChange}
							// onFocus={HandleInputClick}
							// onBlur={HandleInputFocusOut}
							type="number"
							onBlur={onBlur}
							required={required ? true : false}
							disabled={disabled}
						/>
					</div>
				</div>
			);
		},
		Options() {
			return (
				<select required={required ? true : false} onChange={onChange} id={id}>
					<option value="" disabled selected>
						{placeholder}
					</option>

					{options.map((option, key) => (
						<option key={key} id={option.placeholder} value={option.value}>
							{" "}
							{option.placeholder}
						</option>
					))}
				</select>
			);
		},
		Date() {
			return (
				<>
					<div
						id={id}
						className={`inputContainer ${css} ${
							disabled ? "inputDisabled" : ""
						}`}
					>
						<label
							htmlFor={!id ? placeholder : id}
							className="inputPlaceholder"
						>
							{placeholder}
						</label>
						<input
							name={name}
							id={id && id + "input"}
							type={name && name == "expiry" ? "month" : "date"}
							value={value}
							onChange={onChange}
						/>
					</div>{" "}
					{errorMessage ? (
						<div className="input-error-description">{errorMessage}</div>
					) : null}
				</>
			);
		},
		Checkbox() {
			return (
				<div style={style}>
					<div
						id={id}
						className={`inputContainer checkbox ${css} ${
							disabled ? "inputDisabled" : ""
						} ${value ? "active" : ""}`}
					>
						<label
							htmlFor={!id ? placeholder : id}
							className="inputPlaceholder"
						>
							{placeholder}
						</label>
						<input type="checkbox" name={name} id={id} onChange={onChange} />
					</div>
					{errorMessage ? (
						<div className="input-error-description">{errorMessage}</div>
					) : null}
				</div>
			);
		},
	};

	return InputType[type]();
}

export default memo(Input);
