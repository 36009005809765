import React from "react";
import Button from "../../../../components/button/button";

import { Container, ButtonsContainer } from "./styles";

function Confirmation({ accept, reject }) {
	return (
		<Container>
			<h1>Tem certeza que deseja continuar</h1>
			<ButtonsContainer>
				<Button
					placeholder="Não, desejo cancelar"
					type="normal"
					css="exit"
					width="200px"
					on_click={reject}
				/>
				<Button
					placeholder="Sim, desejo continuar"
					type="normal"
					css="normal"
					width="200px"
					on_click={accept}
				/>
			</ButtonsContainer>
		</Container>
	);
}

export { Confirmation };
