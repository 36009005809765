import React from "react";
import "./css/banner.scss";
import Slicker from "../../components/reactSlick";

function Banner({ images, id }) {
	var columns = images.length;

	// O objetivo de ter duas funções para um número par ou impar de imagens está
	// diretamente ligada a responsividade, caso o número de imagens enviadas seja
	// par, a div irá se dividir em linhas que agrupem 2 imagens por linha, caso
	// seja impar, será renderizado um carrossel para melhor visualização no mobile
	const style = {
		//Caso o número de imagens seja par, ele executa essa função
		0() {
			return (
				<>
					<div
						id={id}
						className="banner"
						style={{
							gridTemplateColumns: `repeat(${columns},auto)`,
						}}
					>
						{images.map((image) => (
							<img
								style={{ width: 100 / images.length - 1 + "%" }}
								src={require(`../../assets/banners/${image}`).default}
							/>
						))}
					</div>
				</>
			);
		},
		//Caso o número de imagens seja impar, ele executa essa função
		1() {
			return (
				<Slicker className={id} type="banner">
					{images}
				</Slicker>
			);
		},
		single_image() {
			return (
				<div
					className="banner"
					id={id ? id : images[0].split(".")[0]}
					style={{ gridTemplateColumns: `repeat(${columns},auto)` }}
				>
					<img
						style={{ width: "100%" }}
						src={require(`../../assets/banners/${images}`).default}
					/>
				</div>
			);
		},
	};

	return (
		// Faz o teste, se foi passado somente uma imagem, chama a constante style
		// com a função para renderizar uma imagem, caso seja mais que uma imagem,
		// chamaama a  constante style passando o resto da divisão do tamanho do
		// array de imagems por 2
		images.length > 1 ? style[images.length % 2]() : style["single_image"]()
	);
}

export default Banner;
