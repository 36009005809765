import React, { useEffect, useRef, useState, useContext } from "react";

import DesktopHeader from "./desktopHeader";
import MobileHeader from "./mobileHeader";

const Header = (props) => {
	return (
		<>
			<DesktopHeader />
			<MobileHeader />
		</>
	);
};

export default Header;
