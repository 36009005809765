import { createStore } from "redux";

let today = new Date();
let dateToCartExpire = new Date();
let dateToCartExpireInitialState,
	itemsInitialState = [];
// adiciona 1 dia após a data do dia de hoje na data em que o carrinho irá expirar
dateToCartExpire.setDate(dateToCartExpire.getDate() + 1);

// Transformar as variaveis "today" e "dateToCartExpire" em timestamp
today = Date.parse(today);
dateToCartExpire = Date.parse(dateToCartExpire);

// Verificando se existe informações do carrinho no localStorate
// se existir, armazena os itens e a data para expirar o carrinho no
// initial state da redux store
if (
	localStorage.getItem("requestInfo") &&
	JSON.parse(localStorage.getItem("requestInfo")).dateToCartExpire > today
) {
	dateToCartExpireInitialState = JSON.parse(
		localStorage.getItem("requestInfo")
	).dateToCartExpire;
	itemsInitialState = JSON.parse(localStorage.getItem("requestInfo")).items;
}

const INITIAL_STATE = {
	delivery: true,
	periodOfDayToDelivery: null,
	expires: true,
	dateToCartExpire: dateToCartExpireInitialState,
	paymentMethod: null,
	creditCard: {
		cardNumber: 5309999999999999,
		cardOwnerName: "Hebe Camargo",
		cardOwnerCPF: "11122233344",
		cardValidity: "08/23",
		cardCVV: 123,
		cardType: "Cartão de Crédito",
	},
	items: itemsInitialState,
	userInfo: {
		name: "Cliente Teste",

		paymentMethods: [
			{
				cardNumber: 5309999999999999,
				cardOwnerName: "Hebe Camargo",
				cardOwnerCPF: "11122233344",
				cardValidity: "08/23",
				cardCVV: 123,
				cardType: "Cartão de Crédito",
			},
			{
				cardNumber: 4410888888888888,
				cardOwnerName: "Jô Soares",
				cardOwnerCPF: "55566677788",
				cardValidity: "12/20",
				cardCVV: 456,
				cardType: "Cartão de Débito",
			},
			{
				cardNumber: 6362971234567890,
				cardOwnerName: "Sidney Magal",
				cardOwnerCPF: "99900011122",
				cardValidity: "01/23",
				cardCVV: 789,
				cardType: "Vale Alimentação",
			},
		],
	},
	addressToDelivery: {
		publicArea: "Rua",
		address: "Iapo",
		district: "Rebouças",
		number: "371",
		city: "Curitiba",
		state: "PR",
	},
};

function reducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case "ADD_ITEM_TO_CART":
			var itemIndex;

			state.items.forEach((item, index) => {
				if (item.code == action.product.code) {
					itemIndex = index;
				}
			});
			if (itemIndex === undefined) {
				action.product.quantity = 1;
				localStorage.setItem(
					"requestInfo",
					JSON.stringify({
						dateToCartExpire,
						items: [...state.items, action.product],
					})
				);
				return { ...state, items: [...state.items, action.product] };
			} else {
				localStorage.setItem(
					"requestInfo",
					JSON.stringify({
						dateToCartExpire,
						items: state.items.map((item, index) =>
							index == itemIndex
								? {
										...item,
										quantity: item.quantity + 1,
								  }
								: item
						),
					})
				);

				return {
					...state,
					items: state.items.map((item, index) =>
						index == itemIndex
							? {
									...item,
									quantity: item.quantity + 1,
							  }
							: item
					),
				};
			}
		case "REMOVE_ITEM_TO_CART":
			state.items.forEach((item, index) => {
				if (item.code == action.product.code) {
					itemIndex = index;
				}
			});

			if (state.items[itemIndex].quantity == 1) {
				let first_part = state.items.slice(0, itemIndex);
				let second_part = state.items.slice(itemIndex + 1);

				if (state.items.length == 1 && state.items[0].quantity == 1) {
					localStorage.removeItem("requestInfo");
				} else {
					localStorage.setItem(
						"requestInfo",
						JSON.stringify({
							dateToCartExpire,
							items: first_part.concat(second_part),
						})
					);
				}
				return { ...state, items: first_part.concat(second_part) };
			} else {
				localStorage.setItem(
					"requestInfo",
					JSON.stringify({
						dateToCartExpire,
						items: state.items.map((item, index) =>
							index == itemIndex
								? { ...item, quantity: item.quantity - 1 }
								: item
						),
					})
				);
				return {
					...state,
					items: state.items.map((item, index) =>
						index == itemIndex ? { ...item, quantity: item.quantity - 1 } : item
					),
				};
			}
		case "SET_PERIOD_OF_DAY_TO_DELIVERY":
			return {
				...state,
				periodOfDaYToDelivery: action.period,
				delivery: true,
			};
		case "SET_DELIVERY":
			return {
				...state,
				delivery: action.delivery,
			};

		case "SET_PAYMENT_METHOD_CREDIT_CARD":
			return {
				...state,
				creditCard: state.userInfo.paymentMethods[action.cardSelected],
			};
		case "SET_PAYMENT_METHOD":
			console.log(action);
			return { ...state, paymentMethod: action.paymentMethod };
		case "ADD_NEW_ADDRESS":
			return {
				...state,
				userInfo: {
					name: state.userInfo.name,
					addresses: [...state.userInfo.addresses, action.addressToAdd],
				},
			};
		case "SET_ADDRESS_TO_DELIVERY":
			console.log({
				...state,
				addressToDelivery: state.userInfo.addresses[action.addressToDelivery],
			});
			return {
				...state,
				addressToDelivery: state.userInfo.addresses[action.addressToDelivery],
			};
		case "SET_USER_INFO":
			console.log(action);
			return { ...state, userInfo: action.userInfo };
		case "CLEAR":
			return {
				delivery: false,
				periodOfDayToDelivery: null,
				expires: true,
				dateToCartExpire: dateToCartExpireInitialState,
				paymentMethod: null,
				creditCard: null,
				items: itemsInitialState,
				userInfo: {
					name: "Cliente Teste",
					addresses: [
						{
							publicArea: "Rua",
							address: "Luiza Verone Piccoli",
							district: "Tingui",
							city: "Curitiba",
							state: "PR",
							complement: "Casa",
							number: 200,
							default: true,
						},
					],
					paymentMethods: [
						{
							cardNumber: 5309999999999999,
							cardOwnerName: "Hebe Camargo",
							cardOwnerCPF: "11122233344",
							cardValidity: "08/23",
							cardCVV: 123,
							cardType: "Cartão de Crédito",
						},
						{
							cardNumber: 4410888888888888,
							cardOwnerName: "Jô Soares",
							cardOwnerCPF: "55566677788",
							cardValidity: "12/20",
							cardCVV: 456,
							cardType: "Cartão de Débito",
						},
						{
							cardNumber: 6362971234567890,
							cardOwnerName: "Sidney Magal",
							cardOwnerCPF: "99900011122",
							cardValidity: "01/23",
							cardCVV: 789,
							cardType: "Vale Alimentação",
						},
					],
				},
			};
		default:
			return state;
	}
}

const store = createStore(reducer);
export default store;
