import React from "react";
import { Link } from "react-router-dom";

// Assets
import { ReactComponent as Logo } from "../../../../assets/icons/client_logo.svg";

export default function CleanHeader() {
	return (
		<header id="cleanHeader">
			<div className="menu">
				<div className="itemsContainer">
					<Link to="/" className="logo_container">
						{/* <img
							src={
								require("../../../../assets/icons/client_logo.png")
									.default
							}
							alt=""
							style={{
								width: "120px",
							}}
							srcset=""
						/> */}
						<Logo />
					</Link>
				</div>
			</div>
		</header>
	);
}
