import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Content, OrdersList } from "./styles.js";

// Components
import Page from "../../../components/page";
import { Order } from "./order";

import { useAuth } from "../../../hooks/useAuth.js";

export function Orders() {
	const { loggedUser } = useAuth();
	const history = useHistory();

	const orders = [
		{
			date_delivery: "1999-01-01 08:00:00",
			token: "",
			type_delivery: "E",
			address_code: 0,
			payment_method: 0,
			change: 10,
			items: [
				{
					product: 0,
					quantity: 0,
					price_unit: 0,
					option_code: 0,
					comments: "",
				},
				{
					product: 0,
					quantity: 0,
					price_unit: 0,
					option_code: 0,
					comments: "",
				},
			],
		},
	];

	useEffect(() => {
		if (!loggedUser) {
			history.push("/");
		}
	}, []);

	return (
		<Page page_id="ordersPage">
			<Content>
				<h1>Meus pedidos</h1>

				<OrdersList>
					{orders.map((info, key) => (
						<Order info={info} key={key} />
					))}
				</OrdersList>
			</Content>
		</Page>
	);
}
