import styled from "styled-components";

export const Container = styled.div`
	width: 450px;
	height: 150px;

	h1 {
		width: 80%;
		margin: 0 auto;
		text-align: center;
	}
`;

export const ButtonsContainer = styled.div`
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: space-between;

	align-items: center;
`;
