import styled from "styled-components";

export const Container = styled.article`
	max-height: 90vh;
	width: 500px;
	overflow: hidden;
`;

export const Header = styled.header`
	h2 {
		text-align: center;
		font-weight: 600;
		font-size: 24px;
	}

	margin-bottom: 10px;
`;

export const RegisterContainer = styled.form`
	flex: 1;

	.create-account {
		margin: 10px 0;
		text-align: center;
		span {
			color: #2662f0;
			cursor: pointer;
		}
	}
`;

export const InputContainer = styled.div`
	max-height: 70vh;
	overflow: auto;

	div.cpfrgContainer {
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
	}

	div.passwordContainer {
		display: flex;
		justify-content: space-between;
	}

	select {
		width: 100%;
		margin: 10px 0;
	}
`;
