// Objetos usados para auxílio no desenvolvimento
// não tendo utilidade alguma no ambiente de produção

// Objects used for support at development,
// doesn't have any utility at the production ambient

export const products_backend = [
	{
		id: 111,
		quantity: 1,
		price_unit: 7.9,
		option_code: 0,
		comments: "",
	},
	{
		id: 112,
		quantity: 2,
		price_unit: 6.8,
		option_code: 0,
		comments: "",
	},
];

export const cards = [
	{
		cardNumber: 5309999999999999,
		cardOwnerName: "Hebe Camargo",
		cardOwnerCPF: "11122233344",
		cardValidity: "08/23",
		cardCVV: 123,
		cardType: "Cartão de Crédito",
	},
	{
		cardNumber: 4410888888888888,
		cardOwnerName: "Jô Soares",
		cardOwnerCPF: "55566677788",
		cardValidity: "12/20",
		cardCVV: 456,
		cardType: "Cartão de Débito",
	},
	{
		cardNumber: 6362971234567890,
		cardOwnerName: "Sidney Magal",
		cardOwnerCPF: "99900011122",
		cardValidity: "01/23",
		cardCVV: 789,
		cardType: "Vale Alimentação",
	},
];

export const requests = [
	{
		id: 500,
		data: 1603117858,
		itens: [
			{
				id: 111,
				name: "Refrigerante Coca-Cola",
				complement: "2 litros",
				promo_price: 5.9,
				price: 7.9,
				image_name: "coca2l.png",
				category: "Refri",
				quantity: 1,
			},
			{
				id: 189,
				name: "Mini Whisky",
				complement: "Jack Daniels 50ml",
				promo_price: 30.99,
				price: 7.9,
				image_name: "jackdaniels50ml.jpg",
				category: "Bebidas Alcoolicas",
				quantity: 1,
			},
			{
				id: 190,
				name: "Limão Taiti",
				complement: "100g",
				promo_price: 0.99,
				price: 7.9,
				image_name: "limaotaiti.png",
				category: "Bebidas Alcoolicas",
				quantity: 1,
			},
		],
		adress: "Rua Luiza Verone Piccoli - Tingui",
		payment: 5309999999999999,
		note: 4,
		comment: "A entrega foi muito rápida, satisfeito com a compra.",
		total: 37.88,
	},
	{
		id: 501,
		data: 1601560530,
		itens: [
			{
				id: 117,
				name: "Creme de Leite Piracanjuba",
				complement: "Zero Lactose",
				promo_price: 8.75,
				price: 9.9,
				image_name: "cremeDeLeiteZeroPiracanjuba.png",
				category: "Creme de Leite",
				quantity: 2,
			},
			{
				id: 191,
				name: "Bisteca Bovina",
				complement: "Sem osso",
				promo_price: 22.99,
				price: 25.9,
				image_name: "bistecaBovina.png",
				category: "Carnes",
				quantity: 3,
			},
		],
		adress: "Rua Iapó - Rebouças",
		payment: 6362971234567890,
		note: 4,
		comment:
			"Fui pela primeira vez nesse Hipermercado da avenida Marechal Floriano Peixoto e gostei bastante,achei bem organizado e limpo, com grande variedade de produtos.Os preços são similares aos outros grandes supermercados, mas encontrei algumas promoções bem boas. Recomendo! ",
		total: 86.47,
	},
	{
		id: 500,
		data: 1603117858,
		itens: [
			{
				id: 111,
				name: "Refrigerante Coca-Cola",
				complement: "2 litros",
				promo_price: 5.9,
				price: 7.9,
				image_name: "coca2l.png",
				category: "Refri",
				quantity: 1,
			},
			{
				id: 189,
				name: "Mini Whisky",
				complement: "Jack Daniels 50ml",
				promo_price: 30.99,
				price: 7.9,
				image_name: "jackdaniels50ml.jpg",
				category: "Bebidas Alcoolicas",
				quantity: 1,
			},
			{
				id: 190,
				name: "Limão Taiti",
				complement: "100g",
				promo_price: 0.99,
				price: 7.9,
				image_name: "limaotaiti.png",
				category: "Bebidas Alcoolicas",
				quantity: 1,
			},
		],
		adress: "Rua Luiza Verone Piccoli - Tingui",
		payment: 5309999999999999,
		note: 4,
		comment: "A entrega foi muito rápida, satisfeito com a compra.",
		total: 37.88,
	},
	{
		id: 501,
		data: 1601560530,
		itens: [
			{
				id: 117,
				name: "Creme de Leite Piracanjuba",
				complement: "Zero Lactose",
				promo_price: 8.75,
				price: 9.9,
				image_name: "cremeDeLeiteZeroPiracanjuba.png",
				category: "Creme de Leite",
				quantity: 2,
			},
			{
				id: 191,
				name: "Bisteca Bovina",
				complement: "Sem osso",
				promo_price: 22.99,
				price: 25.9,
				image_name: "bistecaBovina.png",
				category: "Carnes",
				quantity: 3,
			},
		],
		adress: "Rua Iapó - Rebouças",
		payment: 6362971234567890,
		note: 4,
		comment:
			"Fui pela primeira vez nesse Hipermercado da avenida Marechal Floriano Peixoto e gostei bastante,achei bem organizado e limpo, com grande variedade de produtos.Os preços são similares aos outros grandes supermercados, mas encontrei algumas promoções bem boas. Recomendo! ",
		total: 86.47,
	},
	{
		id: 500,
		data: 1603117858,
		itens: [
			{
				id: 111,
				name: "Refrigerante Coca-Cola",
				complement: "2 litros",
				promo_price: 5.9,
				price: 7.9,
				image_name: "coca2l.png",
				category: "Refri",
				quantity: 1,
			},
			{
				id: 189,
				name: "Mini Whisky",
				complement: "Jack Daniels 50ml",
				promo_price: 30.99,
				price: 7.9,
				image_name: "jackdaniels50ml.jpg",
				category: "Bebidas Alcoolicas",
				quantity: 1,
			},
			{
				id: 190,
				name: "Limão Taiti",
				complement: "100g",
				promo_price: 0.99,
				price: 7.9,
				image_name: "limaotaiti.png",
				category: "Bebidas Alcoolicas",
				quantity: 1,
			},
		],
		adress: "Rua Luiza Verone Piccoli - Tingui",
		payment: 5309999999999999,
		note: 4,
		comment: "A entrega foi muito rápida, satisfeito com a compra.",
		total: 37.88,
	},
	{
		id: 501,
		data: 1601560530,
		itens: [
			{
				id: 117,
				name: "Creme de Leite Piracanjuba",
				complement: "Zero Lactose",
				promo_price: 8.75,
				price: 9.9,
				image_name: "cremeDeLeiteZeroPiracanjuba.png",
				category: "Creme de Leite",
				quantity: 2,
			},
			{
				id: 191,
				name: "Bisteca Bovina",
				complement: "Sem osso",
				promo_price: 22.99,
				price: 25.9,
				image_name: "bistecaBovina.png",
				category: "Carnes",
				quantity: 3,
			},
		],
		adress: "Rua Iapó - Rebouças",
		payment: 6362971234567890,
		note: 4,
		comment:
			"Fui pela primeira vez nesse Hipermercado da avenida Marechal Floriano Peixoto e gostei bastante,achei bem organizado e limpo, com grande variedade de produtos.Os preços são similares aos outros grandes supermercados, mas encontrei algumas promoções bem boas. Recomendo! ",
		total: 86.47,
	},
	{
		id: 500,
		data: 1603117858,
		itens: [
			{
				id: 111,
				name: "Refrigerante Coca-Cola",
				complement: "2 litros",
				promo_price: 5.9,
				price: 7.9,
				image_name: "coca2l.png",
				category: "Refri",
				quantity: 1,
			},
			{
				id: 189,
				name: "Mini Whisky",
				complement: "Jack Daniels 50ml",
				promo_price: 30.99,
				price: 7.9,
				image_name: "jackdaniels50ml.jpg",
				category: "Bebidas Alcoolicas",
				quantity: 1,
			},
			{
				id: 190,
				name: "Limão Taiti",
				complement: "100g",
				promo_price: 0.99,
				price: 7.9,
				image_name: "limaotaiti.png",
				category: "Bebidas Alcoolicas",
				quantity: 1,
			},
		],
		adress: "Rua Luiza Verone Piccoli - Tingui",
		payment: 5309999999999999,
		note: 4,
		comment: "A entrega foi muito rápida, satisfeito com a compra.",
		total: 37.88,
	},
	{
		id: 501,
		data: 1601560530,
		itens: [
			{
				id: 117,
				name: "Creme de Leite Piracanjuba",
				complement: "Zero Lactose",
				promo_price: 8.75,
				price: 9.9,
				image_name: "cremeDeLeiteZeroPiracanjuba.png",
				category: "Creme de Leite",
				quantity: 2,
			},
			{
				id: 191,
				name: "Bisteca Bovina",
				complement: "Sem osso",
				promo_price: 22.99,
				price: 25.9,
				image_name: "bistecaBovina.png",
				category: "Carnes",
				quantity: 3,
			},
		],
		adress: "Rua Iapó - Rebouças",
		payment: 6362971234567890,
		note: 4,
		comment:
			"Fui pela primeira vez nesse Hipermercado da avenida Marechal Floriano Peixoto e gostei bastante,achei bem organizado e limpo, com grande variedade de produtos.Os preços são similares aos outros grandes supermercados, mas encontrei algumas promoções bem boas. Recomendo! ",
		total: 86.47,
	},
	{
		id: 500,
		data: 1603117858,
		itens: [
			{
				id: 111,
				name: "Refrigerante Coca-Cola",
				complement: "2 litros",
				promo_price: 5.9,
				price: 7.9,
				image_name: "coca2l.png",
				category: "Refri",
				quantity: 1,
			},
			{
				id: 189,
				name: "Mini Whisky",
				complement: "Jack Daniels 50ml",
				promo_price: 30.99,
				price: 7.9,
				image_name: "jackdaniels50ml.jpg",
				category: "Bebidas Alcoolicas",
				quantity: 1,
			},
			{
				id: 190,
				name: "Limão Taiti",
				complement: "100g",
				promo_price: 0.99,
				price: 7.9,
				image_name: "limaotaiti.png",
				category: "Bebidas Alcoolicas",
				quantity: 1,
			},
		],
		adress: "Rua Luiza Verone Piccoli - Tingui",
		payment: 5309999999999999,
		note: 4,
		comment: "A entrega foi muito rápida, satisfeito com a compra.",
		total: 37.88,
	},
	{
		id: 501,
		data: 1601560530,
		itens: [
			{
				id: 117,
				name: "Creme de Leite Piracanjuba",
				complement: "Zero Lactose",
				promo_price: 8.75,
				price: 9.9,
				image_name: "cremeDeLeiteZeroPiracanjuba.png",
				category: "Creme de Leite",
				quantity: 2,
			},
			{
				id: 191,
				name: "Bisteca Bovina",
				complement: "Sem osso",
				promo_price: 22.99,
				price: 25.9,
				image_name: "bistecaBovina.png",
				category: "Carnes",
				quantity: 3,
			},
		],
		adress: "Rua Iapó - Rebouças",
		payment: 6362971234567890,
		note: 4,
		comment:
			"Fui pela primeira vez nesse Hipermercado da avenida Marechal Floriano Peixoto e gostei bastante,achei bem organizado e limpo, com grande variedade de produtos.Os preços são similares aos outros grandes supermercados, mas encontrei algumas promoções bem boas. Recomendo! ",
		total: 86.47,
	},
	{
		id: 500,
		data: 1603117858,
		itens: [
			{
				id: 111,
				name: "Refrigerante Coca-Cola",
				complement: "2 litros",
				promo_price: 5.9,
				price: 7.9,
				image_name: "coca2l.png",
				category: "Refri",
				quantity: 1,
			},
			{
				id: 189,
				name: "Mini Whisky",
				complement: "Jack Daniels 50ml",
				promo_price: 30.99,
				price: 7.9,
				image_name: "jackdaniels50ml.jpg",
				category: "Bebidas Alcoolicas",
				quantity: 1,
			},
			{
				id: 190,
				name: "Limão Taiti",
				complement: "100g",
				promo_price: 0.99,
				price: 7.9,
				image_name: "limaotaiti.png",
				category: "Bebidas Alcoolicas",
				quantity: 1,
			},
		],
		adress: "Rua Luiza Verone Piccoli - Tingui",
		payment: 5309999999999999,
		note: 4,
		comment: "A entrega foi muito rápida, satisfeito com a compra.",
		total: 37.88,
	},
	{
		id: 501,
		data: 1601560530,
		itens: [
			{
				id: 117,
				name: "Creme de Leite Piracanjuba",
				complement: "Zero Lactose",
				promo_price: 8.75,
				price: 9.9,
				image_name: "cremeDeLeiteZeroPiracanjuba.png",
				category: "Creme de Leite",
				quantity: 2,
			},
			{
				id: 191,
				name: "Bisteca Bovina",
				complement: "Sem osso",
				promo_price: 22.99,
				price: 25.9,
				image_name: "bistecaBovina.png",
				category: "Carnes",
				quantity: 3,
			},
		],
		adress: "Rua Iapó - Rebouças",
		payment: 6362971234567890,
		note: 4,
		comment:
			"Fui pela primeira vez nesse Hipermercado da avenida Marechal Floriano Peixoto e gostei bastante,achei bem organizado e limpo, com grande variedade de produtos.Os preços são similares aos outros grandes supermercados, mas encontrei algumas promoções bem boas. Recomendo! ",
		total: 86.47,
	},
	{
		id: 500,
		data: 1603117858,
		itens: [
			{
				id: 111,
				name: "Refrigerante Coca-Cola",
				complement: "2 litros",
				promo_price: 5.9,
				price: 7.9,
				image_name: "coca2l.png",
				category: "Refri",
				quantity: 1,
			},
			{
				id: 189,
				name: "Mini Whisky",
				complement: "Jack Daniels 50ml",
				promo_price: 30.99,
				price: 7.9,
				image_name: "jackdaniels50ml.jpg",
				category: "Bebidas Alcoolicas",
				quantity: 1,
			},
			{
				id: 190,
				name: "Limão Taiti",
				complement: "100g",
				promo_price: 0.99,
				price: 7.9,
				image_name: "limaotaiti.png",
				category: "Bebidas Alcoolicas",
				quantity: 1,
			},
		],
		adress: "Rua Luiza Verone Piccoli - Tingui",
		payment: 5309999999999999,
		note: 4,
		comment: "A entrega foi muito rápida, satisfeito com a compra.",
		total: 37.88,
	},
	{
		id: 501,
		data: 1601560530,
		itens: [
			{
				id: 117,
				name: "Creme de Leite Piracanjuba",
				complement: "Zero Lactose",
				promo_price: 8.75,
				price: 9.9,
				image_name: "cremeDeLeiteZeroPiracanjuba.png",
				category: "Creme de Leite",
				quantity: 2,
			},
			{
				id: 191,
				name: "Bisteca Bovina",
				complement: "Sem osso",
				promo_price: 22.99,
				price: 25.9,
				image_name: "bistecaBovina.png",
				category: "Carnes",
				quantity: 3,
			},
		],
		adress: "Rua Iapó - Rebouças",
		payment: 6362971234567890,
		note: 4,
		comment:
			"Fui pela primeira vez nesse Hipermercado da avenida Marechal Floriano Peixoto e gostei bastante,achei bem organizado e limpo, com grande variedade de produtos.Os preços são similares aos outros grandes supermercados, mas encontrei algumas promoções bem boas. Recomendo! ",
		total: 86.47,
	},
	{
		id: 500,
		data: 1603117858,
		itens: [
			{
				id: 111,
				name: "Refrigerante Coca-Cola",
				complement: "2 litros",
				promo_price: 5.9,
				price: 7.9,
				image_name: "coca2l.png",
				category: "Refri",
				quantity: 1,
			},
			{
				id: 189,
				name: "Mini Whisky",
				complement: "Jack Daniels 50ml",
				promo_price: 30.99,
				price: 7.9,
				image_name: "jackdaniels50ml.jpg",
				category: "Bebidas Alcoolicas",
				quantity: 1,
			},
			{
				id: 190,
				name: "Limão Taiti",
				complement: "100g",
				promo_price: 0.99,
				price: 7.9,
				image_name: "limaotaiti.png",
				category: "Bebidas Alcoolicas",
				quantity: 1,
			},
		],
		adress: "Rua Luiza Verone Piccoli - Tingui",
		payment: 5309999999999999,
		note: 4,
		comment: "A entrega foi muito rápida, satisfeito com a compra.",
		total: 37.88,
	},
	{
		id: 501,
		data: 1601560530,
		itens: [
			{
				id: 117,
				name: "Creme de Leite Piracanjuba",
				complement: "Zero Lactose",
				promo_price: 8.75,
				price: 9.9,
				image_name: "cremeDeLeiteZeroPiracanjuba.png",
				category: "Creme de Leite",
				quantity: 2,
			},
			{
				id: 191,
				name: "Bisteca Bovina",
				complement: "Sem osso",
				promo_price: 22.99,
				price: 25.9,
				image_name: "bistecaBovina.png",
				category: "Carnes",
				quantity: 3,
			},
		],
		adress: "Rua Iapó - Rebouças",
		payment: 6362971234567890,
		note: 4,
		comment:
			"Fui pela primeira vez nesse Hipermercado da avenida Marechal Floriano Peixoto e gostei bastante,achei bem organizado e limpo, com grande variedade de produtos.Os preços são similares aos outros grandes supermercados, mas encontrei algumas promoções bem boas. Recomendo! ",
		total: 86.47,
	},
	{
		id: 500,
		data: 1603117858,
		itens: [
			{
				id: 111,
				name: "Refrigerante Coca-Cola",
				complement: "2 litros",
				promo_price: 5.9,
				price: 7.9,
				image_name: "coca2l.png",
				category: "Refri",
				quantity: 1,
			},
			{
				id: 189,
				name: "Mini Whisky",
				complement: "Jack Daniels 50ml",
				promo_price: 30.99,
				price: 7.9,
				image_name: "jackdaniels50ml.jpg",
				category: "Bebidas Alcoolicas",
				quantity: 1,
			},
			{
				id: 190,
				name: "Limão Taiti",
				complement: "100g",
				promo_price: 0.99,
				price: 7.9,
				image_name: "limaotaiti.png",
				category: "Bebidas Alcoolicas",
				quantity: 1,
			},
		],
		adress: "Rua Luiza Verone Piccoli - Tingui",
		payment: 5309999999999999,
		note: 4,
		comment: "A entrega foi muito rápida, satisfeito com a compra.",
		total: 37.88,
	},
	{
		id: 501,
		data: 1601560530,
		itens: [
			{
				id: 117,
				name: "Creme de Leite Piracanjuba",
				complement: "Zero Lactose",
				promo_price: 8.75,
				price: 9.9,
				image_name: "cremeDeLeiteZeroPiracanjuba.png",
				category: "Creme de Leite",
				quantity: 2,
			},
			{
				id: 191,
				name: "Bisteca Bovina",
				complement: "Sem osso",
				promo_price: 22.99,
				price: 25.9,
				image_name: "bistecaBovina.png",
				category: "Carnes",
				quantity: 3,
			},
		],
		adress: "Rua Iapó - Rebouças",
		payment: 6362971234567890,
		note: 4,
		comment:
			"Fui pela primeira vez nesse Hipermercado da avenida Marechal Floriano Peixoto e gostei bastante,achei bem organizado e limpo, com grande variedade de produtos.Os preços são similares aos outros grandes supermercados, mas encontrei algumas promoções bem boas. Recomendo! ",
		total: 86.47,
	},
	{
		id: 500,
		data: 1603117858,
		itens: [
			{
				id: 111,
				name: "Refrigerante Coca-Cola",
				complement: "2 litros",
				promo_price: 5.9,
				price: 7.9,
				image_name: "coca2l.png",
				category: "Refri",
				quantity: 1,
			},
			{
				id: 189,
				name: "Mini Whisky",
				complement: "Jack Daniels 50ml",
				promo_price: 30.99,
				price: 7.9,
				image_name: "jackdaniels50ml.jpg",
				category: "Bebidas Alcoolicas",
				quantity: 1,
			},
			{
				id: 190,
				name: "Limão Taiti",
				complement: "100g",
				promo_price: 0.99,
				price: 7.9,
				image_name: "limaotaiti.png",
				category: "Bebidas Alcoolicas",
				quantity: 1,
			},
		],
		adress: "Rua Luiza Verone Piccoli - Tingui",
		payment: 5309999999999999,
		note: 4,
		comment: "A entrega foi muito rápida, satisfeito com a compra.",
		total: 37.88,
	},
	{
		id: 501,
		data: 1601560530,
		itens: [
			{
				id: 117,
				name: "Creme de Leite Piracanjuba",
				complement: "Zero Lactose",
				promo_price: 8.75,
				price: 9.9,
				image_name: "cremeDeLeiteZeroPiracanjuba.png",
				category: "Creme de Leite",
				quantity: 2,
			},
			{
				id: 191,
				name: "Bisteca Bovina",
				complement: "Sem osso",
				promo_price: 22.99,
				price: 25.9,
				image_name: "bistecaBovina.png",
				category: "Carnes",
				quantity: 3,
			},
		],
		adress: "Rua Iapó - Rebouças",
		payment: 6362971234567890,
		note: 4,
		comment:
			"Fui pela primeira vez nesse Hipermercado da avenida Marechal Floriano Peixoto e gostei bastante,achei bem organizado e limpo, com grande variedade de produtos.Os preços são similares aos outros grandes supermercados, mas encontrei algumas promoções bem boas. Recomendo! ",
		total: 86.47,
	},
	{
		id: 500,
		data: 1603117858,
		itens: [
			{
				id: 111,
				name: "Refrigerante Coca-Cola",
				complement: "2 litros",
				promo_price: 5.9,
				price: 7.9,
				image_name: "coca2l.png",
				category: "Refri",
				quantity: 1,
			},
			{
				id: 189,
				name: "Mini Whisky",
				complement: "Jack Daniels 50ml",
				promo_price: 30.99,
				price: 7.9,
				image_name: "jackdaniels50ml.jpg",
				category: "Bebidas Alcoolicas",
				quantity: 1,
			},
			{
				id: 190,
				name: "Limão Taiti",
				complement: "100g",
				promo_price: 0.99,
				price: 7.9,
				image_name: "limaotaiti.png",
				category: "Bebidas Alcoolicas",
				quantity: 1,
			},
		],
		adress: "Rua Luiza Verone Piccoli - Tingui",
		payment: 5309999999999999,
		note: 4,
		comment: "A entrega foi muito rápida, satisfeito com a compra.",
		total: 37.88,
	},
	{
		id: 501,
		data: 1601560530,
		itens: [
			{
				id: 117,
				name: "Creme de Leite Piracanjuba",
				complement: "Zero Lactose",
				promo_price: 8.75,
				price: 9.9,
				image_name: "cremeDeLeiteZeroPiracanjuba.png",
				category: "Creme de Leite",
				quantity: 2,
			},
			{
				id: 191,
				name: "Bisteca Bovina",
				complement: "Sem osso",
				promo_price: 22.99,
				price: 25.9,
				image_name: "bistecaBovina.png",
				category: "Carnes",
				quantity: 3,
			},
		],
		adress: "Rua Iapó - Rebouças",
		payment: 6362971234567890,
		note: 4,
		comment:
			"Fui pela primeira vez nesse Hipermercado da avenida Marechal Floriano Peixoto e gostei bastante,achei bem organizado e limpo, com grande variedade de produtos.Os preços são similares aos outros grandes supermercados, mas encontrei algumas promoções bem boas. Recomendo! ",
		total: 86.47,
	},
];

export const market_info = {
	social_media: [
		"https://www.instagram.com/rpinfosistemas/",
		"https://www.facebook.com/rpinfosistemas/",
		"https://www.linkedin.com/company/rpinfosistemas/",
	],
	slides_images: ["aproveita40off.jpg", "quinzenadocliente.jpg"],
};

export const banner_topo = ["banner_top.png"];

export const banner_descontos = [
	"desconto_linha.png",
	"festival_pet.png",
	"futebol.png",
];

export const banner_geladeira = [
	"consul.png",
	"electrolux.png",
	"consul.png",
	"electrolux.png",
	"consul.png",
];

export const black_friday = ["black_friday_tech.png", "black_friday_tech.png"];

export const recipes = [
	{
		name: "Strogonoff de berinjela",
		time: "20 min",
		difficulty: "Fácil",
		imageName: "strogonoffberinjela.jpg",
	},
	{
		name: "Burrata de limão siciliano",
		time: "15 min",
		difficulty: "Médio",
		imageName: "burratadelimao.jpg",
	},
	{
		name: "Sopa de Agrião",
		time: "20 min",
		difficulty: "Fácil",
		imageName: "sopaagriao.jpg",
	},
	{
		name: "Crepes de espinafre com acelga",
		time: "20 min",
		difficulty: "Fácil",
		imageName: "crepeespinafre.jpg",
	},
	{
		name: "Strogonoff de berinjela",
		time: "20 min",
		difficulty: "Fácil",
		imageName: "strogonoffberinjela.jpg",
	},
];

export const category_cards = [
	{
		name: "Promoção",
		image: "promocao.jpg",
	},
	{
		name: "Feira",
		image: "feira.png",
	},
	{
		name: "Padaria",
		image: "padaria.png",
	},
	{
		name: "Açougue",
		image: "acougue.png",
	},
	{
		name: "Limpeza",
		image: "limpeza.png",
	},
	{
		name: "Alimentos Básicos",
		image: "alimentosBasicos.png",
	},
	{
		name: "Café da manhã",
		image: "cafeManha.png",
	},
	{
		name: "Bebidas alcoólicas",
		image: "bebidaAlcoolica.png",
	},
	{
		name: "Higiene Pessoal",
		image: "higiene.png",
	},
];
