import React, { useState } from "react";

// set the defaults
export const ModalContext = React.createContext({});

export function ModalContextProvider({ children }) {
	const [modalState, setModalState] = useState({
		modalOpened: false,
		openedModal: null,
	});

	return (
		<ModalContext.Provider value={{ modalState, setModalState }}>
			{children}
		</ModalContext.Provider>
	);
}
