import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import { Content, PaymentList, SectionContainer } from "./styles.js";
import api from "../../../services/api";

// Components
import Page from "../../../components/page";
import { PaymentMethod } from "./paymentMethod/index";
import Button from "../../../components/button/button";

import { useAuth, useModal } from "../../../hooks";

export function PaymentMethods(props) {
	const [cards, setCards] = useState([]);
	const { loggedUser } = useAuth();
	const { changeModalParams, openModal, changeCustomStyles } = useModal();
	const history = useHistory();

	useEffect(() => {
		if (!loggedUser) {
			history.push("/");
		}
		api.get(`/front/cards/${loggedUser.userToken}`).then((response) => {
			setCards(response.data.cards);
		});
	}, []);

	const HandleAddNewPaymentMethodClick = () => {
		changeModalParams({
			type: "addNewCard",
			content: {},
			canClose: true,
		});
		changeCustomStyles(
			changeCustomStyles((prevState) => ({
				...prevState,
				overlay: {
					...prevState.overlay,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				},
				content: {
					borderRadius: "8px",
					inset: "unset",
				},
			}))
		);
		openModal();
	};

	return (
		<Page page_id="ordersPage">
			<Content>
				<SectionContainer>
					<h1>Métodos de pagamento</h1>
					<Button
						type="normal"
						css="normal"
						placeholder="Adicionar novo"
						width="342px"
						on_click={() => HandleAddNewPaymentMethodClick()}
					/>
				</SectionContainer>

				<PaymentList>
					{cards.map((card) => (
						<PaymentMethod card={card} />
					))}
				</PaymentList>
			</Content>
		</Page>
	);
}
