export function HandleHoverAddToCart(element,action){
   const actions = {
      HandleHoverAddToCart(){
         element.classList.add('HandleHoverAddToCart')
      },
      mouseleave(){
         element.classList.remove('HandleHoverAddToCart')
      }
   }
   actions[action]()
}