import React, { createContext, useContext, useEffect, useState } from "react";
import api from "../services/api";
import { useHistory } from "react-router";

import { unmaskCPF } from "../utils";

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
	const [loggedUser, setLoggedUser] = useState(
		JSON.parse(sessionStorage.getItem("loggedUser"))
	);
	const history = useHistory();

	async function signIn(login, password) {
		login = unmaskCPF(login);
		const userData = { login: login, pass: password };

		return api
			.post(`front/login`, userData)
			.then((response) => {
				if (response instanceof Error) {
					return new Error();
				}

				switch (response.status) {
					case 204:
						return {
							status: response.status,
							statusText: "Usuário ou senha inválido",
						};
					case 200:
						setLoggedUser({
							name: response.data.user_name,
							email: response.data.user_email,
							userCPFCNPJ: response.data.user_cpfcnpj,
							userToken: response.data.user_token,
						});

						return {
							status: response.status,
							statusText: "Login realizado com sucesso",
						};
					default:
						return {
							...response.status,
							statusText: "Ocorreu um erro, tente novamente",
						};
				}
			})
			.catch((error) => {
				if (error.response) {
					return error;
				}
			});
	}

	function signOut() {
		sessionStorage.clear();
		localStorage.clear();
		setLoggedUser(null);
		history.push("/");
	}

	async function createAccount(newAccountData) {
		delete newAccountData.passwordConfirmation;

		try {
			return await api.post(`front/user`, newAccountData);
		} catch (error) {
			return new Error(error);
		}
	}

	useEffect(() => {
		if (loggedUser) {
			sessionStorage.setItem("loggedUser", JSON.stringify(loggedUser));
		}
	}, [loggedUser]);

	return (
		<AuthContext.Provider
			value={{
				loggedUser,
				createAccount,
				signIn,
				signOut,
				setLoggedUser,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
function useAuth() {
	const context = useContext(AuthContext);

	if (!context) {
		throw new Error("useAuth deve ser usado junto do AuthProvider");
	}

	return context;
}

export { AuthProvider, useAuth };
