import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import "./styles.scss";

// Assets
import { ReactComponent as UserIcon } from "../../../../assets/icons/header/mainMenu/userIcon.svg";
import { ReactComponent as CloseModalIcon } from "../../../../assets/icons/header/mainMenu/closeModalIcon.svg";

import Button from "../../../../components/button/button";
import { useAuth } from "../../../useAuth";
import { useModal } from "../../../useModal";

export function MainMenu() {
	const { loggedUser, signOut } = useAuth();
	const { changeCustomStyles, openModal, changeModalParams, closeModal } =
		useModal();

	const history = useHistory();

	function handleUserOptionClick(pageToGo) {
		if (!loggedUser) {
			return (
				changeModalParams({
					type: "login",
					content: {
						pageToGo: "/" + pageToGo,
					},
					canClose: true,
					customCloseButton: false,
				}),
				changeCustomStyles((prevState) => ({
					...prevState,
					overlay: {
						...prevState.overlay,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					},
					content: {
						borderRadius: "8px",
						inset: "unset",
					},
				}))
			);
		}

		closeModal();
		return history.push("/" + pageToGo);
	}

	return (
		<div className="mainMenuContainer">
			<div className="mainMenuContent">
				<div className="mainMenuHeader">
					<div className="clientAvatar">
						<UserIcon />
					</div>

					<div className="clientName">
						{loggedUser ? `Olá, ${loggedUser.name}` : "Olá, Cliente!"}
					</div>
				</div>

				<div className="mainMenuOptions">
					<ul>
						<li onClick={() => handleUserOptionClick("meuspedidos")}>
							<Link>
								<div className="optionDotContainer">
									<div className="optionDot" />
								</div>
								Seus pedidos
							</Link>
						</li>

						<li onClick={() => handleUserOptionClick("enderecos")}>
							<Link>
								<div className="optionDotContainer">
									<div className="optionDot" />
								</div>
								Endereços de entrega
							</Link>
						</li>

						<li onClick={() => handleUserOptionClick("metodosdepagamento")}>
							<Link>
								<div className="optionDotContainer">
									<div className="optionDot" />
								</div>
								Cartões cadastrados
							</Link>
						</li>

						<li>
							<Link>
								<div className="optionDotContainer">
									<div className="optionDot" />
								</div>
								Configurações
							</Link>
						</li>

						<li className="optionDisabled">
							<Link>
								<div className="optionDotContainer">
									<div className="optionDot" />
								</div>
								Folheteria digital - em breve
							</Link>
						</li>

						<li className="optionDisabled">
							<Link>
								<div className="optionDotContainer">
									<div className="optionDot" />
								</div>
								Horários de pico - em breve
							</Link>
						</li>
					</ul>

					<Button
						type="normal"
						width="100%"
						placeholder="Sair"
						css="exit"
						on_click={() => {
							signOut();
							closeModal();
						}}
					/>
				</div>
			</div>
		</div>
	);
}
