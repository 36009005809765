import { AuthProvider, useAuth } from "./useAuth";
import { OrderProvider, useOrder } from "./useOrder";
import { AlertProvider } from "./useAlert";
import { UserProvider, useUser } from "./useUser";
import { ModalProvider, useModal } from "./useModal";

export const AppProvider = ({ children }) => {
	return (
		<AuthProvider>
			<AlertProvider>
				<OrderProvider>
					<UserProvider>
						<ModalProvider>{children}</ModalProvider>
					</UserProvider>
				</OrderProvider>
			</AlertProvider>
		</AuthProvider>
	);
};

export { useAuth, useOrder, useModal, useUser };
