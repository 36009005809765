import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import "./styles.scss";

// Components
import CheckoutPage from "../../../components/page/checkoutPage";
import Button from "../../../components/button/button";
import api from "../../../services/api";

import { useAuth, useModal, useOrder } from "../../../hooks";

export default function Payment() {
	const history = useHistory();
	const { loggedUser } = useAuth();
	const { orderData, setOrderPaymentMethod, handleGenerateOrder, payOrder } =
		useOrder();
	const { openModal, changeModalParams, changeCustomStyles } = useModal();

	// eslint-disable-next-line
	const [sectionSubtitle, setSectionSubtitle] = useState(
		`Revise suas seus itens e escolha a a forma de pagamento`
	);

	// eslint-disable-next-line
	const [buttonStyle, setButtonStyle] = useState({
		style: "normal disabled ",
		placeholder: "Selecione uma opção acima!",
		action: () => () => {},
	});
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [paymentMethodChoosen, setPaymentMethodChoosen] = useState(null);

	function handleSetPaymentMethod(payment_method, cardCode = "") {
		setPaymentMethodChoosen({ payment_method: payment_method, card: cardCode });
	}

	const HandleAddNewPaymentMethodClick = () => {
		changeModalParams({
			type: "addNewCard",
			content: {},
			canClose: true,
		});
		changeCustomStyles(
			changeCustomStyles((prevState) => ({
				...prevState,
				overlay: {
					...prevState.overlay,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				},
				content: {
					borderRadius: "8px",
					inset: "unset",
				},
			}))
		);
		openModal();
	};
	useEffect(() => {
		if (orderData.request.type_delivery === undefined || !loggedUser) {
			history.push("/checkout/carrinho");
		}
		(async () => {
			let paymentPlataformCode;

			const marketPaymentMethods = await api
				.get("/front/paymentmethods")
				.then((response) => {
					response = response.data.methods;
					console.log(response);

					let arrayToReturn = [];

					for (var i = 0; i < response.length; i++) {
						const payment = response[i];

						if (response[i].paym_plataform == "") {
							arrayToReturn.push(payment);
						} else {
							paymentPlataformCode = response[i].paym_code;
						}
					}

					return arrayToReturn;
				});

			const userPaymentMethods = await api
				.get(`/front/cards/${loggedUser.userToken}`)
				.then((response) => {
					console.log(response);
					const newPaymentMethods = response.data.cards.map((paymentMethod) => {
						return { ...paymentMethod, paym_code: paymentPlataformCode };
					});
					return newPaymentMethods;
				});

			setPaymentMethods(marketPaymentMethods.concat(userPaymentMethods));
		})();
	}, []);

	useEffect(() => {
		if (paymentMethodChoosen) {
			setButtonStyle((prevState) => ({
				...prevState,
				style: "normal",
				placeholder: "Finalizar pedido!",
				action: () => {
					changeModalParams({
						type: "loadingApiReponse",
						content: {
							title: "Uau uau",
							subtitle: "More",
						},
					});
					openModal();

					setOrderPaymentMethod(paymentMethodChoosen);
					//handleGenerateOrder().then((response) => {});
				},
			}));
		}
	}, [paymentMethodChoosen]);

	return (
		<CheckoutPage sectionSubtitle={sectionSubtitle}>
			<Button
				on_click={() => {}}
				placeholder={"Adicionar novo cartão"}
				css="normal"
				width={"100%"}
				id="addNewCard"
				on_click={() => HandleAddNewPaymentMethodClick()}
			/>
			{paymentMethods.length > 0 ? (
				paymentMethods.map((paymentMethod, key) => (
					<Button
						type="CheckoutOption"
						id={paymentMethod.paym_description}
						key={key}
						caption={{
							mainCaption: `${
								paymentMethod.paym_description
									? paymentMethod.paym_description
									: "Final: " + paymentMethod.ucar_last_digits
							}`,
							complementCaption: `${
								paymentMethod.ucar_type && paymentMethod.ucar_type === "C"
									? "Cartão de crédito"
									: "Cartão de débito"
							}`,
						}}
						className="card"
						Icon={
							paymentMethod.ucar_brand &&
							require(`../../../assets/icons/cardFlag/${paymentMethod.ucar_brand.toLowerCase()}.svg`)
								.default
						}
						on_click={() =>
							paymentMethod.ucar_code
								? handleSetPaymentMethod(
										paymentMethod.paym_code,
										paymentMethod.ucar_code
								  )
								: handleSetPaymentMethod(paymentMethod.paym_code)
						}
					/>
				))
			) : (
				<h1>Carregando</h1>
			)}
			{/* <Input type="Text" id="payback" placeholder="Troco para" /> */}
			<Button
				css={buttonStyle.style}
				width={"100%"}
				placeholder={buttonStyle.placeholder}
				on_click={buttonStyle.action}
			/>
		</CheckoutPage>
	);
}
