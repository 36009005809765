import { useRef } from "react";

export default function Debounce(functionToBeDebounced, awaitTime) {
	const timeoutRef = useRef(null);

	function debouncedFunction(...params) {
		clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(() => {
			functionToBeDebounced(...params);
		}, awaitTime);
	}

	return debouncedFunction;
}
