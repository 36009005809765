import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from "react-redux";

// Pages
import Home from "./pages/home";

import Cart from "./pages/checkout/cart";
import PaymentMethod from "./pages/checkout/payment";
import AddressToDelivery from "./pages/checkout/address";
// import Finished from "./pages/checkout/orderFinished";
//import Login from "./pages/login/";
import { ByCategories } from "./pages/listBy/byCategories";
import { ByGroups } from "./pages/listBy/byGroups";
import { Search } from "./pages/listBy/search";

import Register from "./pages/register";

// hooks
import { ModalContextProvider } from "./components/modal/context";

import store from "./Redux/Redux-store";
import { Orders } from "./pages/options/orders";
import { PaymentMethods } from "./pages/options/paymentMethods";
import { Addresses } from "./pages/options/addresses";
import { AppProvider } from "./hooks";

const Routes = () => {
	return (
		<ModalContextProvider>
			<Router>
				<AppProvider>
					<Route component={Home} path="/" exact />
					{/* <Route component={ BuyList } path="/lista" /> */}
					<Route component={ByGroups} path="/listByGroups" />
					<Route component={ByCategories} path="/listByCategories" />
					<Route component={Search} path="/busca" />

					<Provider store={store}>
						<Route component={Cart} path="/checkout/carrinho" />
						<Route component={AddressToDelivery} path="/checkout/endereco" />
						<Route component={PaymentMethod} path="/checkout/pagamento" />
						<Route component={Register} path="/cadastro" />
					</Provider>

					<Route component={Orders} path="/meuspedidos" />
					<Route component={PaymentMethods} path="/metodosdepagamento" />
					<Route component={Addresses} path="/enderecos" />
				</AppProvider>
			</Router>
		</ModalContextProvider>
	);
};

export default Routes;
