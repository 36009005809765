import { createContext, useContext } from "react";
import api from "../services/api";

const UserContext = createContext(null);

const UserProvider = ({ children }) => {
	async function getUserAddresses(token) {
		try {
			return await api.get(`front/adresses/${token}`).then((response) => {
				return response.data;
			});
		} catch (error) {
			return new Error(error);
		}
	}

	async function addNewAddress(newAddressData) {
		try {
			return await api
				.post("front/address", newAddressData)
				.then((response) => {
					return response;
				});
		} catch (error) {
			return new Error(error);
		}
	}

	async function deleteUserAddress({ token, addressId }) {
		console.log({ token, addressId });
		try {
			return await api.delete(`front/address/${addressId}`, {
				token: token,
			});
		} catch (error) {
			throw new Error(error);
		}
	}

	return (
		<UserContext.Provider
			value={{ addNewAddress, getUserAddresses, deleteUserAddress }}
		>
			{children}
		</UserContext.Provider>
	);
};

function useUser() {
	const context = useContext(UserContext);

	if (!context) {
		throw new Error("useUser deve ser usado junto do UserProvider");
	}

	return context;
}

export { UserProvider, useUser };
