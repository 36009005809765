import styled from "styled-components";
import {
	Title as TitleModel,
	Subtitle as SubtitleModel,
} from "../../../../styles/global";

export const Container = styled.li`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;

	list-style: none;
	height: 120px;
	border-radius: 8px;
	border: solid 1px var(--light-grey);
`;

export const DotContainer = styled.div`
	width: 120px;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Dot = styled.div`
	${({ active }) =>
		active
			? `width: 30px;
	height: 30px;
    background: var(--primary);`
			: `width: 40px;
	height: 40px;
    background: var(--light-grey);`}

	border-radius: 50%;

	transition: 0.4s;
`;

export const Description = styled.div`
	width: 60%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	flex: 1;
`;

export const Title = styled(TitleModel)``;

export const Subtitle = styled(SubtitleModel)``;

export const ButtonContainer = styled.div`
	width: 120px;

	display: flex;
	justify-content: center;
	align-items: center;
`;
