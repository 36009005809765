import { Container, CategoryList } from "./styles";

import { WrapperCard } from "./wrapperCard";

function CategoryWrapper({ categories }) {
	return (
		<Container id="categories-list">
			<CategoryList>
				{categories.map((category, key) => (
					<WrapperCard category={category} key={key} />
				))}
			</CategoryList>
		</Container>
	);
}

export { CategoryWrapper };
