import axios from "axios";

const username =
	window.location.hostname == "localhost"
		? "localhost"
		: window.location.hostname.substr(4);
let password = "";
switch (username) {
	case "andreazza.superandreazza.com.br":
		password = "001";
		break;
	case "loja.veronasupermercados.com.br":
		password = "015";
		break;
	case "loja.redepiresms.com.br":
		password = "012";
		break;
	default:
		password = "001";
}

const api = axios.create({
	baseURL: "https://ecommerce.rpinfo.com.br/api",
	headers: {
		"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Credentials": "true",
		"Access-Control-Allow-Methods": "*",
		"Access-Control-Allow-Headers": "*",
	},
	auth: {
		username: username,
		password: password,
	},
});

export default api;
