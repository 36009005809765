import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

// Functions
import { getPriceFormact } from "../card/cards_function";
import "./css/styles.scss";

//Hooks
import { useOrder } from "../../hooks/useOrder";

// Components
import Button from "../button/button";
import CleanHeader from "./header/cleanHeader";

import api from "../../services/api";

export default function CheckoutPage(props) {
	const [clientPrefix, setClientPrefix] = useState("");

	const { orderData } = useOrder();
	const items = orderData.request.items;
	const optionsContainerRef = useRef(null);

	let itemsQuantity = 0;
	let cartValue = 0;

	items.map((item) => (itemsQuantity = itemsQuantity + item.quantity));

	items.forEach((item) => {
		cartValue = cartValue + parseFloat(item.price * item.quantity);
	});

	useEffect(() => {
		const options = [].slice.call(optionsContainerRef.current.children);

		const handleClick = (element) => {
			const clicked = element.target;
			options.map((option) => {
				if (clicked == option) {
					option.classList.remove("notSelectedOption");
					option.classList.add("selectedOption");
				} else {
					option.classList.add("notSelectedOption");
					option.classList.remove("selectedOption");
				}
			});
		};

		options.forEach((element) => {
			if (element.tagName != "BUTTON") {
				element.addEventListener("click", (element) => handleClick(element));
			}
		});
	}, [props.children]);

	useEffect(() => {
		api.get("/front/configs").then((response) => {
			setClientPrefix(response.data.config.clie_prefix);
		});
	}, []);

	return (
		<div className="page_container" id={props.page_id}>
			<CleanHeader />
			<div className="main">
				<div className="itemsContainer">
					{" "}
					<h1>Finalize seu pedido!</h1>
					<p className="subtitle">{props.sectionSubtitle}</p>
					<div id="cartItemsAndOptionsContainer">
						<div className="cartItemsContainer">
							{items.length == 0 ? (
								<div className="header">
									<span>Você está sem itens!</span>
									<span>
										Não podemos prosseguir se o seu carrinho estiver vazio
									</span>
								</div>
							) : (
								<div className="header">
									<span>{items.length > 1 ? "Seus Itens" : "Seu item"}</span>
									<span>
										{items.length > 1
											? items.length + " Produtos"
											: items.length + " Produto"}
										,{" "}
										{itemsQuantity > 1
											? itemsQuantity + " itens"
											: itemsQuantity + " item"}
									</span>
								</div>
							)}

							{items.length == 0 ? (
								<div className="itemsList">
									<div id="cartMessage">
										<p>Seu carrinho está vazio :(</p>
										<p>
											Adicione um produto ao carrinho para ele aparecer aqui!{" "}
											<Link to="/"> Adicionar itens</Link>
										</p>
									</div>
								</div>
							) : (
								<>
									<div className="itemsList">
										<table>
											<thead>
												<tr>
													<th></th>
													<th
														id="thProduct"
														style={{
															textAlign: "left",
														}}
													>
														Produto
													</th>

													<th
														id="thUnityPrice"
														style={{
															textAlign: "center",
														}}
													>
														Preço Unitário
													</th>
													<th
														id="thQuantity"
														style={{
															textAlign: "center",
														}}
													>
														Quantidade
													</th>
													<th
														id="thTotal"
														style={{
															textAlign: "center",
														}}
													>
														Total
													</th>
												</tr>
											</thead>
											<tbody>
												{items.map((item) => (
													<tr>
														{/* <div className="itemContainer"> */}
														<td id="tdImage">
															<div className="imageContainer">
																{item.imageName == null ? (
																	<img
																		src={
																			require(`../../assets/products/naoDispo.png`)
																				.default
																		}
																	/>
																) : (
																	<img
																		src={`https://ecommerceuploads.s3.amazonaws.com/uploads/${clientPrefix}/products/${item.imageName}`}
																	/>
																)}
															</div>
														</td>
														<td id="tdInfo">
															<div className="infoContainer">
																<span className="title">{item.name}</span>
															</div>
														</td>
														<td id="tdUnityPrice">
															<div className="unityPriceContainer">
																<span className="price">
																	{getPriceFormact(item.price)}
																</span>
															</div>
														</td>
														<td id="tdQuantity">
															<div className="quantityContainer">
																<Button type="QuantityButton" product={item} />
															</div>
														</td>
														<td id="tdTotal">
															<div className="totalContainer">
																{getPriceFormact(item.quantity * item.price)}
															</div>
														</td>
														{/* </div> */}
													</tr>
												))}
											</tbody>
										</table>
									</div>

									<div className="cartValueContainer">
										<div className="cartValue">
											<div className="subtotalContainer">
												<span className="caption">Subtotal: </span>
												<span className="subtotal">
													{getPriceFormact(cartValue)}
												</span>
											</div>

											<div className="subtotalContainer">
												<span className="caption">Frete: </span>
												<span className="subtotal">
													{getPriceFormact(12.0)}
												</span>
											</div>

											<div className="totalContainer">
												<span className="caption">Total: </span>
												<span className="total">
													{getPriceFormact(cartValue + 12)}
												</span>
											</div>
										</div>
									</div>
								</>
							)}
						</div>

						<div ref={optionsContainerRef} className="optionsContainer">
							{props.children}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
