import { createContext, useContext, useState, useEffect } from "react";
import Modal from "react-modal";

import Button from "../../components/button/button";

import {
	ApiResponse,
	AddNewCard,
	MainMenu,
	Login,
	CreateAccount,
	Cart,
	AddNewAddress,
	Confirmation,
	ProductMoreInfo,
} from "./components";

import { ReactComponent as CloseModalIcon } from "../../assets/icons/header/mainMenu/closeModalIcon.svg";

const ModalContext = createContext({});

Modal.setAppElement(document.getElementById("root"));

function ModalProvider({ children }) {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [modalParams, setModalParams] = useState({
		type: "",
		content: {},
		canClose: true,
		customCloseButton: false,
	});

	const customStylesInitialState = {
		overlay: {
			zIndex: 900,
			backgroundColor: "rgba(0, 0, 0, 0.5)",
		},
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			borderRadius: "8px",
		},
	};
	const [customStyles, setCustomStyles] = useState(customStylesInitialState);

	function openModal() {
		setModalIsOpen(true);
	}

	function closeModal() {
		if (modalParams.canClose) {
			return setModalIsOpen(false);
		}

		return alert("Ação não permitida");
	}

	function changeCustomStyles(customStyles) {
		if (!customStyles) {
			return setCustomStyles(customStylesInitialState);
		}

		setCustomStyles(customStyles);
	}

	// useEffect(() => {
	// 	console.log(modalParams);
	// }, [modalParams]);

	function renderModal() {
		const { content, type } = modalParams;
		// const { title, subtitle } = content;
		switch (type) {
			case "loadingApiResponse":
				return <ApiResponse content={modalParams.content} />;
			case "addNewCard":
				return <AddNewCard />;
			case "mainMenu":
				return <MainMenu />;
			case "login":
				return <Login redirectTo={content.pageToGo} />;
			case "createAccount":
				return <CreateAccount />;
			case "cart":
				return <Cart />;
			case "addNewAddress":
				return <AddNewAddress />;
			case "confirmation":
				return (
					<Confirmation
						accept={modalParams.content?.confirmationOptions.accept}
						reject={modalParams.content?.confirmationOptions.reject}
					/>
				);
			case "productMoreInfo":
				return <ProductMoreInfo item={content?.item} />;
			default:
				return;
		}
	}

	function changeModalParams(params) {
		setModalParams(params);
	}

	return (
		<ModalContext.Provider
			value={{
				openModal,
				closeModal,
				changeModalParams,
				changeCustomStyles,
			}}
		>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel="Example Modal"
			>
				{modalParams.canClose && !modalParams.customCloseButton && (
					<Button
						style={{ right: "20px", top: "20px", position: "absolute" }}
						type="CloseModal"
					>
						<CloseModalIcon />
					</Button>
				)}

				{renderModal()}
			</Modal>
			{children}
		</ModalContext.Provider>
	);
}

function useModal() {
	const context = useContext(ModalContext);

	if (!context) {
		throw new Error("useModal deve ser usado junto do ModalProvider");
	}

	return context;
}

export { ModalProvider, useModal };
