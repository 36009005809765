import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
:root{
  --light-grey:#E8E9EB;
  --primary:#2662F0;
  --black:#222222;
  --grey:#9B9B9B;
  --white:#FFFFFF;

  --max-width: 1198px;

}
a,button{
	cursor:pointer
}
`;

export const Title = styled.span`
	font-family: "Nunito Sans";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
`;

export const Subtitle = styled.span`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;

	color: var(--grey);
`;
