import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import "./styles.scss";

// Components
import CheckoutPage from "../../../components/page/checkoutPage";
import Button from "../../../components/button/button";

import { ModalContext } from "../../../components/modal/context";

// Assets
import { ReactComponent as HomeIcon } from "../../../assets/icons/checkout/home.svg";

import { useAuth, useModal, useUser, useOrder } from "../../../hooks";

export default function Address(props) {
	const history = useHistory();

	const { loggedUser } = useAuth();
	const { handleSetDeliveryAddress, orderData } = useOrder();
	const { getUserAddresses } = useUser();
	const { changeModalParams, changeCustomStyles, openModal } = useModal();

	const [modalState, setModalState] = useState({
		modalOpened: false,
		openedModal: null,
	});
	const value = { modalState, setModalState };
	// eslint-disable-next-line
	const [sectionSubtitle, setSectionSubtitle] = useState(
		`Revise suas seus itens e escolha o endereço para entrega`
	);
	const [addressToDelivery, setAddressToDelivery] = useState("");
	const [userAddresses, setUserAddresses] = useState([]);
	const [buttonStyle, setButtonStyle] = useState({
		style: "normal ",
		placeholder: "Selecione uma opção acima!",
		action: () => () => {},
	});

	useEffect(() => {
		if (addressToDelivery) {
			setButtonStyle((prevState) => ({
				...prevState,
				placeholder: "Escolher forma de pagamento",
				action: () => {
					handleSetDeliveryAddress(addressToDelivery.usad_code);
					history.push("/checkout/pagamento");
				},
			}));
		}
		// eslint-disable-next-line
	}, [addressToDelivery]);

	useEffect(() => {
		getUserAddresses(loggedUser.userToken).then((response) => {
			console.log(response);
			setUserAddresses(response);
		});
	}, []);

	const HandleAddNewAddressButtonClick = () => {
		changeModalParams({
			type: "addNewAddress",
			content: {},
			canClose: true,
		});
		changeCustomStyles((prevState) => ({
			...prevState,
			overlay: {
				...prevState.overlay,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
			content: {
				borderRadius: "8px",
				inset: "unset",
			},
		}));
		openModal();
	};

	return (
		<>
			<CheckoutPage id="addressPage" sectionSubtitle={sectionSubtitle}>
				{/* Implementação futura: animar o icone do motoboy */}
				<ModalContext.Provider value={value}>
					<button
						onClick={() => HandleAddNewAddressButtonClick()}
						className="addNewAdress buttonBoxShadow"
					>
						<i class="fa fa-map-marker" aria-hidden="true" />
						Adicionar novo endereço
					</button>
				</ModalContext.Provider>{" "}
				{loggedUser ? (
					userAddresses.map((address, key) => (
						<Button
							type="CheckoutOption"
							id={address.id}
							key={key}
							className="card"
							on_click={() =>
								setAddressToDelivery({
									usad_code: address.usad_code,
									usad_address: address.usad_address,
									usad_number: address.usad_number,
									usad_distname: address.dist_name,
									city_name: address.city_name,
									city_uf: address.city_uf,
								})
							}
							Icon={HomeIcon}
							caption={{
								mainCaption: `${address.usad_address} - Nº ${address.usad_number}`,
								complementCaption: `${
									address.usad_type === "H" ? "Casa" : "Trabalho"
								}`,
							}}
						/>
					))
				) : (
					<p>Parece que você não tem nenhum endereço cadastrado</p>
				)}
				<ModalContext.Provider value={value}>
					<Button
						width={"100%"}
						placeholder={
							orderData.request?.items.length === 0
								? "Seu carrinho está vazio"
								: buttonStyle.placeholder
						}
						css={
							!addressToDelivery || orderData.request?.items.length === 0
								? "normal disabled"
								: buttonStyle.style
						}
						on_click={buttonStyle.action}
					/>
				</ModalContext.Provider>
			</CheckoutPage>
		</>
	);
}
