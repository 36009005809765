import { transitions, positions, Provider } from "react-alert";
import CustomTemplate from "../components/alertTemplate";

export const AlertProvider = ({ children }) => {
	const options = {
		position: positions.BOTTOM_CENTER,
		timeout: 5000,
		offset: "30px",
		transition: transitions.SCALE,
	};

	return (
		<Provider template={CustomTemplate} {...options}>
			{children}
		</Provider>
	);
};
