import React, { useContext, useState, memo } from "react";
import "./css/card.scss";

import { getPriceFormact } from "./cards_function";

import { ReactComponent as PriceTag } from "../../assets/icons/product_card/price_tag.svg";
import { ReactComponent as Clock } from "../../assets/icons/recipe/clock.svg";

import Button from "../button/button";
import { ModalContext } from "../modal/context";

import { useModal } from "../../hooks/useModal";

import { PageProvider } from "../page/index";

function Card({ type, item, isLoading, category, style }) {
	const { clientPrefix } = useContext(PageProvider);

	const {
		code,
		imageName,
		name = "Default",
		complement = "default complement",
		oldPrice,
		price,
		difficulty,
		time,
		selected = false,
	} = item;
	const card_style = {
		Product() {
			const { modalState, setModalState } = useContext(ModalContext);
			const [quantity, setQuantity] = useState(0);

			const {
				openModal,
				changeCustomStyles,
				changeModalParams,
				changeCustomCloseButton,
			} = useModal();

			if (isLoading) {
				return (
					<>
						<div className="product_card">
							<div className="productImageContainer"></div>

							<div className="product_info">
								<span className="product_name"></span>
								<span className="product_complement"></span>
							</div>
							<div className="product_price_container">
								<span className="product_promo_price"></span>
							</div>
						</div>
					</>
				);
			}

			const HandleOpenModalProductMoreInfo = (item) => {
				changeModalParams({
					type: "productMoreInfo",
					canClose: true,
					content: { item: item },
				});
				changeCustomStyles((prevState) => ({
					overlay: {
						...prevState.overlay,
						display: "flex",
						justifyContent: "center",
					},
					content: {
						...prevState.content,
						transform: "unset",
						inset: "unset",

						margin: "0 auto",
						borderRadius: "0px 0px 8px 8px",
					},
				}));
				openModal();
			};

			return (
				<>
					<div id={code} className="product_card" style={style}>
						<div
							className="productImageContainer"
							onClick={() => HandleOpenModalProductMoreInfo(item)}
						>
							{imageName == null ? (
								<img
									src={require(`../../assets/products/naoDispo.png`).default}
								/>
							) : (
								<img
									src={`https://ecommerceuploads.s3.amazonaws.com/uploads/${clientPrefix}/products/${imageName}`}
								/>
							)}
						</div>
						<Button type="AddToCart" item={item} />
						<div
							onClick={() => HandleOpenModalProductMoreInfo(item)}
							className="product_info"
						>
							<span className="product_name">{name}</span>
							<span className="product_complement">{complement}</span>
						</div>
						<div className="product_price_container">
							{parseInt(oldPrice) < parseInt(price) ? (
								<>
									<span className="product_price">
										{getPriceFormact(oldPrice)}
									</span>
									<span className="product_promo_price">
										{getPriceFormact(price)}
									</span>

									<span className="save">
										<PriceTag /> Economize {getPriceFormact(oldPrice - price)}
									</span>
								</>
							) : (
								<>
									<span className="product_promo_price">
										{getPriceFormact(price)}
									</span>
								</>
							)}
						</div>
					</div>
				</>
			);
		},
		recipe() {
			return (
				<div
					className="recipe_card"
					onClick={() => alert("Recurso disponível em breve!")}
				>
					<div className="recipe_image">
						<img src={require(`../../assets/recipe/${imageName}`).default} />
					</div>
					<div className="recipe_info">
						<span className="recipe_name">{name}</span>
						<span className="icons_container">
							<div className="icons">
								<Clock />
								<span>{time}</span>
								<span className="difficulty">{difficulty}</span>
							</div>
						</span>
					</div>
				</div>
			);
		},
		Category() {
			return (
				<div
					className={`category_card container_padding ${
						selected && "selected"
					}`}
				>
					{isLoading ? (
						<div className="category_image" />
					) : (
						<div className="category_image">
							{!category.image ? (
								<img
									src={require(`../../assets/products/naoDispo.png`).default}
								/>
							) : (
								<img
									src={
										require(`../../assets/category_cards/${category.image}`)
											.default
									}
								/>
							)}
						</div>
					)}
					<p className="categoryDescription">{category.cat_description}</p>
				</div>
			);
		},
	};

	return card_style[type]();
}

export default memo(Card);
