import React from "react";
import Card from "../card/card";
import Slider from "react-slick";
import { configs } from "./react_slick_config";

export default function LoadingCards({ quantityOfCardsToRender, type }) {
	let loadingCards = [];

	for (var i = 0; i < quantityOfCardsToRender; i++) {
		loadingCards.push(
			<div className="container_padding" key={i}>
				<Card type={type} item={{}} isLoading={true} category={{}} />
			</div>
		);
	}

	return (
		<Slider {...configs[`${type.toLowerCase()}`]()}>
			{loadingCards.map((card) => {
				return card;
			})}{" "}
		</Slider>
	);
}
